import React, { useState } from "react";
import Button from "../button";
import { useGetReminderQuery } from "../../redux/slices/dashboard";
import AddReminder from "../Modal/AddReminder";
import AllReminder from "../Modal/AllReminderr";
import Skeleton from "../Skeleton";
import { useNavigate } from "react-router-dom";

const Reminder = () => {
    const navigate =useNavigate()
  const [showreminder, setShowReminder] = useState(false);

  const { data: remindersData,isLoading ,refetch} = useGetReminderQuery();
  const [viewAllReminder, setViewAllReminder] = useState(false);
  return (
    <div className=" bg-lightThemeGradient dark:bg-darkThemeGradient p-6 rounded-lg h-full overflow-auto shadow-lg w-full text-white">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <h2 className="text-xl font-semibold">Reminders</h2>
          <span className="bg-white text-blue-600 font-bold rounded-full px-2 py-0.5">
            {remindersData?.data?.length}
          </span>
        </div>
        <h2 onClick={() => {navigate('/reminders')}} className="cursor-pointer">
          View all
        </h2>
      </div>

      <div>
  {(!isLoading) ? (
    remindersData?.data?.map((reminder, index) => (
      <div
        key={index}
        className="bg-blue-500 rounded-lg p-4 mb-3 flex justify-between items-center"
      >
        <div>
          <p className="text-sm">{reminder.title}</p>
          {/* <h3 className="text-lg font-medium">{reminder.time}</h3> */}
        </div>
        <span
          className={`text-sm font-bold ${
            reminder.priority === "High" ? "text-red-500" : "text-green-500"
          }`}
        >
          {reminder.priority}
        </span>
      </div>
    ))
  ) : (
    Array(4)
      .fill('')
      .map((_, i) => (
        <div key={i} className="h-12 w-full mb-3">
          <Skeleton />
        </div>
      ))
  )}
</div>

      {showreminder && <AddReminder refetch={refetch} setShowReminder={setShowReminder} />}
      {viewAllReminder && (
        <AllReminder setViewAllReminder={setViewAllReminder} />
      )}
      <div className="w-full cursor-pointer ">
        <Button
          onClick={() => {
            setShowReminder(true);
          }}
          className="w-full cursor-pointer "
        >
          Add Reminder
        </Button>
      </div>
    </div>
  );
};

export default Reminder;
