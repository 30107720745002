import React from "react";
import SiderBarButtonComponent from "./sidebarButton";

const SideBarSwitchButton = ({
  onMouseOver,
  onMouseOut,
  Icon,
  isFirst = false,
  isLast = false,
  isSelected = false,
  setSelectedIndex,
  activeIndex,
  actualIndex,
  buttonText,
  action
}) => {
    
  return (
    <button onMouseOver={onMouseOver} onMouseOut={onMouseOut} className="z-50 relative">
      {activeIndex === actualIndex ? (
        <SiderBarButtonComponent
          isFirst={isFirst}
          isLast={isLast}
          isSelected={true}
          setSelectedIndex={setSelectedIndex}
          isHovered={true}
          Icon={Icon}
          buttonText={buttonText}
          action={action}
          actualIndex={actualIndex}
        />
      ) : (
        <SiderBarButtonComponent
          isFirst={isFirst}
          isLast={isLast}
          isSelected={isSelected}
          setSelectedIndex={setSelectedIndex}
          isHovered={false}
          Icon={Icon}
          buttonText={buttonText}
          action={action}
          actualIndex={actualIndex}
        />
      )}
    </button>
  );
};

export default SideBarSwitchButton;
