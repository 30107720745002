import React, { useState, useEffect, useRef } from "react";
import {
  useClientManagementQuery,
  useGetPropertiesOnRiskQuery,
  useDeleteClientMutation,
  usePropertyManagementQuery,
  useAddWhatsappGroupMutation,
  useGetWhatsappGroupQuery,
  useGetSearchUserQuery,
} from "../../redux/slices/dashboard";
import {
  useGetClientDetailQuery,
  useUpdateClientMutation,
} from "../../redux/slices/Setting";
import UniversalTable from "../../component/Table/UniversalTable";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import Skeleton from "../../component/Skeleton";
import AddClient from "../AllClients/AddClient";
import PopUpModal from "../../component/Modal/PopupModal";
import UniversalModel from "../../component/Modal/UniversalModel";
import { toast } from "react-toastify";
import ReusableInput from "../../component/InputField/ReusableInput";
import Button from "../../component/button";
import { IoSearch } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import { MdOutlineSort } from "react-icons/md";
import { Input } from "postcss";
import { useNavigate } from "react-router-dom";
import UniversalLoader from "../../component/Loader/UniversalLoader";

const ClientManagement = () => {
  const methods = useForm({});
  const navigate = useNavigate();
  const { watch, handleSubmit } = methods;
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedInputField, setSelectedInputFiled] = useState();
  const [selectedInputFiledText, setSelectedInputFiledText] = useState();
  const [selectedPropertyId, setSelectedPropertyId] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [filterBudget, setFilterBudget] = useState();
  const [searchType, setSearchType] = useState("");
  const inputRef=useRef()
  const {
    data,
    isLoading:clientIsLoading,
    refetch: getClientRefetch,
  } = useClientManagementQuery({ pageNumber: pageNumber ,searchType:searchType ,budgetSort:filterBudget,isActive:filterStatus});
  const { data: searchUser } = useGetSearchUserQuery({
    searchText: selectedInputFiledText,
  });
  const [skipGetWhatsapp, setSkipGetWhatsapp] = useState(true);
const [selectedGroupName,setSelectedGroupName]=useState('')
  const [clientIdWA, setClientIdWA] = useState(); // duplicate client id created to refetch particular api

  const { data: clientDetail } = useGetClientDetailQuery({
    clientId: clientIdWA,
  });
  const { data: getWhatsappGroup ,isFetching:getWhatsappGroupFetching ,isLoading:getWhatsappGroupLoading} = useGetWhatsappGroupQuery(
    { clientId: selectedPropertyId,groupName:selectedGroupName },
    { skip: skipGetWhatsapp }
  );
  useEffect(() => {
    if (skipGetWhatsapp == false) {
      setSkipGetWhatsapp(true);
    }
  }, [skipGetWhatsapp]);
  console.log(getWhatsappGroupFetching,getWhatsappGroupLoading,skipGetWhatsapp,"getWhatsappGroupLoading")
  const [addWhatsappGroup] = useAddWhatsappGroupMutation();
  const [showAddClient, setShowAddClient] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteClient] = useDeleteClientMutation();
  const totalPages = data?.data?.totalPages;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showWAModal, setShowWAModal] = useState(false);
  const [showVieiwWAModel, setShowViewWAModel] = useState(false);
  // const [whatsappGroup,setWhatsappGroup]=useState([])
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [updateClient] = useUpdateClientMutation();
  const [totalGroup, setTotalGroup] = useState(0);
  const [whatsappGroup, setWhatsappGroup] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [groupData, setGroupData] = useState(
    Array.from({ length: totalGroup }, () => ({
      whatsappGroupName: "",
      whatsappGroupParticipants: [],
    }))
  );
  const [isLoading,setIsLoading]=useState(false);

  const [filterComplition, setFilterComplition] = useState();

  const dropdownRef = useRef();
  const dropDownRef1 = useRef();
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const sortHandleClickOutside = (event) => {
    if (dropDownRef1.current && !dropDownRef1.current.contains(event.target)) {
      setShowSort(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", sortHandleClickOutside);
    return () => {
      document.removeEventListener("mousedown", sortHandleClickOutside);
    };
  }, []);
  const numberOfGroup = watch("numberOfGroup");
  const onSubmit = (data) => {
    setTotalGroup(parseInt(data?.numberOfGroup, 10));
  };
  useEffect(() => {
    if (numberOfGroup !== undefined) {
      handleSubmit(onSubmit)(); // Trigger onSubmit when numberOfGroup changes
    }
  }, [numberOfGroup]);
  useEffect(() => {
    setGroupData(
      Array.from({ length: totalGroup }, () => ({
        whatsappGroupName: "",
        whatsappGroupParticipants: [],
      }))
    );
  }, [totalGroup]);
  const [isSearchVisible, setSearchVisible] = useState(false);

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };
  const handleAddGroup = (index) => {
    setIsLoading(true)
    addWhatsappGroup({
      whatsappGroup: groupData.filter((item) => item.whatsappGroupName !== ""),
      clientId: selectedPropertyId,
    })
      .unwrap()
      .then((res) => {
        // setSkipGetWhatsapp(false);
        setIsLoading(false)
        toast.success(res?.message, { position: "bottom-right" });
        setShowWAModal(false);
        setShowCreateGroup(false);
        getClientRefetch();
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error(err?.data?.message, { position: "bottom-right" });
        setShowWAModal(false);
        getClientRefetch();
      });
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...groupData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setGroupData(updatedData);
  };
  const handleParticipantAdd = (index, value) => {
    const updatedData = [...groupData];
    const currentGroup = updatedData[index];

    // Initialize participants as an array if not already present
    if (!Array.isArray(currentGroup.whatsappGroupParticipants)) {
      currentGroup.whatsappGroupParticipants = [];
    }

    // Add the new participant to the array
    currentGroup.whatsappGroupParticipants.push({ participantNumber: value });
    setGroupData(updatedData);
  };

  const handleParticipantRemove = (groupIndex, participantIndex) => {
    const updatedData = [...groupData];
    const currentGroup = updatedData[groupIndex];

    // Remove the selected participant
    currentGroup.whatsappGroupParticipants.splice(participantIndex, 1);
    setGroupData(updatedData);
  };

  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleFunction = (hId, action) => {
    setSelectedPropertyId(hId);
    
    if (action === "Delete") {
      setIsModalOpen(true);

      setShowDeleteModal(true);
    } else if (action === "Deactivate") {
      setShowDeactivateModal(true);
    } else if (action === "Activate") {
      console.log("Activate Action");
      setShowActivateModal(true);
    } else if (action === "Create_WA_Group") {
      setShowWAModal(true);
    } else if (action === "View_WA_Detail") {
      setShowViewWAModel(true);
      setClientIdWA(hId);
    }else if(action ==='send_onboarding_link'){
        setSkipGetWhatsapp(false);
    }
    else if(action ==='view_onboarding_link'){
      navigate(`/client-pre-onboarding-form/${hId}`)
    }
  };

  const handleActivateConfirm = () => {
    updateClient({
      propertyCode: selectedPropertyId,
      isActive: true,
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        closeModal();
        getClientRefetch();
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
        getClientRefetch();
      });
  };
  const handleDeactivateConfirm = () => {
    updateClient({
      propertyCode: selectedPropertyId,
      isActive: false,
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        closeModal();
        getClientRefetch();
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
        getClientRefetch();
      });
  };

  const handleDeleteConfirm = () => {
    if (selectedPropertyId) {
      deleteClient({ clientId: selectedPropertyId })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          getClientRefetch();
          closeModal();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
  };
  const closeModal = () => {
    setShowDeleteModal(false);
    setShowDeactivateModal(false);
    setShowActivateModal(false);
  };
  const handleEnterClick = (group, index) => {
    console.log(inputRef?.current,"inputRef")
    const isValid = /^(?:\+\d{10,13}|\d{10})$/.test(
      selectedInputFiledText
    );
    const isDuplicate = group?.whatsappGroupParticipants.some(
      (participant) => participant.participantNumber === selectedInputFiledText
    );
    if (isValid && !isDuplicate) {
      handleParticipantAdd(index, selectedInputFiledText);
      if (inputRef.current) {
        inputRef.current.value = ""; // Set the input value to an empty string
      }
    }
  };
  console.log(selectedInputField, "selected input field");
  return (
    <div className="p-6 w-full h-auto">
      <div className="h-auto mt-20 pl-20 w-full">
        <div className="bg-lightThemeGradient mt-20 h-[70vh] overflow-y-auto rounded-lg dark:bg-darkThemeGradient p-4">
           
          {showAddClient ? (
            <AddClient
              clientReload={getClientRefetch}
              setShowAddClient={setShowAddClient}
            />
          ) : (
            <>
              {clientIsLoading ? (
                <div className="w-full h-[60vh]">
                  <Skeleton />
                </div>
              ) : (
                <>
                  <div className="">
                    <div className="w-full flex justify-between items-center">
                      <h1 className="text-2xl">Clients</h1>
                      <div className="flex items-center gap-4">
                        <div ref={dropDownRef1} className="relative">
                          <div
                            onClick={() => setShowSort(!showFilter)}
                            className="flex p-2 text-sm h-fit items-center filter text-darkThemeFontColor  gap-2 rounded-lg cursor-pointer"
                          >
                            <MdOutlineSort />
                            Sort
                          </div>
                          {showSort && (
                            <div className="absolute top-10 right-0 flex flex-col tex gap-2  text-darkThemeFontColor min-w-[400px] bg-gray-800 shadow-lg rounded-lg p-4 z-50">
                              <div className="w-full">
                                <p>Budget</p>
                                <div className="h-12 ">
                                  <NormalDropDown
                                    selectedValue={filterBudget}
                                    setSelectedValue={setFilterBudget}
                                    options={["Low to high", "High to low"]}
                                    label={"Select value"}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div ref={dropdownRef} className="relative ">
                          <div
                            onClick={() => setShowFilter(!showFilter)}
                            className="flex p-2 text-sm h-fit items-center filter text-darkThemeFontColor  gap-2 rounded-lg cursor-pointer"
                          >
                            <CiFilter />
                            Filter
                          </div>
                          {showFilter && (
                            <div className="absolute top-10 right-0 flex flex-col gap-4  text-darkThemeFontColor min-w-[400px] bg-gray-800 shadow-lg rounded-lg p-4 z-50">
                              <div className="w-full">
                                <p>Status</p>
                                <div className="h-12">
                                  <NormalDropDown
                                    selectedValue={filterStatus}
                                    setSelectedValue={setFilterStatus}
                                    options={["Active", "Inactive"]}
                                    label={"Select value"}
                                  />
                                </div>
                              </div>

                              <div className="w-full">
                                <p>Completion</p>
                                <div className="h-12">
                                  <NormalDropDown
                                    selectedValue={filterComplition}
                                    setSelectedValue={setFilterComplition}
                                    options={["Onboarded", "Not Onboarded"]}
                                    label={"Select value"}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex items-center space-x-2">
                          {/* Search Icon */}
                          <div
                            onClick={toggleSearch}
                            className="text-white  hover:text-gray-900"
                          >
                            <IoSearch />
                          </div>

                          {/* Search Input */}
                          {isSearchVisible && (
                            <input
                              type="text"
                              placeholder="Search..."
                              value={searchType}
                              onChange={(e)=>{setSearchType(e.target.value)}}
                              className="border text-black border-gray-300 rounded-lg py-1 px-2 w-48 focus:outline-none focus:border-blue-500 transition duration-150 ease-in-out"
                            />
                          )}
                        </div>
                        <h1
                          onClick={() => setShowAddClient(true)}
                          className="p-2 cursor-pointer bg-white rounded-lg text-black text-sm"
                        >
                          Add Client
                        </h1>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <h2 className="text-sm">
                        {data?.data.length} clients on this page
                      </h2>
                    </div>
                  </div>

                  <div>
                    <UniversalTable
                      showActions="clientele"
                      handleFunction={handleFunction}
                      dataArray={data?.data}
                    />
                    {totalPages > 1 && (
                      <div className="flex justify-center items-center space-x-4 mt-6">
                        <button
                          onClick={handlePrevious}
                          className={`px-4 py-2 text-white rounded ${
                            pageNumber === 1
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-700"
                          }`}
                          disabled={pageNumber === 1}
                        >
                          Previous
                        </button>
                        <span className="text-lg text-black dark:text-white font-medium">
                          Page {pageNumber} of {totalPages}
                        </span>
                        <button
                          onClick={handleNext}
                          className={`px-4 py-2 text-white rounded ${
                            pageNumber === totalPages
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-700"
                          }`}
                          disabled={pageNumber === totalPages}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {showDeleteModal && (
        <PopUpModal
          closeModal={closeModal}
          handleFunction={handleDeleteConfirm}
          heading="Comfirm Delete"
          subHeading={"Do you really want to delete Property"}
        />
      )}
      {showDeactivateModal && (
        <PopUpModal
          closeModal={closeModal}
          handleFunction={handleDeactivateConfirm}
          heading="Comfirm Deactivate"
          subHeading={"Do you really want to Deactivate Property"}
        />
      )}

      {showActivateModal && (
        <PopUpModal
          closeModal={closeModal}
          handleFunction={handleActivateConfirm}
          heading="Comfirm Activate"
          subHeading={"Do you really want to Activate Property"}
        />
      )}

      {showWAModal && (
        <UniversalModel
          setShowModel={setShowWAModal}
          showSaveButton={true}
          handleSave={handleAddGroup}
        >
          <div className="w-full overflow-y-auto max-h-[70vh] p-2 mt-8">
            <FormProvider {...methods}>
              <form
                className="flex flex-col justify-between items-between"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <ReusableInput
                  label="Number of Properties"
                  name="numberOfProperties"
                  type="number"
                  placeholder="Enter Number of Properties"
                />
                <ReusableInput
                  label="How many groups do you want to create?"
                  name="numberOfGroup"
                  type="number"
                  placeholder="Enter Number of Groups"
                />
                <div className="w-28">{/* <Button>Next Step</Button> */}</div>
              </form>
            </FormProvider>

            {groupData.map((group, index) => (
              <div
                key={index}
                className="border rounded-lg p-4 my-2 flex flex-col gap-4"
              >
                <div className="flex w-full justify-between gap-4 items-center">
                  <h1 className="font-semibold">Group Name</h1>
                  <div className="w-[80%] justify-start ">
                  <input
                    type="text"
                    placeholder="Enter group name"
                    value={group.whatsappGroupName}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "whatsappGroupName",
                        e.target.value
                      )
                    }
                    className="border p-2 w-[200px] placeholder:text-xs rounded text-black"
                  />
                   </div>
                </div>

                <div className="flex w-full flex-col justify-between items-center gap-2">
                      <div className="flex justify-between items-center w-full">
                        <h1 className="font-semibold">Participants</h1>
                        <div className="relative w-[80%] flex justify-start items-center">
                          {/* Container for Input and Button */}
                          <div className="flex gap-2 justify-end">
                            <input
                              ref={inputRef}
                              type="text"
                              placeholder="Add participant and press Enter"
                              onClick={() => {
                                selectedInputField === index
                                  ? setSelectedInputFiled(null)
                                  : setSelectedInputFiled(index);
                                setSelectedInputFiledText('');
                              }}
                              onKeyDown={(e) => {
                                const inputValue = e.target.value.trim();

                                const isValid = /^(?:\+\d{10,13}|\d{10})$/.test(
                                  inputValue
                                );
                                const isDuplicate =
                                  group?.whatsappGroupParticipants.some(
                                    (participant) =>
                                      participant.participantNumber ===
                                      inputValue
                                  );
                                console.log(isDuplicate, "isDuplicate");
                                if (e.key === "Enter") {
                                  setSelectedInputFiledText('');
                                  if (isValid && !isDuplicate) {
                                    handleParticipantAdd(index, inputValue);
                                    e.target.value = ""; // Clear input field
                                    e.target.style.borderColor = "gray"; // Reset border color
                                  } else {
                                    e.target.style.borderColor = "red"; // Set border color to red for invalid input
                                  }
                                }
                              }}
                              onChange={(e) => {
                                setSelectedInputFiledText(e.target.value);
                                // e.target.style.borderColor = "gray"; // Reset border color on change
                                // e.target.value = e.target.value.replace(
                                //   /[^+\d]/g,
                                //   ""
                                // ); // Allow only + and digits
                              }}
                              className="border p-2 w-[200px] self-end placeholder:text-xs rounded text-black"
                            />
                            <Button
                              onClick={() => handleEnterClick(group, index)}
                            >
                              Enter
                            </Button>
                          </div>

                          {/* Dropdown Container, aligned to the left */}
                          {selectedInputField === index && (
                            <div className="absolute z-10 top-full left-0  bg-white border text-black w-[200px] max-h-[200px] overflow-y-auto rounded shadow-lg p-2 mt-1">
                              {searchUser?.data.map((item, i) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    const isDuplicate =
                                  group?.whatsappGroupParticipants.some(
                                    (participant) =>
                                      participant.participantNumber ===
                                       item?.phoneNumber
                                  );


                                   !isDuplicate && handleParticipantAdd(
                                      index,
                                      item?.phoneNumber
                                    );
                                  }}
                                  className="flex items-center gap-4 p-4 cursor-pointer hover:bg-gray-400/40 rounded-md"
                                >
                               {/* Profile Picture */}
<img
  src={item?.profileImg || "https://via.placeholder.com/150"} // Use placeholder image if profileImg is null
  alt={item?.fullName || "User"}
  className="w-6 h-6 rounded-full object-cover"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop in case of error on fallback image
    e.target.src = "https://i.imgur.com/your-sample-image.jpg"; // Use a sample image from the internet
  }}
/>


                                  {/* Full Name and Phone Number */}
                                  <div className="flex flex-col text-sm">
                                    <span className="font-medium text-gray-900">
                                      {item?.fullName || "Unknown User"}
                                    </span>
                                    <span className="text-sm text-gray-600">
                                      {item?.phoneNumber || "No Phone Number"}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full gap-4 flex-wrap items-center justify-start">
                      {group?.whatsappGroupParticipants[0] &&
                        group?.whatsappGroupParticipants?.map(
                          (participant, i) => (
                            <div key={i} className="relative flex items-center">
                              <span className="bg-gray-200 text-black px-3 py-1 rounded-full text-sm font-semibold transition-all hover:bg-gray-300">
                                {participant?.participantNumber}
                              </span>
                              <div
                                onClick={() => {
                                  handleParticipantRemove(index, i);
                                }}
                                className="absolute border  cursor-pointer text-red-600 rounded-full -top-3 -right-3 transition-transform transform hover:scale-110"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </div>
                            </div>
                          )
                        )}
                    </div>
              </div>
            ))}
          </div>
        </UniversalModel>
      )}

      {showVieiwWAModel && (
        <UniversalModel setShowModel={setShowViewWAModel}>
          <div className="w-full overflow-y-auto max-h-[70vh] p-4 mt-8 ">
          <UniversalLoader loading={getWhatsappGroupFetching ||getWhatsappGroupLoading }/>
            <div className="flex w-full justify-between items-center">
              <h2 className="text-lg font-bold text-white mb-4">
                WhatsApp Groups
              </h2>
              <div className="flex gap-2 items-center ">
                <h1
                  onClick={() => {
                    setShowCreateGroup(!showCreateGroup);
                  }}
                  className=" font-bold text-white bg-blue-600 mb-4 text-sm p-2 rounded-md cursor-pointer"
                >
                  {showCreateGroup ? "Close" : "Create Group"}
                </h1>

                {showCreateGroup && (
                  <h1
                    onClick={handleAddGroup}
                    className=" font-bold text-white p-2 bg-blue-400 mb-4 rounded-lg cursor-pointer"
                  >
                    Save
                  </h1>
                )}
              </div>
            </div>

            {!showCreateGroup ? (
              clientDetail?.data?.whatsappGroup?.map((group) => (
                <div
                  key={group.whatsappGroupId}
                  className="border-b flex flex-col border-white/20 pb-4 mb-4"
                >
<div className="w-full flex justify-between">
                  <div className="text-md font-semibold text-gray-100">
                   <span className="font-semibold">Group Name: </span> {group.whatsappGroupName}
                  </div>
                  <div onClick={()=>{setSelectedGroupName(group.whatsappGroupName) ; setSkipGetWhatsapp(false); }} className="cursor-pointer">Send Onboarding Link</div>
</div>
                  <div className="mt-2">
                    <h4 className="text-sm font-medium text-gray-300">
                      Participants:
                    </h4>
                    {group.whatsappGroupParticipants.map((participant) => (
                      <div
                        key={participant._id}
                        className="flex items-center mt-2 text-gray-300"
                      >
                        <span className="text-sm">
                          {participant.participantNumber}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full overflow-y-auto max-h-[70vh] p-2 ">
                <FormProvider {...methods}>
                  <form
                    className="flex flex-col justify-between items-between"
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    <ReusableInput
                      label="Number of Properties"
                      name="numberOfProperties"
                      type="number"
                      placeholder="Enter Number of Properties"
                    />
                    <ReusableInput
                      label="How many groups do you want to create?"
                      name="numberOfGroup"
                      type="number"
                      placeholder="Enter Number of Groups"
                    />
                    <div className="w-28">
                      {/* <Button>Next Step</Button> */}
                    </div>
                  </form>
                </FormProvider>

                {groupData.map((group, index) => (
                  <div
                    key={index}
                    className="border rounded-lg p-4 my-2 flex flex-col gap-4"
                  >
                    <div className="flex w-full justify-between gap-4 items-center">
                      <h1 className="font-semibold">Group Name</h1>
                      <div className="w-[80%]">
                      <input
                        type="text"
                        placeholder="Enter group name"
                        value={group.whatsappGroupName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "whatsappGroupName",
                            e.target.value
                          )
                        }
                        className="border p-2 w-[200px] placeholder:text-xs rounded text-black"
                      />
                    </div>
                    </div>

                    <div className="flex w-full flex-col justify-between items-center gap-2">
                      <div className="flex justify-between items-center w-full">
                        <h1 className="font-semibold">Participants</h1>
                        <div className="relative w-[80%] flex justify-start items-center">
                          {/* Container for Input and Button */}
                          <div className="flex gap-2  justify-end">
                            <input
                              type="text"
                              placeholder="Add participant and press Enter"
                              ref={inputRef}
                              onClick={() => {
                                selectedInputField === index
                                  ? setSelectedInputFiled(null)
                                  : setSelectedInputFiled(index);
                                setSelectedInputFiledText('');
                              }}
                              onKeyDown={(e) => {
                                const inputValue = e.target.value.trim();

                                const isValid = /^(?:\+\d{10,13}|\d{10})$/.test(
                                  inputValue
                                );
                                const isDuplicate =
                                  group?.whatsappGroupParticipants.some(
                                    (participant) =>
                                      participant.participantNumber ===
                                      inputValue
                                  );
                                console.log(isDuplicate, "isDuplicate");
                                if (e.key === "Enter") {
                                  setSelectedInputFiledText('');
                                  if (isValid && !isDuplicate) {
                                    handleParticipantAdd(index, inputValue);
                                    e.target.value = ""; // Clear input field
                                    e.target.style.borderColor = "gray"; // Reset border color
                                  } else {
                                    e.target.style.borderColor = "red"; // Set border color to red for invalid input
                                  }
                                }
                              }}
                              onChange={(e) => {
                                setSelectedInputFiledText(e.target.value);
                                // e.target.style.borderColor = "gray"; // Reset border color on change
                                // e.target.value = e.target.value.replace(
                                //   /[^+\d]/g,
                                //   ""
                                // ); // Allow only + and digits
                              }}
                              className="border p-2 w-[200px] self-end placeholder:text-xs rounded text-black"
                            />
                            <Button
                              onClick={() => handleEnterClick(group, index)}
                            >
                              Enter
                            </Button>
                          </div>

                          {/* Dropdown Container, aligned to the left */}
                          {selectedInputField === index && (
                            <div className="absolute z-10 top-full left-0  bg-white border text-black w-[200px] max-h-[200px] overflow-y-auto rounded shadow-lg p-2 mt-1">
                              {searchUser?.data.map((item, i) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    const isDuplicate =
                                  group?.whatsappGroupParticipants.some(
                                    (participant) =>
                                      participant.participantNumber ===
                                       item?.phoneNumber
                                  );


                                   !isDuplicate && handleParticipantAdd(
                                      index,
                                      item?.phoneNumber
                                    );
                                  }}
                                  className="flex items-center gap-4 p-4 cursor-pointer hover:bg-gray-400/40 rounded-md"
                                >
                                  {/* Profile Picture */}
                                                               {/* Profile Picture */}
<img
  src={item?.profileImg || "https://via.placeholder.com/150"} // Use placeholder image if profileImg is null
  alt={item?.fullName || "User"}
  className="w-6 h-6 rounded-full object-cover"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop in case of error on fallback image
    e.target.src = "https://i.imgur.com/your-sample-image.jpg"; // Use a sample image from the internet
  }}
/>

                                  {/* Full Name and Phone Number */}
                                  <div className="flex flex-col text-sm">
                                    <span className="font-medium text-gray-900">
                                      {item?.fullName || "Unknown User"}
                                    </span>
                                    <span className="text-sm text-gray-600">
                                      {item?.phoneNumber || "No Phone Number"}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full gap-4 flex-wrap items-center justify-start">
                      {group?.whatsappGroupParticipants[0] &&
                        group?.whatsappGroupParticipants?.map(
                          (participant, i) => (
                            <div key={i} className="relative flex items-center">
                              <span className="bg-gray-200 text-black px-3 py-1 rounded-full text-sm font-semibold transition-all hover:bg-gray-300">
                                {participant?.participantNumber}
                              </span>
                              <div
                                onClick={() => {
                                  handleParticipantRemove(index, i);
                                }}
                                className="absolute border  cursor-pointer text-red-600 rounded-full -top-3 -right-3 transition-transform transform hover:scale-110"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </UniversalModel>
      )}
    </div>
  );
};

export default ClientManagement;
