import React from "react";
import Skeleton from "../Skeleton";
import { useOtaRevenueBreakdownQuery } from "../../redux/slices/dashboard";
import Chart from 'react-apexcharts';
import dataNotFound from '../../assets/icons/404/4.svg';

const OTARevenueBreakdown = ({hId}) => {
    // Use the hook to fetch data from the API
    const { data: otaRevenueData, isLoading } = useOtaRevenueBreakdownQuery({hId:hId});

    // Parse the data
    const sourceData = otaRevenueData?.data?.sourceData || [];
    const totalSum = otaRevenueData?.data?.totalSum || 0;

    const series = sourceData.map(item => item.value);
    const labels = sourceData.map(item => item.source);

    const chartOptions = {
        series: series,
        chart: {
            type: 'donut',
            width: '100%',
            height: '500px' // Adjust this value as needed
        },
        labels: labels,
        colors: ['#26c6da', '#80deea', '#004d66', '#b2ebf2', '#26a69a'], // Updated color scheme based on the provided image
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            formatter: function (val, opts) {
                return `<div style="width: 100%; text-align: left;">${val}: ${opts.w.globals.series[opts.seriesIndex]}</div>`;
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w) {
                                return totalSum;
                            }
                        },
                        style: {
                            fontSize: '14px', // Adjust font size as needed
                            colors: ['#000'] // Keep text color if necessary
                        }
                    }
                }
            }
        }
    };
    

    return (
        <>
            {!isLoading ? (
                <div className="w-full h-full rounded-lg p-4 bg-lightThemeGradient dark:bg-darkThemeGradient flex flex-col items-center">
                    <div className="w-full flex justify-between text-darkThemeFontColor mb-4">
                        <h1 className="font-semibold text-darkThemeFontColor ">OTA Revenue Breakdown by Source</h1>
                    </div>
                    <div className="w-full flex h-full items-center justify-center">
                        {sourceData?.length > 0 ? (
                            <Chart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="donut"
                                width={400}
                            />
                        ) : (
                            <div className="w-full flex bg-[#7c8ba130] flex-col items-center justify-start h-[90%] p-4 border-2 border-[#7C8BA1] rounded-lg m-2">
                                <img src={dataNotFound} className="h-60 w-60" alt="No Data" />
                                <div className="w-full flex justify-center">
                                    <p className="text-xl font-medium">No recent activities</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </>
    );
};

export default OTARevenueBreakdown;
