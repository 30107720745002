import React, { useState } from "react";
import BackDrop from "../pages/OnBoarding/BackDrop";
import { LuUser } from "react-icons/lu";
import ReusableInput from "./InputField/ReusableInput";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { IoClose } from "react-icons/io5";

const Modal = ({ setShowAddUser }) => {
  const methods = useForm({
    defaultValues: {
      personalDetail: {
        fullName: "",
        email: "",
        phoneNumber: "",
        position: "",
      },
      familyDetail: {
        fathersName: "",
        motherName: "",
        fatherOccuptation: "",
      },
      emergencyDetail: {
        name: "",
        relation: "",
        number: "",
      },
    },
  });

  const [personalDetail, setPersonalDetail] = useState(true);
  const [familyDetail, setFamilyDetail] = useState(false);
  const [emergencyDetail, setEmergencyDetail] = useState(false);
  const handleNext = () => {
    if (personalDetail) {
      setFamilyDetail(true);
      setPersonalDetail(false);
    } else if (familyDetail) {
      setEmergencyDetail(true);
      setFamilyDetail(false);
    }
  };
  const handleBack = () => {
    if (familyDetail) {
      setPersonalDetail(true);
      setFamilyDetail(false);
    } else if (emergencyDetail) {
      setEmergencyDetail(false);
      setFamilyDetail(true);
    }
  };
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "familyDetail",
  });

  const onSubmit = (data) => {
    
  };

  return (
    <BackDrop>
      <div className="bg-white w-1/2 h-4/5 rounded-md">
        <div className="flex p-4 justify-between items-center border-b-2 mb-5">
          <h1>Add a user</h1>
          <div className="cursor-pointer" onClick={() => setShowAddUser(false)}>
            <IoClose size={20} color="red" />
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            className="flex flex-col h-4/5 justify-between items-between"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {personalDetail && (
              <div className="m-6 border-2 p-4">
                <h1 className="bg-white p-2 w-fit -ml-2 -mt-9">
                  Personal Details
                </h1>
                <ReusableInput
                  label="Full Name"
                  name="personalDetail.fullName"
                  type="text"
                  placeholder="Enter your Full Name"
                  rules={{ required: "Full Name is required" }}
                  showIcon={true}
                />
                <ReusableInput
                  label="Email"
                  name="personalDetail.email"
                  type="email"
                  placeholder="Enter your email"
                  rules={{ required: "Email is required" }}
                  showIcon={true}
                />
                <ReusableInput
                  label="Phone Number"
                  name="personalDetail.phoneNumber"
                  type="number"
                  placeholder="Enter your Phone Number"
                  rules={{ required: "Phone Number is required" }}
                  showIcon={true}
                />
                <ReusableInput
                  label="Position"
                  name="personalDetail.position"
                  type="text"
                  placeholder="Enter your Position"
                  rules={{ required: "Position is required" }}
                  showIcon={true}
                />
              </div>
            )}
            {familyDetail && (
              <div className="m-6 border-2 p-4">
                <h1 className="bg-white p-2 w-fit -ml-2 -mt-9">
                  Family Details
                </h1>

                <div className="mb-4">
                  <ReusableInput
                    label="Father's Name"
                    name={`familyDetail.fathersName`}
                    type="text"
                    placeholder="Enter your Father's Name"
                    rules={{ required: "Father's Name is required" }}
                    showIcon={true}
                  />
                  <ReusableInput
                    label="Mother's Name"
                    name={`familyDetail.motherName`}
                    type="text"
                    placeholder="Enter your Mother's Name"
                    rules={{ required: "Mother's Name is required" }}
                    showIcon={true}
                  />
                  <ReusableInput
                    label="Father's Occupation"
                    name={`familyDetail.fatherOccuptation`}
                    type="text"
                    placeholder="Enter your Mother's Name"
                    rules={{ required: "Mother's Name is required" }}
                    showIcon={true}
                  />
                </div>
              </div>
            )}
            {emergencyDetail && (
              <div className="m-6 border-2 p-4">
                <h1 className="bg-white p-2 w-fit -ml-2 -mt-9">
                  Emergency Contact Detail's
                </h1>

                <div className="mb-4">
                  <ReusableInput
                    label="Name"
                    name={`emergencyContact.name`}
                    type="text"
                    placeholder="Enter Name of Emergency Contact"
                    rules={{ required: "Father's Name is required" }}
                    showIcon={true}
                  />
                  <ReusableInput
                    label="Relationship with employee"
                    name={`emergencyContact.relationship`}
                    type="text"
                    placeholder="Enter your Relationship with emergency contact"
                    rules={{ required: "Relationship  is required" }}
                    showIcon={true}
                  />
                  <ReusableInput
                    label="Emergency Contact Number"
                    name={`emergencyContact.number`}
                    type="number"
                    placeholder="Enter Emergency Number"
                    rules={{ required: "Emergency Number is required" }}
                    showIcon={true}
                  />
                </div>
              </div>
            )}
            <div className="w-full justify-center items-center">
              <div className="w-full flex justify-center items-center">
                <div className="h-auto  w-[90%] flex justify-between ">
                  {!personalDetail && (
                    <div
                      onClick={handleBack}
                      className="px-4 p-2 w-fit  cursor-pointer border-2 mr-auto"
                    >
                      {" "}
                      Back
                    </div>
                  )}
                  {!emergencyDetail && (
                    <button
                      type="submit"
                      onClick={() => {
                        
                        methods.handleSubmit((data) => {
                          if (methods.formState.isValid) {
                            onSubmit(data)
                            handleNext();
                          }
                        })();
                      }}
                      className="px-4 p-2 w-fit self-end cursor-pointer border-2 ml-auto"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
     
          </form>
        </FormProvider>
      </div>
    </BackDrop>
  );
};

export default Modal;
