import React, { useEffect, useState } from "react";
import RWorldBlack from "../../assets/icons/RWorldBlack.png";
import Button from "../button";
import ReusableInput from "../InputField/ReusableInput";

import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useSignupMutation,
} from "../../redux/slices/onBoarding";

import GoogleSignInButton from "../googleButton";
import Cookies from "js-cookie";

import { toast } from "react-toastify";
import { decryptText } from "../../utils/EncryptionAlgo";
import { encryptionKey } from "../../helpers/helpers";
import RetvensDarkLogo from "../../assets/logo/rWorldDarkLogo.svg";
import RetvensLightLogo from "../../assets/logo/rWorldLightLogo.svg";

const Login = ({ setRecentLogin, setLoading, recentLoginEmail }) => {
  const methods = useForm();
  const [login] = useLoginMutation();
  const navigate = useNavigate();
  const [signup] = useSignupMutation();
  const onSubmit = (data) => {
    setLoading(true);
    login(data)
      .unwrap()
      .then(async (res) => {
        Cookies.set("token", res?.data?.token, { expires: 7 });
        const prevLogin = Cookies.get("recentLogin")
          ? JSON.parse(Cookies.get("recentLogin"))
          : [];

        const isEmailInPrevLogin = prevLogin?.some(
          (item) => item.email === res?.data?.email
        );
        if (!isEmailInPrevLogin) {
          Cookies.set(
            "recentLogin",
            JSON.stringify([
              ...prevLogin,
              { email: res?.data?.email, name: res?.data?.fullName },
            ]),
            { expires: 7 }
          );
          setRecentLogin([
            ...prevLogin,
            { email: res?.data?.email, name: res?.data?.fullName },
          ]);
        }

        const status = await decryptText(res?.data?.status, encryptionKey);

        if (status === "onboarding") {
          navigate("/onboarding");
        } else {
          navigate("/dashboard");
        }

        setLoading(false);
        // navigate("/onBoarding");
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
  const handleSuccess = (response) => {
    Cookies.set("clientToken", response.stsTokenManager.accessToken, {
      expires: 7,
    });
    signup({ accountType: "google", email: response.email })
      .unwrap()
      .then((res) => {
        navigate("/onBoarding");
        Cookies.set("token", res?.data?.token, { expires: 7 });
        const prevLogin = Cookies.get("recentLogin")
          ? JSON.parse(Cookies.get("recentLogin"))
          : [];
        Cookies.set(
          "recentLogin",
          JSON.stringify([...prevLogin, { email: res?.data?.email }]),
          { expires: 7 }
        );
        setRecentLogin([...prevLogin, { email: res?.data?.email }]);
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
  useEffect(() => {
    methods.setValue("email", recentLoginEmail);
  }, [recentLoginEmail]);
  const handleFailure = (response) => {
    // Handle login failure here
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "dark")
  );

  return (
    <FormProvider {...methods}>
      <form
        className="w-[100%] h-[100%]"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {/* <div
          className="flex bg-custom-image bg-no-repeat  bg-right-bottom   flex-col bg-slate-100 h-screen w-full justify-center"
          style={{ backgroundPosition: "right 0 bottom -60px" }}
        > */}
        <div className="h-full w-full px-4">
          <div className="flex flex-col items-center justify-center">
            <div className=" bg-lightThemeGradient dark:bg-darkThemeGradient   text-darkThemeFontColor shadow rounded-lg  w-full p-10 mt-16">
              <div className="flex justify-between items-start gap-3 ">
                <div className=" flex flex-col  ">
                  <div className="flex items-center gap-2  ">
                    <p className="text-[10px] md:text-[18px]  font-semibold">
                      {" "}
                      Welcome to
                    </p>
                    <span className="flex w-16 h-10 items-center">

                      {theme === "light" ? (
                        <img
                          className=""
                          width={100}
                          src={RetvensDarkLogo}
                          alt="Logo"
                        />
                      ) : (
                        <img
                          className=""
                          width={100}
                          src={RetvensLightLogo}
                          alt="Logo"
                        />
                      )}
                    </span>
                  </div>
                  <div className=" text-base md:text-4xl font-bold ">
                    Log in
                  </div>
                </div>
                <div className="flex flex-col items-start pt-2 pl-[10px] ">
                  <h2 className="text-[10px] p-2  pl-[20px] font-semibold ">No Account?</h2>
                  <Link
                    className="no-underline text-black outline-none bg-white rounded-md px-2 py-1"
                    to={"/signup"}
                  >
                    <div className="text-black text-[14px] text-bold "> Sign up now!</div>
                  </Link>
                </div>
              </div>
              {/* <div className="my-10 w-64">
                <Button type="primary">
                    <img src={RownLogo} height={19} width={19} alt="" />
                    <p className="text-base font-medium ml-4 ">
                      Sign In with R-OWN
                    </p>
                  </Button>
                <GoogleSignInButton
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                />
              </div> */}
              {/* <div className="w-full flex items-center justify-between py-5">
                  <hr className="w-full bg-white" />
                  <p className="text-base font-medium leading-4 px-2.5 text-gray-400">
                    OR
                  </p>
                  <hr className="w-full bg-white" />
                </div> */}
              <div className="mt-8 flex flex-col items-between">
                <ReusableInput
                  label="Enter your Email Address"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  rules={{ required: "Email is required" }}
                  showIcon={true}
                />
                <ReusableInput
                  label="Enter Your Password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  rules={{ required: "Password is required" }}
                  showIcon={true}
                />

                <div className="w-[100%] flex justify-end">
                  <Link to={"/forgot-password"}>
                    <p className="bg-blue-400 text-white text-[12px] px-4 rounded-md py-1 flex-end">Forgot Password ?</p>
                  </Link>
                </div>
              </div>

              <div className="mt-8">
                <Button>Sign In</Button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </form>
    </FormProvider>
  );
};

export default Login;
