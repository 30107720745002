import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'

const RealTimeDate = () => {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const formattedDate = format(currentDateTime, "eeee, MMMM d, yyyy HH:mm:ss");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div>
      {formattedDate}
    </div>
  )
}

export default RealTimeDate

