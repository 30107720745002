import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const SidebarHoverButton = ({
  hoveredText = "hovered text",
  IconImage = IoIosArrowDown,
  buttonText = "button text",
  styling = "",
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleHover = (index) => {
    setActiveIndex(index);
  };

  const handleMouseOut = () => {
    setActiveIndex(null);
  };

  return (
    <button
      onMouseOver={() => handleHover(styling)}
      onMouseOut={handleMouseOut}
      className=""
    >
      {activeIndex === styling ? (
        <div className={`${styling} flex flex-row  w-[200px]`}>
          <IconImage onClick={() => {}} />
          <p>{hoveredText}</p>
        </div>
      ) : (
        <div className={`${styling} flex flex-row w-[100px]`}>
          <IconImage onClick={() => {}} />
          <p>{buttonText}</p>
        </div>
      )}
    </button>
  );
};

export default SidebarHoverButton;
