import React from 'react'
import { BsWallet2 } from "react-icons/bs";
import { IoIosPeople } from 'react-icons/io';
import numberOfClient from '../../assets/croDashboard/numberOfClient.svg'
import activeEmployee from '../../assets/croDashboard/activeEmployee.svg'
import distressClient from '../../assets/croDashboard/distressClient.svg'
import currentOnboarding from '../../assets/croDashboard/currentOnboarding.svg'
import pendingTask from '../../assets/performanceDashBoard/pendingTask.svg'
import assignedTask from '../../assets/performanceDashBoard/assignedTask.svg'


import { MdOutlineEmojiPeople } from 'react-icons/md';
const ClientWrapper = ({name , value,} ) => {     
  return (
    <div className='w-full h-full items-center   dark:bg-darkThemeGradient bg-lightThemeGradient text-darkThemeFontColor p-8 rounded-lg flex justify-between'>
      <div className="left">
        <h1 className=''>{name}</h1>
        <div className="flex gap-4  items-center">
            <h1 className='text-2xl'>{value}</h1>
            {/* <h1 className='text-green-500'>+55%</h1> */}
        </div>
      </div>
      <div className='p-2 rounded-lg h-fit bg-blue-700'>
  {name === 'Number of client' ? (
  <img className="" width={30} src={numberOfClient} alt="Logo" />
) : name === 'Active Employee' ? (
  <img className="" width={25} src={activeEmployee} alt="Logo" />
  ) : name === 'Distress Client' ? (
    <img className="" width={25} src={distressClient} alt="Logo" />
    ) : name === 'Current Onboarding' ? (
      <img className="" width={25} src={currentOnboarding} alt="Logo" />
      ) : name === 'Pending Task' ? (
        <img className="" width={25} src={pendingTask} alt="Logo" />
        ) : name === 'Assigned Task' ? (
          <img className="" width={25} src={assignedTask} alt="Logo" />
          )  
        : (
    <BsWallet2 size={30} />
  )}
</div>

    </div>
  )
}

export default ClientWrapper  

