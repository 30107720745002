import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import ReusableInput from "../InputField/ReusableInput";
import NormalDropDown from "../DropDown/NormalDropDown";
import {
  useEditTaskTypeDetailMutation,
  useGetTaskTypeDetailQuery,
  useGetTaskTypeQuery,
  useGetUtilsQuery,
  usePostImageMutation,
  usePostTaskTypeMutation,
} from "../../redux/slices/canabel";
import { format } from "date-fns";
import TimeRange from "../TimeRange";
import { toast } from "react-toastify";
import CheckupForm from "../CheckupForm";
import { RiH1 } from "react-icons/ri";
import Toggle from "../CheckBox/Toggle";
import DateTimePicker from "react-datetime-picker";
import { useGetPropertyUserListQuery } from "../../redux/slices/Setting";
const AddTask = ({ setShowAddTask, refetch, isEdit=false ,selectedTaskTypeId}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [assignedBy,setAssignedBy]=useState();
  const [assignedTo,setAssignedTo]=useState();
  const [selectedTaskType, setSelectedTaskType] = useState();
  const [selectedParentTask, setSelectedParentTask] = useState();
  const [selectedTaskRecurrence, setSelectedTaskRecurrence] = useState(false);
  const [selectedTaskFreq, setSelectedTaskFreq] = useState();
  const [selectedTaskCategory, setSelectedTaskCategory] = useState();
  const [redirectArray, setRedirectArray] = useState([]);
  const [selectedValidationFunctionality, setSelectedValidationFunctionality] =
    useState();
  const [selectedIsMendatory, setSelectedIsMendatory] = useState();
  const [selectedCheckup, setSelectedCheckup] = useState();
  const [selectedProperties, setSelectedProperties] = useState();
  const [selectedSpecificProperty, setSelectedSpecificProperty] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState();
  const [expectedTime, setExpectedTime] = useState();
  const [taskTag, setTaskTag] = useState([]);
  const [submitError, setSubmitError] = useState();
  const { data: utilsData } = useGetUtilsQuery();
  const [postTask] = usePostTaskTypeMutation();
  const [editTask] =useEditTaskTypeDetailMutation()
  const currentDate = new Date();
  const [imageURL, setImageURL] = useState([]);
  const [postImage] = usePostImageMutation();
  const [deadlineDate, setDeadlineDate] = useState();
  const [createdDate,setCreatedDate]=useState();
  const [startEndPeriod1, setStartEndPeriod1] = useState([
    {
      startDate: new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        1
      ),
      endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      key: "range1",
    },
  ]);
  const {data:taskTypeData}=useGetTaskTypeDetailQuery({taskTypeId:selectedTaskTypeId})
  const {data:userListData}=useGetPropertyUserListQuery();

useEffect(()=>{
  if(isEdit){
    
    methods.setValue("fullName", taskTypeData?.data?.name);
    setSelectedTaskType(taskTypeData?.data?.type)
    setSelectedTaskFreq(taskTypeData?.data?.frequency);
    setSelectedTaskRecurrence(taskTypeData?.data?.recurrence);
    setSelectedTaskCategory(taskTypeData?.data?.category)
    setSelectedIsMendatory(taskTypeData?.data?.isMandatory)
    setSelectedValidationFunctionality(taskTypeData?.data?.validation)
    methods.setValue('textarea',taskTypeData?.data?.description)
    setSelectedPriority(taskTypeData?.data?.priority)
    // setInputValue(taskTypeData?.data?.expectedTime)
    setTaskTag(taskTypeData?.data?.tags);
    setRedirectArray(taskTypeData?.data?.checkUp)
    setCreatedDate(taskTypeData?.data?.createdBy)
    setDeadlineDate(taskTypeData?.data?.deadline)
    setAssignedBy(taskTypeData?.data?.assignedBy)
    setAssignedTo(taskTypeData?.data?.assignedTo)
  }
},[taskTypeData?.data])
  const options = ["Revenue", "ADR", "Room Nights"];
  const methods = useForm({
    // resolver: zodResolver(schema),
    defaultValues: {
      textarea: "", // Default value for the textarea
    },
  });

  const [FirstPeriod, setFirstPeriod] = useState([
    {
      startDate: new Date(
        // currentDate.getFullYear() - 1,
        // currentDate.getMonth(),
        // 1
        "2024-08-08"
      ),
      endDate: new Date(
        // currentDate.getFullYear() - 1,
        // currentDate.getMonth() + 1,
        // 0
        "2024-08-31"
      ),
      key: "range1",
    },
  ]);
  const [SecondPeriod, setSecondPeriod] = useState([
    {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      key: "range2",
    },
  ]);
  const [period2ShowDropDown, setPeriod2ShowDropDown] = useState(false);
  const [period1ShowDropDown, setPeriod1ShowDropDown] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(1);

  const ref = useRef();
  const ref2 = useRef();

  const [periodOneCalender, setPeriodOneCalender] = useState(false);
  const [periodTwoCalender, setPeriodTwoCalender] = useState(false);

  const [startEndPeriod2, setStartEndPeriod2] = useState([
    {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      key: "range2",
    },
  ]);
  function convertToSeconds(input) {
    // Split input into number and unit
    const [amount, unit] = input.split(" ");

    // Convert amount to a number
    const num = parseInt(amount, 10);

    // Calculate seconds based on the unit
    if (unit) {
      switch (unit.toLowerCase()) {
        case "day":
        case "days":
          return num * 24 * 60 * 60; // Days to seconds
        case "hour":
        case "hours":
          return num * 60 * 60; // Hours to seconds
        case "minute":
        case "minutes":
          return num * 60; // Minutes to seconds
        case "second":
        case "seconds":
          return num; // Already in seconds
        default:
          return null; // Return null if the unit is not recognized
      }
    } else {
      return null;
    }
  }
  const [inputValue, setInputValue] = useState("7 minutes");

  const [inputValuePeriod2, setInputValuePeriod2] = useState(
    `${format(new Date(startEndPeriod2[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod2[0].endDate), "dd-MMM-yy")}`
  );

  // Destructure methods
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // Submit handler
  const onSubmit = (data) => {
    // if(selectedParentTask  || selectedTaskType ||selectedTaskFreq||selectedTaskRecurrence ||selectedTaskCategory ||selectedIsMendatory || selectedValidationFunctionality ||selectedProperties ||redirectArray?.length<1 ||selectedValue ||inputValue ||taskTag){

    //     setSubmitError(true)
    //     return ;
    // }
    let apiObj = {
      parentTaskId: selectedParentTask,
      name: data?.fullName,
      type: selectedTaskType,
      frequency: selectedTaskFreq,
      recurrence: selectedTaskRecurrence ,
      category: selectedTaskCategory,
      isMandatory: selectedIsMendatory ,
      validation: selectedValidationFunctionality,
      applicableProperties: selectedSpecificProperty,
      propertiesApplication: selectedProperties,
      checkUp: redirectArray,
      description: data?.textarea,
      priority: selectedPriority,
      expectedTime: convertToSeconds(inputValue),
      tags: taskTag,
      taskTypeId:selectedTaskTypeId,
      createdFor:createdDate,
      deadline:deadlineDate,
      assignedTo:assignedTo,
      assignedBy:assignedBy,
    };
    setSubmitError(false);


    if(isEdit){
      editTask(apiObj)
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        setShowAddTask(false);
        refetch();
      })
      .catch((err) => {
        toast.error(err.data.message, { position: "bottom-right" });
      });
    }else{

      postTask(apiObj)
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        setShowAddTask(false);
        refetch();
      })
      .catch((err) => {
        toast.error(err.data.message, { position: "bottom-right" });
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPeriodOneCalender(false);
        setPeriod1ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref2?.current && !ref2?.current.contains(event.target)) {
        setPeriodTwoCalender(false);
        setPeriod2ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref2]);

  const handleSave = () => {
    setFirstPeriod(startEndPeriod1);
    setSecondPeriod(startEndPeriod2);
  };


  return (
    <div className="fixed  z-50 inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div className="bg-lightThemeGradient dark:bg-darkThemeGradient w-full max-w-[1260px]">

       
      <div className="bg-lightThemeGradient dark:bg-darkThemeGradient w-full max-w-[1260px] text-darkThemeFontColor rounded-lg ">
        <div className="relative w-full flex rounded-lg justify-center py-4">
         {!(currentScreen === 2) &&(<div className="absolute top-4 left-4 flex gap-2 items-center"> <div
            onClick={() => setShowAddTask(false)}
            className="cursor-pointer text-white text-2xl"
          >
              <IoClose />
          </div>
           <div
           onClick={() => setShowAddTask(false)}
           className=" cursor-pointer text-white font-bold text-xl"
         >
             Add Task 
         </div></div>)
          
          
          }
            {currentScreen > 1 && (
          <div className="absolute top-4 flex left-4 bg-blue-600   rounded-lg cursor-pointer text-white">
                <div
                onClick={() => setCurrentScreen((prev) => prev - 1)}
                className="p-2 "
                >
                Back
              </div>
            </div>
            )}
            {!(currentScreen === 2) && (
            <div className="absolute top-4 flex right-4 bg-blue-600 p-2 rounded-lg cursor-pointer text-white">
              <div
                onClick={() => setCurrentScreen((prev) => prev + 1)}
                // className="absolute top-4 right-4 bg-blue-600 p-2 rounded-lg cursor-pointer text-white "
              >
                Next
              </div>
            </div>
            )}
          {currentScreen == 1 && (
            <FormProvider {...methods}>
              <form
                className="grid grid-cols-2 mt-6 rounded-lg gap-4 p-6 w-full h-4/5"
                onSubmit={methods.handleSubmit("")}
              >
                <div className="col-span-1 h-14">
                  <ReusableInput
                    label="Task Name"
                    name="fullName"
                    type="text"
                    placeholder="Enter Task Name"
                    rules={{ required: "Task Name is required" }}
                  />
                </div>
                <div className="col-span-1  ">
                  <p className="mb-2">Task Type</p>
                  <div className="h-12">
                    <NormalDropDown
                      selectedValue={selectedTaskType}
                      setSelectedValue={setSelectedTaskType}
                      options={["Task", "Sub Task","Special Task"]}
                      label={"Select value"}
                    />
                  </div>
                </div>

                {(selectedTaskType == "Sub Task" ) && (
                  <div className="col-span-1">
                    <p>Parent Task</p>
                    <div className="h-12">
                      <NormalDropDown
                        selectedValue={selectedParentTask}
                        setSelectedValue={setSelectedParentTask}
                        options={utilsData?.data?.task}
                        sendOnlyId={true}
                        label={"Select value"}
                      />
                    </div>
                  </div>
                )}
          {selectedTaskType!='Special Task' &&      <div className="col-span-1 flex items-center justify-between">
                  <p>Task Recurrence</p>
                  {/* <div className="h-12 flex items-center"> */}
                    {/* <NormalDropDown
                      selectedValue={selectedTaskRecurrence}
                      setSelectedValue={setSelectedTaskRecurrence}
                      options={["True", "False"]}
                      label={"Select value"}
                    /> */}
<div className='w-fit h-14'>
                  <Toggle onClick={()=>setSelectedTaskRecurrence((pre=>!pre))} isToggled={selectedTaskRecurrence}/>
                  </div>
                  {/* </div>             */}
                </div>}

              {selectedTaskRecurrence &&  <div className="col-span-1">
                  <p>Task Frequency</p>
                  <div className="h-12">
                    <NormalDropDown
                      selectedValue={selectedTaskFreq}
                      setSelectedValue={setSelectedTaskFreq}
                      options={[
                        "Daily",
                        "Weekly",
                        "Monthly",
                        "Quaterly",
                        "Yearly",
                      ]}
                      label={"Select value"}
                    />
                  </div>
                </div>}
           { selectedTaskType!='Task'  &&   <div className="col-span-1">
                  <p>Task Category</p>
                  <div className="h-12">
                    <NormalDropDown
                      selectedValue={selectedTaskCategory}
                      setSelectedValue={setSelectedTaskCategory}
                      options={["Validation Task", "Checkup Task",'Meeting Task']}
                      label={"Select value"}
                      // isTextField={true} 
                    />
                  </div>
                </div>}
{/* {selectedTaskCategory==='Meeting Task' && } */}
                {selectedTaskCategory==='Meeting Task' &&  <>  <div className="col-span-1 ">
                  <ReusableInput
                    label="Meeting Link"
                    name="meetingLink"
                    type="text"
                    placeholder="Enter Meeting Link"
                    rules={{ required: "Meeting Link is required" }}
                    // showIcon={true}
                  />
                </div> <div className="col-span-1 ">
                  <ReusableInput
                    label="Meeting 
                    Title"
                    name="meetingTitle"
                    type="text"
                    placeholder="Enter Meeting Title"
                    rules={{ required: "Meeting Title is required" }}
                    // showIcon={true}
                  />
                </div><div className="col-span-1 ">
                  <ReusableInput
                    label="Meeting 
                    Description"
                    name="meetingDescription"
                    type="text"
                    placeholder="Enter Meeting Description"
                    rules={{ required: "Meeting description is required" }}
                    // showIcon={true}
                  />
                </div></>}
              
                {selectedTaskCategory === "Validation Task" &&
                  (selectedTaskType == "Sub Task" || selectedTaskType =='Special Task') && (
                    <div className="col-span-1">
                      <p>Validation Functionality</p>
                      <div className="h-12">
                        <NormalDropDown
                          selectedValue={selectedValidationFunctionality}
                          setSelectedValue={setSelectedValidationFunctionality}
                          options={utilsData?.data?.validationFunction}
                          label={"Select value"}
                          sendOnlyId={true}
                        />
                      </div>
                    </div>
                  )}
                <div className="col-span-1 flex    justify-between  items-center ">
                  <p>Is Mandatory</p>
                  {/* <div className="h-12">
                    <NormalDropDown
                      selectedValue={selectedIsMendatory}
                      setSelectedValue={setSelectedIsMendatory}
                      options={["True", "False"]}
                      label={"Select value"}
                    />
                  </div> */}<div className='w-fit h-14'>
                   <Toggle onClick={()=>setSelectedIsMendatory((pre=>!pre))} isToggled={selectedIsMendatory}/>
                </div>
                </div>

                {/* 
                <div className="col-span-1">
                  <p>Checkup Redirects</p>
                  <div className="h-14">
                    <NormalDropDown
                      selectedValue={selectedCheckup}
                      setSelectedValue={setSelectedCheckup}
                      options={utilsData?.data?.redirectURIs}
                      label={"Select value"}
                      sendOnlyId={true}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <p>Time Period</p>
                  <div className="h-14">
                    <NormalDropDown
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      options={[
                        "Last week",
                        "Last Month",
                        "Same Time Last Year",
                      ]}
                      label={"Select value"}
                      //   sendOnlyId={true}
                    />
                  </div>
                </div> */}

                <div className="col-span-1">
                  <p>Properties Application</p>
                  <div className="h-12">
                    <NormalDropDown
                      selectedValue={selectedProperties}
                      setSelectedValue={setSelectedProperties}
                      options={[
                        "Apply to all",
                        "Include Specific Hotel",
                        "Exclude Specific Hotel",
                      ]}
                      label={"Select value"}
                      //   sendOnlyId={true}
                    />
                  </div>
                </div>
                {(selectedProperties ==='Include Specific Hotel' ||selectedProperties ==='Exclude Specific Hotel') && <div className="col-span-1">
                  <p>Specific Properties Selection</p>
                  <div className="h-12">
                    <NormalDropDown
                      selectedValue={selectedSpecificProperty}
                      setSelectedValue={setSelectedSpecificProperty}
                      options={utilsData?.data?.propertyList}
                      label={"Select value"}
                      sendOnlyId={true}
                      isMulti={true}
                    />
                  </div>
                </div>}
                {selectedTaskCategory === "Checkup Task" && (
                  <CheckupForm
                    redirectArray={redirectArray}
                    setRedirectArray={setRedirectArray}
                    utilsData={utilsData?.data?.redirectURIs}
                  />
                )}
              </form>
            </FormProvider>
          )}

          {currentScreen === 2 && (
            <FormProvider {...methods}>
              <form
                className="grid grid-cols-2 gap-4 rounded-lg mt-6 p-6 w-full h-4/5 overflow-y-auto"
                onSubmit={handleSubmit(onSubmit)} // Pass the onSubmit function here
              >
                <div className="col-span-2">
                  <label
                    htmlFor="textarea"
                    className="block text-sm font-bold text-darkThemeFontColor "
                  >
                    Task Description
                  </label>
                  <textarea
                    id="textarea"
                    {...register("textarea", {
                      required: "This field is required",
                    })}
                    rows="4" // Number of rows (height) for the textarea
                    className="mt-1 block p-2 w-full border border-gray-300 bg-transparent  rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.textarea && (
                    <p className="mt-2 text-red-600 text-sm">
                      {errors.textarea.message}
                    </p>
                  )}
                </div>

                <div className="w-full">
                  <p>Task Tag</p>
                  <div className="w-full h-14 ">
                    <NormalDropDown
                      selectedValue={taskTag}
                      setSelectedValue={setTaskTag}
                      options={utilsData?.data?.taskTag}
                      label={"Select value"}
                      sendOnlyId={true}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p>Priority</p>
                  <div className="w-full h-14">
                    <NormalDropDown
                      selectedValue={selectedPriority}
                      setSelectedValue={setSelectedPriority}
                      options={["High", "Low"]}
                      label={"Select value"}
                    />
                  </div>
                </div>
                <div className="w-full">
                    <h1>Assigned by</h1>
                          <div className="w-full h-14">
                      <NormalDropDown
                        selectedValue={assignedBy}
                        setSelectedValue={setAssignedBy}
                        options={userListData?.data}
                        label={"Select assigned by"}
                        sendOnlyId={true}
                      />
                    </div> 
                </div>
                <div className="w-full">
                    <h1>Assigned to</h1>
                          <div className="w-full h-14">
                      <NormalDropDown
                        selectedValue={assignedTo}
                        setSelectedValue={setAssignedTo}
                        options={userListData?.data}
                        sendOnlyId={true}
                        label={"Select assgined to"}
                      
                      />
                    </div> 
                </div>
           {  !selectedTaskRecurrence &&   <div className="w-full">
                    <h1>Created on</h1>
                          <div className="w-full border rounded-lg">
                          <DateTimePicker
                        onChange={setCreatedDate}
                        value={createdDate}
                        format="y-MM-dd"
                        className="w-full relative rounded-lg p-2 "
                      />
                    </div>
                </div>}
                <div className="w-full">
                    <h1>Deadline</h1>
                          <div className="w-full border rounded-lg">
                          <DateTimePicker
                        onChange={setDeadlineDate}
                        value={deadlineDate}
                        format="y-MM-dd"
                        className="w-full relative rounded-lg p-2 "
                      />
                    </div> 
                </div>
              
                <div className="w-full">
                  <p>Expected Time</p>
                  {/* <div className="w-full">
                      <NormalDropDown
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        options={["Hgh","Low"]}
                        label={"Select Frequency of task"}
                      
                      />
                    </div> */}
                  <div className="flex gap-4 w-full items-center">
                    <div className="flex items-center gap-2 w-[100%]">
                      <div className="flex w-full justify-start" ref={ref}>
                        <TimeRange
                          period={startEndPeriod1}
                          setPeriod={setStartEndPeriod1}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          left={"-300px"}
                          setCalendarOpen={setPeriodOneCalender}
                          calendarOpen={periodOneCalender}
                          isFuture={false}
                          showDropDown={period1ShowDropDown}
                          setShowDropDown={setPeriod1ShowDropDown}
                          // minDate={new Date() || new Date()}
                          // maxDate={new Date() || new Date()}
                        />
                      </div>
                    </div>

                    {/* <div className="text-gray-400">
            <div className="flex items-center justify-center gap-2">
                {" "}
                <div className="h-4 w-4   leading-tight  rounded-md bg-blue-600"></div>{" "}
                <p>1 Jan 24 - 31 Dec 25 </p>
            </div>
            <div className="flex items-center  justify-center gap-2">
                {" "}
                <div className="h-4 w-4  leading-snug rounded-md bg-slate-700"></div>{" "}
                <p>1 Jan 23 - 31 Dec 24 </p>
            </div>
            </div> */}
                  </div>
                </div>
                {/* <h1 className="font-semibold">Attachment</h1> */}
                <div className="flex  gap-4">
                  {imageURL &&
                    imageURL.map((item) => (
                      <div className="relative w-24 h-24 rounded-full  border">
                        <div className="absolute -top-2 bg-red-600 right-0 px-2 rounded-lg ">
                          x
                        </div>
                        <img
                          className="w-full h-full object-fit rounded-full"
                          src={item}
                          alt="Uploaded"
                        />
                      </div>
                    ))}
                  {/* <div className="w-24 h-24 rounded-full overflow-hidden">
                <label className="w-full h-full bg-gray-800 flex items-center justify-center cursor-pointer">
                  <div className="flex items-center justify-center w-full h-full text-white">
                    Upload
                  </div>

                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onload = () => setImageURL((prev)=>[...prev,reader.result]);
                      reader.readAsDataURL(file);
                      console.log(file)
                      const formData = new FormData();
                      formData.append('taskAttachment', file);
                      formData.append('docType',file.type)
                      postImage({taskAttachment:file, docType:file.type, taskId:'taskId'})
                    }}
                    accept="*"
                    className="hidden"
                  />
                </label>
              </div> */}
                </div>
                {submitError && (
                  <h1 className=" col-span-2 text-red-600">
                    All fileds are mendatory
                  </h1>
                )}
                <div className="col-span-2">
                  <button
                    type="submit"
                    className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default AddTask;
