import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getToken from "../utils/getToken";
import { BASE_URL } from "../helpers/helpers";
import Cookies from "js-cookie";

// Get the token from localStorage or wherever it's stored
const localStorageToken = getToken();
const baseurl ="https://staging.worldserver.retvenslabs.com/api/"
// Custom baseQuery with global error handling for 401
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL || baseurl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.token;
    if (token || localStorageToken) {
      headers.set("authorization", token || localStorageToken);
    }
    return headers;
  },
});

// Wrapper for baseQuery that handles 401 errors globally
const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // Handle 401: Logout the user, clear cookies, and reload or redirect
    console.log("Logout due to 401");

    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookieName) => {
        if(cookieName!=='recentLogin'){
            Cookies.remove(cookieName);
        }
      // Clear all cookies
    });

    localStorage.clear();

    // Optionally, redirect to login or home page
    window.location.reload(); // This will reload the page
    // Or use: window.location.href = "/"; to redirect to a specific page
  }
  return result;
};

// Define the API slice
export const emptySplitApi = createApi({
  reducerPath: "emptySplitApi",
  baseQuery: baseQueryWithAuth, // Use the wrapper to intercept 401 errors
  endpoints: () => ({}),
  tagTypes: [
    "login",
    "signup",
    "onBoarding",
    "basicDetails",
    "OTPVerfication",
    "ResendOTP",
    "loginInfo",
    "forgotPassword",
    "resetPassword",
    "getAllCategory",
    "onBoardingInviteMember",
    "useDesignationQuery",
    "addScenarioByUser",
    "updateFrequency",
    "getScenarioFrequency",
    "revenueGrowth",
    "getActivityManager",
    "getChannelPerformance",
    "getPropertyChain",
    "getStarCategory",
    "getAllFaq",
    "getClientPerformance",
    "addClient",
    "addEmployee",
    "getOnboardingFunnel",
    "getClient",
    "getUsersList",
    "getClientDetail",
    "getUserDetail",
    "getDepartment",
    "switchToRatex",
    "getDesignation",
    "updateCurrentScreen",
    "getTaskFilter",
    "getTask",
    "getTaskDetail",
    "getUtils",
    "getTaskType",
    "postImage",
    "patchTaskStatus",
    "getEmployeeOverview",
    "getPersonalProgress",
    "getTeamAcitivy",
    "remmoveImage",
    "getReminder",
    "addReminder",
    "getUpComingMeeting",
    "getAllMeeting",
    "getHotelPerformanceSummery",
    "editReminder",
    "getReminder",
    "getClientCRO",
    "getPropertiesOnRisk",
    "otaRevenueBreakdown",
    "getPropertyDetail",
    "otaTable",
    "PropertyRevenueBreakdonw",
    "propertyManagement",
    "clientManagement",
    "composetDetail",
    "changePassword",
    "getUserProfileInformation",
    "editUserProfile",
    "editProfilePicture",
    "getSingleReminder",
    "getPerformanceDashboard",
    "editNotification",
    "clientPropertyRevenue",
    "editUserProfile",
    "getEmployeePi",
    "employeeProperty",
    "editEmployeeProfilePicturePicture",
    "editEmployeeDetail",
    "getEmployeeActivity",
    "getHeaderUtils",
    "clientPropertyRevenue",
    "clientPropertyManagement",
    "getPropertyAssign",
    "unAssignProperty",
    "addPropertyDetail",
    "getPropertyActivity",
    "getUserList",
    "postPropertyAssign",
    "deleteProperty",
    "getTaskTypeDetail",
    "editTaskTypeDetail",
    "getPropertyUserList",
    "getClientsList",
    "deleteClient",
    "deactiveProperty",
    "addClientTransaction",
    "addWhatsappGroup",
    "getWhatsappGroup",
    "removeProfilePicture",
    "getSearchUser"
  ],
});
