import { useState } from "react";
import NormalDropDown from "./DropDown/NormalDropDown";

const CheckupForm = ({ utilsData,setRedirectArray,redirectArray }) => {
  const [selectedCheckup, setSelectedCheckup] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
   const [checkupError,setCheckupError]=useState(null)

  // Function to add new object to the array
  const addNewRedirect = () => {
    if (selectedCheckup && selectedValue) {
      const newRedirect = {
        checkupRedirect: selectedCheckup,
        timePeriod: selectedValue,
      };

      setRedirectArray([...redirectArray, newRedirect]);
      setSelectedCheckup(null); // Reset the selected checkup after adding
      setSelectedValue(null);   // Reset the selected time period after adding
      setCheckupError(null)
    }else{
      setCheckupError('Select Checkup and Time Period')
    }
  };

  // Function to delete an item from the array based on index
  const deleteRedirect = (indexToDelete) => {
    setRedirectArray(redirectArray.filter((_, index) => index !== indexToDelete));
  };
  const checkupMap = new Map(utilsData?.map((item)=>[item?.id,item?.name]))

  return (
<div className="grid col-span-2 grid-cols-2 gap-4">
  {/* First column: Dropdowns */}
  <div className="col-span-1 w-full  space-y-4">
    <div className="flex gap-4">
   
        
    <div className="w-1/2">
      <p>Checkup Redirects</p>
      <div className="h-14">
        <NormalDropDown
          selectedValue={selectedCheckup}
          setSelectedValue={setSelectedCheckup}
          options={utilsData}
          label={"Select value"}
          sendOnlyId={true}
        />
      </div>
    </div>

    <div className="w-1/2">
      <p>Time Period</p>
      <div className="h-14">
        <NormalDropDown
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          options={["Last week", "Last Month", "Same Time Last Year"]}
          label={"Select value"}
        />
      </div>
    </div>
    </div>
    {/* Add New Button */}
    <div
      onClick={addNewRedirect}
      className="mt-4 bg-blue-500 w-fit text-white px-4 py-2 rounded"
    >
      Add New
    </div>
   <span className="font-semibold text-sm"> {checkupError}</span>
  </div>

  {/* Second column: Added Redirects */}
  <div className="col-span-1 mt-4">
  {  redirectArray?.length>0 && <h3>Added Redirects:</h3>}
    <ul>
      {redirectArray.map((item, index) => (
        <li key={index} className="flex  mt-2 gap-2 items-center">
          <div className="w-full flex  gap-4">
        <div>    <span className="font-bold">Checkup:</span> {checkupMap.get(item.checkupRedirect)}</div>
      <div>      <span className="font-bold">Period:</span> {item.timePeriod}</div>
          </div>
          <div
            onClick={() => deleteRedirect(index)}
            className="ml-4 bg-red-500 text-white px-2 py-1 rounded"
          >
            Delete
          </div>
        </li>
      ))}
    </ul>
  </div>
</div>

  );
};

export default CheckupForm;
