import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import Button from "../../button";
import { IoCheckmarkDone } from "react-icons/io5";


const WelcomeScreenTableTabComponent = ({
  text = "task",
  completionStatus = false,
  clickFunc = () => {},
}) => {
  return (
    <>
      <div
        className={`my-2 flex w-full flex-row justify-between items-center  px-[10px] ${completionStatus ? "" : ""}`}
      >
        <div className="flex flex-row space-x-3 justify-start items-center  ">
          <FaArrowRightLong className="text-white h-[14px]" />
          <p className="text-[14px] font-[600]">{text}</p>
        </div>
        {completionStatus ? (
          <IoCheckmarkDone className="h-[18px]" />
        ) : (
          <Button
            type="outlined"
            hasIcon={true}
            isLoading={false}
            buttonText={"Try It"}
            Icon={FaArrowRightLong}
            clickFunc={clickFunc}
          />
        )}
      </div>
    </>
  );
};

export default WelcomeScreenTableTabComponent;
