import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsIpad } from "../../utils/isIpad";
import ActivityManager from "../../component/ApexGraphComponent/ActivityManager/ActivityManager";
import SingleChainProperty from "../../component/ApexGraphComponent/SingleChainProperty";
import ChannelPerformance from "../../component/ApexGraphComponent/ChannelPerformance";
import TopFiveClients from "../../component/ApexGraphComponent/TopFiveClients";
import CriticalClients from "../../component/ApexGraphComponent/CriticalClients";
import RevenueGrowth from "../../component/ApexGraphComponent/RevenueGrowth";
import ClientByStarCategory from "../../component/ApexGraphComponent/ClientByStarCategory";
import OnboardingFunnel from "../../component/ApexGraphComponent/OnboardingFunnel";
import ClientWrapper from "../../component/WrapperComponents/ClientWrapper";
import Reminder from "../../component/Dashboard/Reminder";
import UpComingMettings from "../../component/Dashboard/upComingMettings";
import EmployeeOverview from "../../component/ApexGraphComponent/EmployeeOverview";
import PersonalProgress from "../../component/ApexGraphComponent/PersonalProgress";
import TeamActivity from "../../component/TeamActivity";
import UpComingTask from "../../component/Dashboard/UpComingTask";
import HotelPerformanceSummery from "../../component/ApexGraphComponent/HotelPerformanceSummery";
import { useGetClientCROQuery } from "../../redux/slices/dashboard";
import PropertiesOnRisk from "../../component/DashboardScreen/PropertiesOnRisk";

const CRODashboard = () => {
  const isIpad = useIsIpad();
  const { data: clientCro } = useGetClientCROQuery();

  function getGreeting() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      return "Good morning";
    } else if (hour >= 12 && hour < 17) {
      return "Good afternoon";
    } else if (hour >= 17 && hour < 20) {
      return "Good evening";
    } else {
      return "Good night";
    }
  }

  const navigate = useNavigate();

  return (
    <div className="p-4 mx-auto mt-10 w-[90%] h-auto">
      <div className="flex justify-center text-lightThemeFontColor dark:text-darkThemeFontColor flex-col p-4 items-center">
        <h2>
          {new Date().toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
        </h2>
        <h1 className="font-semibold text-2xl">{getGreeting()}, Partner!</h1>
      </div>
      <div className="flex flex-wrap justify-between">
        <div className="flex w-full justify-between gap-4">
          <div className="w-2/5">
            <ClientWrapper
              name={"Number of client"}
              value={clientCro?.data?.numberOfClient}
            />
          </div>
          <div className="w-2/5">
            <ClientWrapper
              name={"Distress Client"}
              value={clientCro?.data?.distressCount}
            />
          </div>
          <div className="w-2/5">
            <ClientWrapper
              name={"Active Employee"}
              value={clientCro?.data?.activeEmployee}
            />
          </div>
          <div className="w-2/5">
            <ClientWrapper
              name={"Current Onboarding"}
              value={clientCro?.data?.currentOnboarding}
            />
          </div>
        </div>
        <div className="w-[100%] flex justify-between gap-4">
          <div
            className={`${isIpad ? "w-[49%] my-2" : "w-[65%] my-2"} min-h-[150px]`}
          >
            <EmployeeOverview />
          </div>
          <div className="w-[35%]">
            <UpComingTask />
          </div>
        </div>

        <div
          className={`${isIpad ? "w-[100%] my-2" : "w-[100%] my-2"} min-h-[300px]`}
        >
          <PropertiesOnRisk />
        </div>
      </div>
    </div>
  );
};

export default CRODashboard;
