import getAccessToken from "../../../utils/getAccessToken";
import getToken from "../../../utils/getToken";
import { emptySplitApi } from "../../injectEndpoint";

export const Finance = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addNewCustomer: builder.mutation({
      query: (data) => ({
        url: `/finance/addNewCustomer`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      providesTags: ["addNewCustomer"],
    }),
    addClientTransaction: builder.mutation({
      query: (data) => {
        const formData = new FormData();
    formData.append('clientId',data?.clientId)
        formData.append('transactionId', data?.transactionId);
        formData.append('clientTransactionImage', data?.img);
    
        return {
          url: `/finance/addClientTransaction`,
          method: 'POST',
          headers: {
            Authorization: getToken(),
          },
          body: formData,
        };
      },
      providesTags: ["addClientTransaction"],
    }),
    
})
});

export const {
  useAddNewCustomerMutation,
  useAddClientTransactionMutation,
} = Finance;
