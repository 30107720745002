import React from "react";
import { useGetAllMeetingQuery } from "../../redux/slices/dashboard";
import Button from "../button";
import { useNavigate } from "react-router-dom";

const AllMeetings = () => {
  const { data: getAllMeetingData } = useGetAllMeetingQuery();
  const navigate = useNavigate();
  return (
    <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div className="bg-lightThemeGradient dark:bg-darkThemeGradient w-full max-w-[80%] text-darkThemeFontColor rounded-lg shadow-md">
        <div className="relative w-full flex justify-center py-4">
            <div className="mx-4 w-fit">

<Button onClick={()=>{navigate(-1)}} type='primaryd'>Back</Button>
            </div>
          <div className="w-full flex flex-col gap-2 max-h-[600px] overflow-y-scroll p-4">
      {getAllMeetingData?.data.length>0 ? getAllMeetingData?.data?.map((reminder, index) => (
        <div className="bg-purple-200 relative rounded-lg p-4 h-auto w-full shadow-lg flex flex-col justify-between">
          <div className=" absolute right-4 flex justify-end mt-4">
            <img
              src="https://www.gstatic.com/images/branding/product/1x/meet_2020q4_48dp.png"
              alt="Google Meet"
              className="w-8 h-8"
            />
          </div>
          <div className="text-left">
            <p className="text-xs text-gray-600">{reminder?.startTime}</p>
            <p className="text-lg font-bold text-gray-800">
              {reminder?.endTime}
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-medium h-fit text-gray-900 ">
              {reminder?.meetingDescription}
            </p>
          </div>
        </div>
      )):<div class="flex items-center justify-center w-full p-6  rounded-lg">
      <p class="dark:text-darkThemeFontColor text-lightThemeFontColor  text-lg font-medium">No meetings found</p>
    </div>
    }
        </div>
    </div>
    </div>
    </div>
  );
};

export default AllMeetings;
