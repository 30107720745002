import React, { useEffect, useState } from "react";

import Button from "../../component/button";
import ReusableInput from "../../component/InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import {
  useAddClientMutation,
  useGetDepartmentQuery,
  useUpdateClientMutation,
} from "../../redux/slices/Setting";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import { useGetDesignationQuery } from "../../redux/slices/dashboard";
import { useOnBoardingInviteMemberMutation } from "../../redux/slices/onBoarding";

const AddEmployee = ({
  data,
  setShowAddClient,
  editClients = false,
  getClientRefetch,
}) => {
  const methods = useForm();
  const { hotelId } = useParams();
  const [propertyType, setPropertyType] = useState("Single Property");
  const [chainName, setChainName] = useState();
  const [assignedTo, setAssignedTo] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [value, onChange] = useState(new Date());
  const [sampleArray, setSampleArray] = useState([]);
  const [departmentOption, setDepartmentOptions] = useState([]);
  const { data: departmentData } = useGetDepartmentQuery();
  const [selectedValue, setSelectedValue] = useState();
  const [selectedDesignation, setSelectedDesignation] = useState();
  const [onBoardingInviteMember] = useOnBoardingInviteMemberMutation();
  const {
    data: designationOptions,
    isLoading,
    isFetching,
  } = useGetDesignationQuery({
    departmentId: selectedDepartment,
  });
  const handleDepartmentChange = (index, value) => {
    setSampleArray((prevArray) =>
      prevArray.map((member, i) =>
        i === index ? { ...member, department: value } : member
      )
    );
  };
  const handleDesignationChange = (index, value) => {
    setSampleArray((prevArray) =>
      prevArray.map((member, i) =>
        i === index ? { ...member, designation: value } : member
      )
    );
  };
  const propertyTypeOption = ["Single Property", "Chain Property"];

  //   const assignedToOption = userDropDown?.data;
  const [updateClient] = useUpdateClientMutation();
  const [addClient] = useAddClientMutation();

  const onSubmit = (data) => {
    const tempArray = [
      {
        fullName: data?.fullName,
        designation: selectedDesignation,
        department: selectedDepartment,
        phoneNumber: data?.phoneNumber,
        email: data?.email,
      },
    ];
    onBoardingInviteMember({ people: tempArray })
      .unwrap()
      .then((res) => {
          setShowAddClient(false);
        toast.success(res?.message, { position: "bottom-right" });
        getClientRefetch();
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
  //   useEffect(() => {
  //     setDepartmentOptions(departmentData?.data || []);
  //   }, [departmentData?.data]);
  return (
    <div className="w-[100%] bg-lightThemeGradient h-full rounded-lg dark:bg-darkThemeGradient text-darkThemeFontColor p-4  overflow-scroll">
      <FormProvider {...methods}>
        <form
          className="flex flex-col w-full justify-start items-between"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <div className="">
                <Button
                  onClick={() => {
                    setShowAddClient(false);
                  }}
                >
                  <FaArrowLeft />
                </Button>
              </div>
              <div className="">
                <h1 className="text-darkThemeFontColor text-2xl font-bold">
                  Add New Employee
                </h1>

                <p className="">
                  Let's add a new employee and assign to your team
                </p>
              </div>
            </div>
            <div>
              <Button>Save Employee</Button>
            </div>
          </div>

          <div className="flex mt-2 flex-col gap-2 w-full">
  <ReusableInput
    label="Employee Name"
    name="clientName"
    type="text"
    placeholder="Enter property name"
    rules={{ required: "Employee name is required" }}
    showIcon={false}
  />
  <ReusableInput
    label="Phone Number"
    name="phoneNumber"
    type="phoneNumber"
    placeholder="Enter phone number"
    rules={{ required: "Employee phonenumber is required" }}
    showIcon={false}
  />

  <ReusableInput
    label="Email"
    name="email"
    type="text"
    placeholder="Enter employee email"
    rules={{ required: "Email is required" }}
    showIcon={false}
  />

  {/* Adjusted height and added margin to the dropdowns */}
  <div className="w-full   ">
    <div>Department</div>
    <div className="h-14">
    <NormalDropDown
      options={departmentData?.data}
      label="Select an Department"
      selectedValue={selectedDepartment}
      setSelectedValue={setSelectedDepartment}
      sendOnlyId={true}
    />
      </div>
  </div>

  <div className="w-full  ">
    <div>Designation</div>
    <div className="h-14">
    <NormalDropDown
      options={designationOptions?.data}
      label="Select an Designation"
      selectedValue={selectedDesignation || "select an option"}
      setSelectedValue={setSelectedDesignation}
      sendOnlyId={true}
    />
    </div>
  </div>
</div>

        </form>
      </FormProvider>
    </div>
  );
};

export default AddEmployee;
