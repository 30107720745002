import React, { useEffect, useState } from "react";
import { LiaEdit } from "react-icons/lia";
import Skeleton from "../Skeleton";
import OtherUserComponent from "../Table/OtherUserComponent";
const UserProfileWrapper = ({ children,label, isLoading,showEditButton,showAddButton,setShowAddButton,dataArray}) => {
//     const [otherUsersearchText, setOtherUserSearchText] = useState("");
//     const [selectedIndex, setSelectedIndex] = useState(null);
//       useEffect(() => {
//     if (dataArray) {
//       setDuplicateData(dataArray);
//     }
//   }, [dataArray]);
//   const handleAssignToggle = (propertyIndex, userIndex, hId) => {
//     setDuplicateData((prevState) => {
//       const updatedState = prevState?.map((property, pIdx) => {
//         if (pIdx === propertyIndex) {
//           return {
//             ...property,
//             otherUser: property?.otherUser?.map((user, uIdx) =>
//               uIdx === userIndex
//                 ? { ...user, isAssigned: !user?.isAssigned }
//                 : user
//             ),
//           };
//         }
//         return property;
//       });

//       // Filter the updated property based on hId using updatedState
//       const filterPropArray = updatedState?.filter((item) => {
//         return parseInt(item?.hId) === parseInt(hId);
//       });

//       // console.log(filterPropArray && filterPropArray[0]?.otherUser);

//       setObjToAssign({
//         hId: hId,
//         otherUser: filterPropArray && filterPropArray[0]?.otherUser,
//       });

//       // Return the updated state to setDuplicateData
//       return updatedState;
//     });
//   };

//   const handleAssignSave = () => {
//     assiginPropertyTask(objToAssign)
//       .unwrap()
//       .then((res) => {
//         toast.success("Assigining task updated successfully!", {
//           position: "bottom-right",
//         });
//         setSelectedIndex(null);
//         refetch();
//       })
//       .catch((err) => {
//         toast.error("Something went wrong!", { position: "bottom-right" });
//         setSelectedIndex(null);
//       });
//   };

  return (
    isLoading ? <div className="h-[400px]"><Skeleton/> </div> :<div className=" flex flex-col gap-2 p-4 rounded-lg w-full">
    <div className="w-full flex  justify-between">

  <h1 className="border-b-2 w-full ">{label}</h1>
  {showEditButton && <div onClick={()=>{showEditButton(true)}} className="w-fit cursor-pointer border-b-2"> <LiaEdit /></div>}
  {setShowAddButton && <div onClick={()=>{setShowAddButton((prev)=>!prev)}} className=" w-32 text-right text-sm cursor-pointer border-b-2"> Add New+</div>}
  {/* <OtherUserComponent
                      index={index}
                      setOtherUserSearchText={setOtherUserSearchText}
                      item={item}
                      handleAssignSave={handleAssignSave}
                      isLoadingAssigining={isLoadingAssigining}
                      setSelectedIndex={setSelectedIndex}
                      otherUsersearchText={otherUsersearchText}
                      selectedIndex={selectedIndex}
                      data={item?.otherUser}
                      handleAssignToggle={handleAssignToggle}
                    /> */}
    </div>
  {children}
</div>
 
  );
};

export default UserProfileWrapper;
