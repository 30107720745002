import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import SidebarHoverButton from "./SubComponent/sidebarHoverButton";
import SolidButtonSubComponent from "./SubComponent/solidButtonSubComponent";
import OutlinedButton from "./SubComponent/outlinedButton";
import NormalButton from "./SubComponent/normalButton";

const Button = ({
  children,
  hasIcon = false,
  onClick = () => {},
  isLoading = false,
  type = "primary",
  dropdownOption,
  hoveredText = "hovered text",
  buttonText = "button text",
  IconImage = IoIosArrowDown,
  Icon,
  styling = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const baseClasses =
    "flex items-center  justify-center  text-[14px] font-semibold leading-none px-5 h-10  rounded-[9px] w-full cursor-pointer shadow-custom";

  return (
    <>
      {type === "sidebarHover" ? (
        <SidebarHoverButton
          hoveredText={hoveredText}
          buttonText={buttonText}
          IconImage={IconImage}
          styling={styling}
        />
      ) : type === "solid" ? (
        <SolidButtonSubComponent
          hasIcon={hasIcon}
          isLoading={isLoading}
          buttonText={buttonText}
          Icon={Icon}
          clickFunc={onClick}
        />
      ) : type === "outlined" ? (
        <OutlinedButton
          hasIcon={hasIcon}
          isLoading={isLoading}
          buttonText={buttonText}
          Icon={Icon}
          clickFunc={onClick}
        />
      ) : type === "normal" ? (
        <NormalButton
          hasIcon={hasIcon}
          isLoading={isLoading}
          buttonText={buttonText}
          Icon={Icon}
          clickFunc={onClick}
        />
      ) : (
        <button
          type="submit"
          className={`relative ${baseClasses} ${type == "primary" || type === "dropdown" ? "bg-RGreen text-white" : type === "light" ? "bg-RLight text-black" : "bg-RDark text-white"}  `}
          onClick={onClick}
        >
          <div className="flex items-center  gap-2">
            {" "}
            {isLoading ? "Loading.." : children}{" "}
            {type === "dropdown" && (
              <IoIosArrowDown onClick={() => setIsOpen(!isOpen)} />
            )}
          </div>
          {isOpen && (
            <div className="absolute flex flex-col gap-2 rounded-md bg-white overflow-hidden -bottom-[80px] z-10">
              {dropdownOption &&
                dropdownOption?.map((item) => {
                  return (
                    <div className="p-2 text-sm text-black font-light bg-white hover:bg-gray-100 transition-all ease-in-out duration-150">
                      {item}
                    </div>
                  );
                })}
            </div>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
