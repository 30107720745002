import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { useIsIpad } from '../utils/isIpad';
import { useGetAllCategoryQuery, useGetScenarioDetailsQuery } from '../redux/slices/onBoarding';
import SelectableTabs from '../component/SelectableTabls';
import Scenario from './OnBoarding/Scenario';

const NotificationSetting = () => {
    const { data: categoryData, refetch: refetchCategoryData } =
    useGetAllCategoryQuery();
  const isIpad = useIsIpad();
  const [categoryId, setCategoryId] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryArray, setSubCategoryArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: senarioData,
    isLoading: senarioDataLoading,
    isError,
    error,
  } = useGetScenarioDetailsQuery(
    {
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    },
    { skip: !(categoryId && subCategoryId) }
  );

  useEffect(() => {
    setSubCategoryArray(
      categoryData?.data.flatMap((category) =>
        category?.categories?.flatMap((subCategory) =>
          subCategory?.subCategories
            .filter((sub) => !sub.completed)
            .map((sub) => sub.subCategoryId)
        )
      )
    );
  }, [categoryData]);

  useEffect(() => {
    if (
      categoryData?.data &&
      subCategoryArray &&
      !categoryId &&
      !subCategoryId
    ) {
      setCategoryId(categoryData?.data[0]?.categories[0]?.categoryId);
      setSubCategoryId(
        subCategoryArray[0]
          ? subCategoryArray[0]
          : categoryData?.data[0]?.categories[0]?.subCategories[0]?.subCategoryId
      );
    }
  }, [subCategoryArray]);
  return (
    <div className='w-full h-screen pt-[150px] mb-[100px] flex items-center justify-center' >
    <div
        className={`bg-white rounded-lg absolute flex justify-center ${isIpad ? "w-[1000px] h-[600px] " : "w-[80%]  h-[700px] "}`}
      >
        <div className="w-[30%]  border-r-2  h-full">
          <SelectableTabs
          isSetting={true}
            categoryData={categoryData}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            setCategoryId={setCategoryId}
            setSubCategoryId={setSubCategoryId}
          />
        </div>
        <div className="w-[70%]  border-r-2  h-full">
          <Scenario
          isSetting={true}
            setIsLoading={setIsLoading}
            subCategoryArray={subCategoryArray}
            setSubCategoryId={setSubCategoryId}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            senarioDetail={senarioData}
            refetchCategoryData={refetchCategoryData}
          />
        </div>
      </div>
      </div>
  )
}

export default NotificationSetting

