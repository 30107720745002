import React from 'react'
import UniversalTable from '../Table/UniversalTable'
import { useGetPropertiesOnRiskQuery } from '../../redux/slices/dashboard'
import Skeleton from '../Skeleton'

const PropertiesOnRisk = () => {
    const {data,isLoading,refetch}=useGetPropertiesOnRiskQuery()
  return (
    <div className='bg-lightThemeGradient rounded-lg dark:bg-darkThemeGradient  p-4'>
        <div className=''>

      <h1 className='text-2xl'>Properties on Risk</h1>
      <h2 className='text-sm'>{data?.data.length} risk properties</h2>
        </div>

       { isLoading?<div className='w-full h-[200px]'> <Skeleton/> </div>:<div>
            <UniversalTable dataArray={data?.data} refetch={refetch} />
        </div>}
    </div>
  )
}

export default PropertiesOnRisk

