import React from 'react';

const ProgressBar = ({ progress=50 }) => {
  return (
    <div className='relative w-full h-1 bg-black  rounded-md  border-gray-300'>
      <div className='absolute top-0 left-0 h-full bg-[#ABD2FF] rounded-md' style={{ width: `${progress}%` }}></div>
    </div>
  );
}

export default ProgressBar;
