import React from "react";

const SolidButtonSubComponent = ({
    hasIcon,
    isLoading,
    buttonText,
    Icon,
    clickFunc
}) => {
  return (
    <>
      <button className="" onClick={clickFunc}>
        <div className="flex flex-row bg-buttonBlue px-[10px] py-[5px] rounded-lg justify-center items-center space-x-3">
            <Icon className="text-white"/>
            <p className="text-[14px] text-white">{buttonText}</p>
        </div>
      </button>
    </>
  );
};

export default SolidButtonSubComponent;
