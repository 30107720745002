import React, { useState, useEffect, useRef } from "react";
import RevensCalling from "../../assets/icons/Revens/smallRevensCalling.svg";
import RevensSheid from "../../assets/icons/Revens/RevensShield.svg";
import RevensSearch from "../../assets/icons/Revens/RevensSearch.svg";
import RevensCoin from "../../assets/icons/Revens/RevensCoin.svg";
import BigRevensCalling from "../../assets/icons/Revens/RevensCalling.svg";

import RevensHey from "../../assets/icons/Revens/RevensHey.svg";
import womenTalking from "../../assets/icons/Revens/woman talking with chatbot.svg";

import Bubbles from "../../assets/icons/Circles/Bubble.svg";
import SemiBubble from "../../assets/icons/Circles/semiBubble.svg";
import UpSideDown from "../../assets/icons/Circles/UpSideDown.svg";
import FullCircle from "../../assets/icons/Circles/FullCircle.svg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const RevensScreen = ({ currentScreen = 1,finalData,nickName }) => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [revensMessage, setRevensMessage] = useState("");
  const [bgImage, setBgImage] = useState("");
  let logo = useRef(null);
  let semiBubble = useRef(null);
  let upsideDown = useRef(null);
  let headingAnimation = useRef(null);
  useGSAP(() => {
    const tl = gsap.timeline();

    // Set initial positions before animating
    tl.set(logo, { y: -32, x: -14 })
      .set(semiBubble, { y: 32, x: 20 })
      .set(upsideDown, { y: "50%", x: "-6px" });

    if (currentScreen === 1) {
      // Animation for screen 1
      tl.from(logo, { y: 100, duration: 1 })
        .from(semiBubble, { y: 64, x: 64, duration: 1 }, "<")
        .from(upsideDown, { y: 100, x: 150, duration: 1 }, "<")
        .to(logo, { y: 24, x: 112, duration: 1 })
        .to(semiBubble, { y: 160, x: -40, duration: 1 }, "<")
        .to(upsideDown, { y: -40, x: 50, duration: 1 }, "<")
        .to({}, { duration: 0.5 })
        .to(logo, { x: 0, y: 0, duration: 1 })
        .to(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .to(upsideDown, { y: 0, x: 0, duration: 1 }, "<")
        .to(headingAnimation, { y: "+=20", opacity: 0, duration: 0.5 }, "-=0.5")
        .set(headingAnimation, { opacity: 0, y: "" })
        .set(headingAnimation, { opacity: 1 })
        .call(() => {
          setHeading("Do you want me to address you by your nickname?");
          setSubHeading("");
          gsap.to(headingAnimation, { y: "-=20", opacity: 1, duration: 10 });
        });
    } else if (currentScreen === 2) {
      // Animation for screen 2
      tl.set(logo, { y: 0, x: 0 })
        .set(semiBubble, { y: 0, x: 60 }, "<")
        .set(upsideDown, { y: 0, x: 0 }, "<")
        .from(logo, { y: 0, x: 0, duration: 1 })
        .from(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .from(upsideDown, { y: 0, x: 0, duration: 1 }, "<")

        .to(logo, { y: 160, x: -60, duration: 1 })
        .to(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .to(upsideDown, { y: 144, x: 200, duration: 1 }, "<");
    } else if (currentScreen === 3) {
      // Animation for screen 3
      tl.from(logo, { y: 160, x: -60, duration: 1 })
        .from(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .from(upsideDown, { y: 144, x: 200, duration: 1 }, "<")
        .to(logo, { y: 24, x: 112, duration: 1 })
        .to(semiBubble, { y: 160, x: -40, duration: 1 }, "<")
        .to(upsideDown, { y: -40, x: 50, duration: 1 }, "<")
        .to({}, { duration: 0.5 })
        .to(logo, { x: 0, y: 0, duration: 1 })
        .to(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .to(upsideDown, { y: 0, x: 0, duration: 1 }, "<");
    } else if (currentScreen === 4) {
      // Animation for screen 4
      tl.set(logo, { y: 0, x: 0, duration: 1 })
        .set(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .set(upsideDown, { y: 0, x: 0, duration: 1 }, "<")
        .to(logo, { y: 160, x: -60, duration: 1 })
        .to(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .to(upsideDown, { y: 144, x: 200, duration: 1 }, "<");
    } else if (currentScreen === 5) {
      tl.from(logo, { y: 160, x: -60, duration: 1 })
        .from(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .from(upsideDown, { y: 144, x: 200, duration: 1 }, "<")
        .to(logo, { y: 24, x: 112, duration: 1 })
        .to(semiBubble, { y: 160, x: -40, duration: 1 }, "<")
        .to(upsideDown, { y: -40, x: 50, duration: 1 }, "<")
        .to({}, { duration: 0.5 })
        .to(logo, { x: 0, y: 0, duration: 1 })
        .to(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .to(upsideDown, { y: 0, x: 0, duration: 1 }, "<");
    } else if (currentScreen === 6) {
      // Animation for screen 4
      tl.set(logo, { y: 0, x: 0, duration: 1 })
        .set(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .set(upsideDown, { y: 0, x: 0, duration: 1 }, "<")
        .to(logo, { y: 160, x: -60, duration: 1 })
        .to(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .to(upsideDown, { y: 144, x: 200, duration: 1 }, "<");
    } else if (currentScreen === 7) {
      tl.from(logo, { y: 160, x: -60, duration: 1 })
        .from(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .from(upsideDown, { y: 144, x: 200, duration: 1 }, "<")
        .to(logo, { y: 24, x: 112, duration: 1 })
        .to(semiBubble, { y: 160, x: -40, duration: 1 }, "<")
        .to(upsideDown, { y: -40, x: 50, duration: 1 }, "<")
        .to({}, { duration: 0.5 })
        .to(logo, { x: 0, y: 0, duration: 1 })
        .to(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .to(upsideDown, { y: 0, x: 0, duration: 1 }, "<");
    } else if (currentScreen === 8) {
      // Animation for screen 4
      tl.set(logo, { y: 0, x: 0, duration: 1 })
        .set(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .set(upsideDown, { y: 0, x: 0, duration: 1 }, "<")
        .to(logo, { y: 160, x: -60, duration: 1 })
        .to(semiBubble, { y: -200, x: 20, duration: 1 }, "<")
        .to(upsideDown, { y: 144, x: 200, duration: 1 }, "<");
    }

    return () => {
      tl.kill();
    };
  }, [currentScreen]);

  useEffect(() => {
    switch (currentScreen) {
      case 1:
        setHeading(
          <>
            Welcome to <strong>R-World</strong>
          </>
        );
        setSubHeading("Your journey begins now.");
        setRevensMessage("I'm Revens your personal onboarding assistant");
        setBgImage(RevensHey);

        break;
      case 2:
        setHeading("Your Company");
        setSubHeading("Your Proud");
        setRevensMessage(
          <>
            <div>Awesome, {nickName ||'Boss'}! Let's get your agency set up</div>
            <div>bit better now {nickName || 'Boss'}!</div>
          </>
        );

        break;
      case 3:
        setHeading("Team Structure is important to setup your business");
        setSubHeading("");
        setRevensMessage(
          <>
            <div>Let's know your company a little</div>
            <div>What's the name of your proud?</div>
          </>
        );
        setBgImage(RevensSheid);
        break;
      case 4:
        setHeading(
          "Number of Hotels Managed is important to set up your Hotel Workspace"
        );
        setRevensMessage(
          `${nickName || 'Boss'} Please let me know how many hotels does your business currently manage?`
        );
        setBgImage(womenTalking);

        break;
      case 5:
        setHeading("Providing location gives you many benefits");
        setRevensMessage(
          <>
            <div>
              That's impressive! With a team managing over {finalData?.managedHotel || ''} hotels I bet
              your agency has a great location
            </div>{" "}
            <div>Where are you guys located?</div>
          </>
        );
        setBgImage(RevensSearch);

        break;
      case 6:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 7:
        setHeading("Finalising billing date, maintain your cash flow properly");
        setRevensMessage(
          "Now that we have your business information on file, let's talk about invoicing. When would you like your first invoice for R-world to be generated?"
        );
        setBgImage(RevensCoin);

        break;
      case 8:
        setHeading(
          `Alright,  ${nickName || 'Boss'}, let's make sure you stay in the loop at all times`
        );
        setRevensMessage(
          <>
            <div>
              Could you please share your WhatsApp number so I can keep you
              informed about your company?
            </div>
            <div>
              Keep your phone by your side, notifications are on the way!
            </div>
          </>
        );
        setBgImage(BigRevensCalling);

        break;
      case 9:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 10:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 11:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 12:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 13:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 14:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      case 15:
        setHeading(
          "You can select all the departments in which your business performs"
        );
        setRevensMessage(
          "Do you have any specific department or teams that will be using R-World the most?"
        );

        break;
      default:
        setHeading("");
    }
  }, [currentScreen]);

  return (
    <div
      style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "50%" }}
      className="relative w-[100%] bg-no-repeat bg-center bg-cover h-[100%] bg-RGreen rounded-lg p-4 overflow-hidden text-white flex flex-col items-center justify-center"
    >
      <div>
        <div
          ref={(el) => {
            logo = el;
          }}
          id="box"
          className="absolute -left-14 -top-32"
        >
          <img src={FullCircle} className="w-[100%] h-[100%]" />
        </div>
        <div
          ref={(el) => {
            semiBubble = el;
          }}
          className="absolute -right-20 top-32"
        >
          <img src={FullCircle} className="w-[100%] h-[100%]" />
        </div>
        <div
          ref={(el) => {
            upsideDown = el;
          }}
          className="absolute h-12 w-12 top-1/2 -left-6"
        >
          <img src={FullCircle} className="w-[100%] h-[100%]" />
        </div>
        <div className="flex mx-10 py-10 md:gap-6 flex-col h-[100%] w-[100%] md:w-1/3">
          <div className="flex flex-col text-white"></div>
        </div>
      </div>
      <div className="absolute inset-0 bg-R-Green opacity-50 rounded-lg "></div>
      <div
        ref={(el) => {
          headingAnimation = el;
        }}
        className="relative z-10 flex flex-col items-center md:gap-4 pb-28 px-16"
      >
        <h1 className="text-lg md:text-4xl font-semibold text-center">
          {heading}
        </h1>
        <h2 className="text-lg md:text-4xl font-semibold">{subHeading}</h2>
      </div>

      <div className="px-10 w-auto max-w-[80%] md:w-auto h-auto md:h-auto rounded-lg flex absolute bottom-8 justify-start items-center bg-[#748CAB]/15 gap-2">
        <div className="w-[50px] z-20">
          <img src={RevensCalling} className="object-cover z-20" alt="" />
        </div>
        <div className="revens w-[100%] h-auto py-4">
          <h1>Revens</h1>
          <h1>{revensMessage}</h1>
        </div>
      </div>
    </div>
  );
};

export default RevensScreen;
