import React from 'react'
import WelcomeScreenTable from '../../component/WelcomeScreen/WelcomeScreenTable';

const WelcomeScreen = () => {
    let taskList = [
      {
        taskText: "Task Name",
        completionStatus: true,
      },
      {
        taskText: "Task Name",
        completionStatus: true,
      },
      {
        taskText: "Task Name",
        completionStatus: true,
      },
      {
        taskText: "Task Name",
        completionStatus: true,
      },
    ];
    return (
      <>
        <div className="h-[100%] w-[100%] flex flex-col items-center justify-center">
          <div className=" w-[100%] flex flex-col items-center text-white dark:text-black mt-[20px]">
            <h1 className="text-[30px] font-[700]">
              Congrats on automating your agency{" "}
            </h1>
            <p className="text-[15px] font-[400]">
              Complete these simple steps to keep your hotel up and running{" "}
            </p>
          </div>
          <WelcomeScreenTable taskList={taskList} />
        </div>
      </>
    );
}
 
export default WelcomeScreen;