import React from "react";
import DashboardCard from "./SubComponent/DashboardCard";

const DashboardCardList = ({ list = [] }) => {
  return (
    <div className="flex flex-row justify-between w-full my-[20px]">
      {list &&
        list?.length > 0 &&
        list.map((listItem, id) => {
          return (
            <DashboardCard
              Icon={listItem.Icon}
              cardName={listItem.cardName}
              currentValue={listItem.currentValue}
              lastValue={listItem.lastValue}
            />
          );
        })}
    </div>
  );
};

export default DashboardCardList;
