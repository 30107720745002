import React, { useState } from "react";
import RWorldBlack from "../../assets/icons/RWorldBlack.png";
import Button from "../button";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useSignupMutation,
} from "../../redux/slices/onBoarding";
import RownLogo from "../../assets/icons/R-OwnLogo.svg";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import RetvensDarkLogo from "../../assets/logo/rWorldDarkLogo.svg";
import RetvensLightLogo from "../../assets/logo/rWorldLightLogo.svg";

const SignUp = ({ setRecentLogin }) => {
  const methods = useForm();
  const [signUp] = useSignupMutation();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      accountType: "signUp",
    };
    signUp(updatedData)
      .unwrap()
      .then((res) => {
        Cookies.set("token", res?.data?.token, { expires: 7 });

      
        toast.success(res?.message, { position: "bottom-right" });
        navigate("/onBoarding");
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "dark")
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {/* <div
          className="flex bg-custom-image bg-no-repeat  bg-right-bottom   flex-col bg-slate-100 h-screen w-full justify-center"
          style={{ backgroundPosition: "right 0 bottom -60px" }}
        > */}
        <div className="h-full w-full px-4">
          <div className="flex flex-col items-center justify-center">
            <div className=" bg-lightThemeGradient dark:bg-darkThemeGradient  shadow rounded-lg  w-full p-10 mt-16">
              <div className="flex justify-between items-start gap-3 ">
                <div className=" flex flex-col  ">
                  <div className="flex items-center gap-2  ">
                    <p className="text-base md:text-xl font-semibold">
                      {" "}
                      Welcome to
                    </p>
                    <span className="flex w-16 h-10 items-center">
                      {theme === "light" ? (
                        <img
                          className=""
                          width={100}
                          src={RetvensDarkLogo}
                          alt="Logo"
                        />
                      ) : (
                        <img
                          className=""
                          width={100}
                          src={RetvensLightLogo}
                          alt="Logo"
                        />
                      )}
                    </span>
                  </div>
                  <div className="text-base md:text-4xl font-medium ">
                    Sign Up
                  </div>
                </div>

                <div className="flex flex-col items-start pt-2 pl-[10px]">
                  <h2 className="text-[10px] font-semibold ">Already have an Account?</h2>
                  <div className=" flex items-start justify-center p-1 "> 
                  <Link
                    className="no-underline text-black outline-none  bg-white rounded-md px-2 py-1"
                    to={"/login"}
                  >
                    <div className="text-black text-[14px] items-end  text-bold"> Log in</div>
                  </Link>
                  </div>
                </div>
              </div>
              {/* <div className="my-10 w-64">
                <Button type="primary">
                  <img src={RownLogo} height={19} width={19} alt="" />
                  <p className="text-base font-medium ml-4 ">
                    Sign In with R-OWN
                  </p>
                </Button>
              </div> */}
              {/* <div className="w-full flex items-center justify-between py-5">
                  <hr className="w-full bg-white" />
                  <p className="text-base font-medium leading-4 px-2.5 text-gray-400">
                    OR
                  </p>
                  <hr className="w-full bg-white" />
                </div> */}
              <div className="mt-8 flex flex-col items-between">
                <ReusableInput
                  label="Enter your Email Address"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  rules={{ required: "Email is required" }}
                  showIcon={true}
                />
                <ReusableInput
                  label="Enter Your Password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  rules={{ required: "Password is required" }}
                  showIcon={true}
                />
              </div>

              <div className="mt-8">
                <Button>Sign up</Button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </form>
    </FormProvider>
  );
};

export default SignUp;
