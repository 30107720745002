import React, { useEffect, useState } from "react";

import Button from "../../component/button";
import ReusableInput from "../../component/InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import {
  useAddEmployeeMutation,
  useGetDepartmentQuery,
  useGetUserDetailQuery,
} from "../../redux/slices/Setting";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useGetDesignationQuery } from "../../redux/slices/dashboard";
import UniversalLoader from "../../component/Loader/UniversalLoader";

const AddEmployee = ({ setShowAddEmployee, editEmployee = false, data }) => {
  const methods = useForm();
  const { userId } = useParams();
  const [propertyType, setPropertyType] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [departmentOption, setDepartmentOptions] = useState([]);
  const { data: departmentData, isLoading: departmentIsLoading } =
    useGetDepartmentQuery();
  const [designation, setDesignation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: designationOption,
    isLoading: designationLoading,
    isFetching,
  } = useGetDesignationQuery({
    departmentId: selectedDepartment,
  });

  useEffect(() => {
    if (departmentIsLoading || designationLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [departmentIsLoading, designationLoading]);
  const [addEmployee] = useAddEmployeeMutation();
  useEffect(() => {
    setDepartmentOptions(departmentData?.data || []);
  }, [departmentData?.data]);
  useEffect(() => {
    if (data?.data) {
      methods.setValue("employeeName", data?.data?.fullName);
      methods.setValue("whatsAppNumber", data?.data?.phoneNumber);
      methods.setValue("employeeEmail", data?.data?.email);
    }
  }, [data?.data]);

  const onSubmit = (data) => {
    setIsLoading(true);
    addEmployee({
      employeeData: {
        name: data?.employeeName,
        department: selectedDepartment,
        designation: designation,
        number: data?.whatsAppNumber,
        email: data?.employeeEmail,
        password: data?.employeePassword,
      },
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.message || "Employee added sucessfully", {
          position: "bottom-right",
        });
        setShowAddEmployee(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.data?.message || "some Error occurred", {
          position: "bottom-right",
        });
      });
  };
  return (
    <div className="w-[100%]  p-4 min-h-[100%]">
      <UniversalLoader loading={isLoading} />
      <FormProvider {...methods}>
        <form
          className="flex flex-col w-full justify-start items-between"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="w-[62%] w-full flex justify-between items-center ">
            <div className="flex gap-4 items-center">
              <div className="">
                <div
                  onClick={() => {
                    setShowAddEmployee(false);
                  }}
                  className={`relative cursor-pointer bg-RGreen text-whitebg-RDark text-white p-2 rounded-lg px-4 `}
                >
                  <FaArrowLeft />
                </div>
              </div>
              {editEmployee ? (
                <div className="">
                  <h1 className="text-2xl font-bold">Edit Employee</h1>

                  <p className="">
                    Let's edit employee of your's and assign to your team
                  </p>
                </div>
              ) : (
                <div className="">
                  <h1 className="text-2xl font-bold">Add New Employee</h1>

                  <p className="">
                    Let's add new employee of your's and assign to your team
                  </p>
                </div>
              )}
            </div>
            <div>
              <Button>Save Employee</Button>
            </div>
          </div>
          <div className="w-[25%] mt-6  flex flex-col justify-start ">
            <ReusableInput
              label="Employee Name"
              name="employeeName"
              type="text"
              placeholder="Enter Employee Name"
              rules={{ required: "Employee Name is required" }}
              showIcon={false}
            />

            <div className="my-2">
              <h1 className="text-lg font-medium text-[#343434]">
                Department{" "}
              </h1>
              <div className="h-12">
                <NormalDropDown
                  options={departmentOption}
                  label="Select an option"
                  selectedValue={selectedDepartment}
                  setSelectedValue={setSelectedDepartment}
                  // isMulti={true}
                  sendOnlyId={true}
                />
              </div>
            </div>
            <div className="my-2">
              <h1 className="text-lg font-medium text-[#343434]">
                Designation
              </h1>
              <div className="h-12">
                <NormalDropDown
                  options={designationOption?.data}
                  label="Select an option"
                  selectedValue={designation}
                  setSelectedValue={setDesignation}
                  sendOnlyId={true}
                />
              </div>
            </div>
            <ReusableInput
              label="What's App Number"
              name="whatsAppNumber"
              type="phoneNumber"
              placeholder="Enter Property Owner number"
              rules={{ required: "Property Owner number is required" }}
              showIcon={true}
            />
            <ReusableInput
              label="Work Email"
              name="employeeEmail"
              type="email"
              placeholder="Enter Employee Email address"
              rules={{ required: "Employee Email is required" }}
              showIcon={true}
            />
            {!editEmployee && (
              <ReusableInput
                label="Password"
                name="employeePassword"
                type="password"
                placeholder="Enter Employee password"
                rules={{ required: "Employee Password is required" }}
                showIcon={true}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEmployee;
