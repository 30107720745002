export function calculatePercentageDifference(oldValue, newValue) {
  // Validate input values
  if (
    oldValue === null ||
    oldValue === undefined ||
    newValue === null ||
    newValue === undefined
  ) {
    return "-";
  }

  // Calculate percentage difference
  const difference = Math.abs(oldValue - newValue);
  const average = (oldValue + newValue) / 2;
  const percentageDifference = (difference / average) * 100;

  // Determine if it's positive or negative based on value1 and value2
  const isPositive = oldValue > newValue;

  // Format the result to two decimal places and return an object
  return {
    percentage: percentageDifference.toFixed(2),
    isPositive,
  };
}
