import React, { useState } from 'react'
import { useIsIpad } from '../../utils/isIpad';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

const OTADetail = ({ota,objIndex,index,handleChange}) => {
    const [viewPassword,setViewPassword]=useState(false);
    const isIpad =useIsIpad();
    const [otaPhoneNumber,setOtaPhoneNumber]=useState(ota?.phoneNo);
    const [otaEmail,setOtaEmail]=useState(ota?.email)
    const [otaUserId,setOtaUserId]=useState(ota?.userId);
    const [otaPassword,setOtaPassword]=useState(ota?.password);
  return (
    <div
    className={`grid  ${isIpad ? "grid-cols-3" : "xs:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} border-b py-4 gap-4`}
  >
    <div className="mb-4">
      <div className="text-sm">OTA Name</div>
      <div className="text-sm mt-1 flex gap-2">
        <img
          className="rounded-lg"
          src={ota?.otaImage}
          width={30}
        />
        <input
          value={ota?.otaName}
          onChange={(e) =>
            handleChange(
              "otaDetails.otaName",
              e.target.value,
              objIndex,
              index
            )
          }
          className="px-2 h-8 text-sm text-black xs:w-[100%] rounded-md focus:outline-none disabled:bg-gray-200"
          placeholder="Enter OTA Name"
          disabled={true}
          
        />
      </div>
    </div>
    <div className="mb-4">
      <div className="text-sm">Registered Mob No.</div>
      <div className="text-sm mt-1">
        <input
          value={otaPhoneNumber}
          type="text"
          pattern="\d*"
          onChange={(e) =>{
            handleChange(
              "otaDetails.phoneNo",
              e.target.value.replace(/[^0-9]/g, ''),
              objIndex,
              index
            )
            setOtaPhoneNumber(e.target.value)}
          }
          className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
          placeholder="Enter Registered Mob No."
        />
      </div>
    </div>
    <div className="mb-4">
      <div className="text-sm">Registered Email</div>
      <div className="text-sm mt-1">
        <input
          value={otaEmail}
          type="email"
          onChange={(e) =>{
            handleChange(
              "otaDetails.email",
              e.target.value,
              objIndex,
              index
            )
            setOtaEmail(e.target.value)
        
        }
          }
          className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
          placeholder="Enter Registered Mob No."
        />
      </div>
    </div>
    <div className="mb-4">
      <div className="text-sm">OTA User Id</div>
      <div className="text-sm mt-1">
        <input
          value={otaUserId}
          type="text"
          onChange={(e) =>{
            handleChange(
              "otaDetails.otaUserId",
              e.target.value,
              objIndex,
              index
            )
        setOtaUserId(e.target.value)}
          }
          className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
          placeholder="Enter OTA User Id"
        />
      </div>
    </div>
    <div className="mb-4">
      <div className="text-sm">OTA Password</div>
      <div className="text-sm mt-1 w-fit h-fit relative">
        <input
          value={otaPassword}
          type={`${viewPassword?'text':'password'}`}
          onChange={(e) =>{
            handleChange(
              "otaDetails.password",
              e.target.value,
              objIndex,
              index
            )
        setOtaPassword(e.target.value)
        }
          }
          className="px-2  xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
          placeholder="Enter OTA Password"
        />
        <div onClick={()=>setViewPassword((prev)=>!prev)} className={`absolute right-2 cursor-pointer text-black top-2`}>     {viewPassword? <FaEyeSlash size={15} /> : <FaEye size={15} /> }</div>
      </div>
    </div>
  </div>
  )
}

export default OTADetail

