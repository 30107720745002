import React from "react";
import EmployeeTable from "./subComponent/employeeTable";

const CustomTable = ({
  type = "",
  header = [],
  body = [],
  heading = "",
  containSubheading = false,
  subHeading = "",
  hasFilter = false,
  hasSort = false,
  hasDownload = false,
  hasPagination = false,
  paginationFunc = () => {},
}) => {
  return (
    <>
      {type === "employee" ? (
        <>
          <EmployeeTable
            header={header}
            body={body}
            heading={heading}
            containSubheading={false}
            subHeading={subHeading}
            hasFilter={hasFilter}
            hasSort={hasSort}
            hasDownload={hasDownload}
            hasPagination={hasPagination}
            paginationFunc={paginationFunc}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomTable;
