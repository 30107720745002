import React from "react";
import ReusableInput from "../InputField/ReusableInput";

const ConnectWhatsapp = () => {
  return (
    <div className="w-[100%] text-lightThemeFontColor dark:text-darkThemeFontColor h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">Connect with us on WhatsApp</h1>
        <p className="text-[#96A0AD] my-2">
          To keep you informed about your company's progress with
          R-World including upcoming training sessions and helpful tips,  can we
          get your preferred phone number? We'll also be sending out important
          updates and announcements there
        </p>
      </div>
      <div>
        <p className="my-2 f">
          When would you prefer your first invoice to be generated?
        </p>
        <ReusableInput
          label="Enter your number registered with Whatsapp"
          name="phoneNumber"
          type="phoneNumber" // Keep type as phoneNumber for consistency
          placeholder="Phone Number"
          className='  placeholder:text-darkThemeFontColor dark:placeholder:text-lightThemeFontColor   text-lightThemeFontColor dark:text-darkThemeFontColor '
          lableClass=' text-lightThemeFontColor dark:text-darkThemeFontColor text-lightThemeFontColor dark:text-darkThemeFontColor  '
          rules={{
            required: "Phone number is required",
            pattern: {
              value: /^\d{10}$/,
              message: "Phone number must be exactly 10 digits"
            }
          }}
          showIcon={true}
        />
      </div>
    </div>
  );
};

export default ConnectWhatsapp;
