import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import ReusableInput from "../InputField/ReusableInput";
import NormalDropDown from "../DropDown/NormalDropDown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./customDateTimePicker.css"; // Custom CSS for dark mode styles
import { useAddReminderMutation } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

const UniversalModel = ({ children,setShowModel,showSaveButton=false,handleSave=()=>{},fixedWidth=false }) => {
 

  // Initialize form methods
  const methods = useForm({
    defaultValues: {
      fullName: "", // Default value for reminder name
    },
  });



  return (
    <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div className= {`bg-lightThemeGradient dark:bg-popupGradient  ${fixedWidth? 'w-fit p-4':'w-full max-w-[1260px]'} text-darkThemeFontColor rounded-lg shadow-md`}>
        <div className="relative w-full flex justify-center py-4">
          <div
            onClick={() => setShowModel(false)}
            className="absolute top-4 left-4 cursor-pointer text-white text-2xl"
          >
            <IoClose />
          </div>

          {/* Save Button */}
        {showSaveButton &&  <button
            type="submit"
            className="absolute top-4 right-4 cursor-pointer text-white bg-blue-700 rounded-lg p-2"
            onClick={methods.handleSubmit(handleSave)} // Ensure the button submits the form
          >
            Save
          </button>}

    {children}
        </div>
      </div>
    </div>
  );
};

export default UniversalModel;
