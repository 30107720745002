import React from "react";
import Cookies from "js-cookie";

const RecentLoginCard = ({
  email,
  index,
  name,
  setRecentLogin,
  recentLogin,
}) => {
  const removeRecentItems = () => {
    setRecentLogin(recentLogin.filter((item) => item.email != email));
    Cookies.set(
      "recentLogin",
      JSON.stringify(recentLogin.filter((item) => item.email != email)),
      { expires: 7 }
    );
  };
  return (
    <div
      key={index}
      className=" color-[#FOEBD8] relative h-[180px] w-[150px] cursor-pointer overflow-hidden  bg-[#748CAB] rounded-lg shadow-md "
    >
      <div
        onClick={removeRecentItems}
        type="button"
        className=" cursor-pointer absolute top-1 right-1  text-gray-600 hover:text-gray-800"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
      <div className="p-4">
        <div className="relative ">
          <img
            className="w-24 h-24 rounded-full mx-auto"
            src="https://via.placeholder.com/150"
            alt="Profile Picture"
          />
        </div>
        <div className="text-center mt-4">
          <h2 className="text-sm font-semibold  text-[#FOEBD8]">{name}</h2>
          <p className="text-xs overflow-hidden  w-full text-[#FOEBD8]">
            {email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecentLoginCard;
