import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import ReusableInput from "../InputField/ReusableInput";
import NormalDropDown from "../DropDown/NormalDropDown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./customDateTimePicker.css"; // Custom CSS for dark mode styles
import { useAddReminderMutation } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import Toggle from '../CheckBox/Toggle';

const AddReminder = ({ setShowReminder,refetch }) => {
  const [recurrence, setRecurrence] = useState();
  const [selectedPriority, setSelectedPriority] = useState();
  const [recurrenceTimeRange, setRecurrenceTimeRange] = useState();
  const [value, onChange] = useState(new Date());
  const [addReminder] = useAddReminderMutation();

  // Initialize form methods
  const methods = useForm({
    defaultValues: {
      fullName: "", // Default value for reminder name
    },
  });

  // Form submission handler
  const handleSave = (data) => {
    addReminder({
      title: data?.fullName,
      time: value,
      reccurance: recurrence,
      priority: selectedPriority,
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        setShowReminder(false);
        refetch()
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  return (
    <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
  <div className="bg-lightThemeGradient  p-4 dark:bg-popupGradient w-full max-w-[1260px] text-darkThemeFontColor rounded-lg shadow-md">
    <div className="w-full flex justify-between items-center py-4 px-6">
      {/* Left-aligned Heading */}
      <div className="text-xl">Add Reminder</div>
      
      {/* Right-aligned Buttons */}
      <div className="flex items-center space-x-4">
        <div
          onClick={() => setShowReminder(false)}
          className="cursor-pointer bg-blue-700 rounded-lg text-white px-4 py-2"
        >
          Close
        </div>
        {/* Save Button */}
        <button
          type="submit"
          className="cursor-pointer text-white bg-blue-700 rounded-lg px-4 py-2"
          onClick={methods.handleSubmit(handleSave)} // Ensure the button submits the form
        >
          Save
        </button>
      </div>
    </div>

    <div className="col-span-1 h-auto px-6 pb-6">
      <FormProvider {...methods}>
        <form
          className="grid grid-cols-2 gap-4 w-full"
          onSubmit={methods.handleSubmit(handleSave)} // Link form submission to handleSave
        >
          {/* Reminder Name Input */}
          <div className="col-span-1 h-14">
            <ReusableInput
              label="Reminder Name"
              name="fullName"
              type="text"
              placeholder="Enter the Reminder Name"
              rules={{ required: "Reminder Name is required" }} // Form validation rule
              showIcon={true}
            />
          </div>

          {/* DateTime Picker */}
          <div className="col-span-1">
            <p>Date & Time</p>
            <div className="h-12 border flex items-center rounded-lg border-white">
              <DateTimePicker
                onChange={onChange}
                value={value}
                format="y-MM-dd h:mm a"
                className="w-full relative rounded-lg text-white p-2 dark:text-white"
              />
            </div>
          </div>

          {/* Recurrence Dropdown */}
          <div className="col-span-1 h-18 flex items-center justify-between">
            <p>Task Recurrence</p>
            <div className="w-fit h-14">
              <Toggle
                onClick={() => setRecurrence((prev) => !prev)}
                isToggled={recurrence}
              />
            </div>
          </div>

          {/* Recurrence Time Range */}
          {recurrence && (
            <div className="col-span-1">
              <p>Recurrence Time Range</p>
              <div className="h-14">
                <NormalDropDown
                  selectedValue={recurrenceTimeRange}
                  setSelectedValue={setRecurrenceTimeRange}
                  options={["Daily", "Weekly", "Quarterly"]}
                  label="Select value"
                />
              </div>
            </div>
          )}

          {/* Priority Dropdown */}
          <div className="col-span-1 h-14">
            <p>Priority</p>
            <div className="w-full h-14">
              <NormalDropDown
                selectedValue={selectedPriority}
                setSelectedValue={setSelectedPriority}
                options={["High", "Low"]}
                label="Select value"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  </div>
</div>

  
  );
};

export default AddReminder;
