import React from "react";
import Button from "../../button";

// icons
import { PiFunnelSimple } from "react-icons/pi";
import { TbRuler2Off } from "react-icons/tb";
import { IoFunnelOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import { BiSort } from "react-icons/bi";
import { FaUserTie } from "react-icons/fa6";

const EmployeeTable = ({
  header = [],
  body = [],
  heading = "",
  containSubheading = false,
  subHeading = "",
  hasFilter = false,
  hasSort = false,
  hasDownload = false,
  hasPagination = false,
  paginationFunc = () => {},
}) => {
  return (
    <div className="flex flex-col w-[100%] h-[50%] bg-newGradient dark:bg-lightGradient px-[10px] py-[20px] rounded-md">
      {/* header */}
      <div className="flex flex-row justify-between">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-[20px] font-[600]">{heading}</h1>
          {containSubheading && (
            <p className="text-[14px] font-[300]">{subHeading}</p>
          )}
        </div>
        <div className="flex flex-row items-end justify-end">
          <Button
            type="normal"
            hasIcon={TbRuler2Off}
            isLoading={false}
            buttonText={"Filter"}
            Icon={IoFunnelOutline}
            onClick={() => {}}
          />
          <Button
            type="normal"
            hasIcon={TbRuler2Off}
            isLoading={false}
            buttonText={"Sort"}
            Icon={BiSort}
            onClick={() => {}}
          />
          <Button
            type="normal"
            hasIcon={TbRuler2Off}
            isLoading={false}
            buttonText={"Download"}
            Icon={GoDownload}
            onClick={() => {}}
          />
        </div>
      </div>

      {/* body */}
      <div className="flex flex-col w-full my-3">
        {/* table header */}
        <div className="flex flex-row w-[100%] justify-between border-b-[1px] border-gray-400 pb-1 my-3">
          {header &&
            header.map((headerItem, id) => {
              return (
                <p
                  className={`w-[${100 / header?.length}%] text-[10px] font-[700]`}
                >
                  {headerItem}
                </p>
              );
            })}
        </div>

        {/* {
      employeeName: "Saumitra Shukla",
      employeeEmail: "saumitra@shuka.com",
      employeeId: "emp=965",
      role: "Revenue Exec",
      status: "Inactive",
      lastSeen: new Date("2024-05-23"),
    }, */}
        {/* table body */}
        <div className="flex flex-col">
          {body &&
            body.map((bodyItem, id) => {
              return (
                <div
                  className={`flex flex-row w-[100%] justify-between border-b-[1px] border-gray-400 pb-1 my-3`}
                >
                  <div className={`flex flex-row w-[${100 / header?.length}%] items-center space-x-3 `}>
                    {
                        bodyItem?.profilePicture && bodyItem?.profilePicture !=""
                        ?
                        <img
                          src={bodyItem?.profilePicture}
                          alt="profile"
                          className="h-[30px] w-[30px] rounded-full max-w-full"
                        />
                        :
                        <div className="bg-white flex justify-center items-center h-[30px] w-[30px] rounded-full">
                        <FaUserTie className="text-black h-[20px] w-[20px]"/>
                        </div>
                    }
                    <div className="flex flex-col items-start">
                      <p className="text-[15px] truncate">{bodyItem.employeeName}</p>
                      <p className="text-[12px] truncate">{bodyItem.employeeEmail}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* footer */}
      <div></div>
    </div>
  );
};

export default EmployeeTable;
