import React, { useState } from "react";
import Skeleton from "../Skeleton";
import Button from "../button";
import UniversalTable from "../Table/UniversalTable";
import {
  useGetEmployeeOverviewQuery,
  useGetLowPerformanceClientQuery,
} from "../../redux/slices/dashboard";
import dataNotFound from "../../assets/icons/404/1.svg";
import { useNavigate } from "react-router-dom";
import AddEmployee from "../../pages/employees/AddEmployeeNew";

const EmployeeOverview = ({showPagination=true}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data: performanceData, isLoading,isFetching } = useGetEmployeeOverviewQuery({pageNumber:pageNumber});
const [showAddEmployee,setShowAddEmployee]=useState();

  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };


  const totalPages = performanceData?.data?.totalPages;
  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
const navigate = useNavigate()
  console.log(performanceData?.data?.employeeOverview)
  return (
    <>
      {!(isLoading|| isFetching) ? (
            showAddEmployee ?   <div className="h-full p-2"><AddEmployee setShowAddClient={setShowAddEmployee} /> </div>: <div className="w-[100%] pb-12 relative rounded-lg overflow-hidden p-4 bg-lightThemeGradient z-10  dark:bg-darkThemeGradient h-[100%]">
          <div className="h-full p-2">
          <div className="w-full flex justify-between items-center">
              <h1 className="text-2xl">Employee</h1>
              <h1  onClick={()=>setShowAddEmployee(true)} className="p-2 cursor-pointer bg-white  rounded-lg text-black text-sm">Add Employee</h1>
            </div>
            <div className="h-[90%] my-2 pb-2 ">
              {performanceData?.data ? (
                <>
                  <UniversalTable
                    isLoading={isLoading}
                    dataArray={performanceData?.data?.employeeOverview || performanceData?.data}
                    showActions={"employeeOverview"}
                    isEmp={true}
                  />
             {(showPagination && totalPages>1) &&     
      <div className="absolute bottom-4 w-full flex items-center justify-center mt-8">
        <div className="flex justify-center items-center space-x-4 ">
          <button
            onClick={handlePrevious}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === 1
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === 1}
          >
            Previous
          </button>

          <span className="text-lg text-white dark:text-black font-medium">
            Page {pageNumber} of {totalPages}
          </span>

          <button
            onClick={handleNext}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === totalPages
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === totalPages}
          >
            Next
          </button>
        </div>
      </div>
                  
                  
                  }
                </>
              ) : (
                <div className="w-[99%] flex bg-[#7c8ba130]  items-center justify-start  h-auto p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
                  <img src={dataNotFound} className="h-60 w-60" alt="" />
                  <div className="w-[80%] flex justify-center">
                    <p className="text-xl font-medium">No recent Activities</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[400px]">
        <Skeleton />
        </div>
      )}
    </>
  );
};

export default EmployeeOverview;
