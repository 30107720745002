import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ReusableInput from "../component/InputField/ReusableInput";
import ChangePassword from "./profilePage/changePassword";
import UserPI from "./profilePage/UserPI";
import {
  useGetUserProfileInformationQuery,
  useEditUserProfileMutation,
} from "../redux/slices/onBoarding";
import { toast } from "react-toastify";
import UserProfilePicture from "./profilePage/UserProfilePicture";
import NormalDropDown from "../component/DropDown/NormalDropDown";
import DateTimePicker from "react-datetime-picker";
import UserProfileWrapper from "../component/WrapperComponents/UserProfileWrapper";
import { MdOutlineViewAgenda } from "react-icons/md";
const ProfilePage = () => {
  const methods = useForm();

  const {
    data: userProfileData,
    error,
    isLoading,
    refetch:userProfileRefetch,
  } = useGetUserProfileInformationQuery();
  const [editUserProfile] = useEditUserProfileMutation();
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [editPersonalProfile, setEditPersonalProfile] = useState();
  const [editUserDetails, setEditUserDetails] = useState(false);
  const [editEducation, setEditEducation] = useState(false);
  const [showEditPassword,setShowEditPassword]=useState(false)
  const [education,setEducation]=useState([]);
  useEffect(() => {
    if (userProfileData) {
      methods.reset({
        designation: userProfileData?.data?.designationName || "",
        department: userProfileData?.data?.departmentName || "",
        mobileNumber: userProfileData?.data?.phoneNumber || "",
        email: userProfileData?.data?.email || "",
        fullName: userProfileData?.data?.fullName || "",
        nickName: userProfileData?.data?.nickName || "",
        maritalStatus: userProfileData?.data?.maritialStatus || "",
        nationality: userProfileData?.data?.nationality || "",
        personalAddress: userProfileData?.data?.personalAddress || "",
      });
      userProfileData?.data?.education?.map((item) => {
        if (item.educationType === "underGraduation") {
          methods.setValue("underCourseName", item?.courseName);
          methods.setValue("underInstitudeName", item?.institute);
          methods.setValue("underStartingYear", item?.startYear);
          methods.setValue("underEndYear", item?.endYear);
        } else if (item?.educationType === "postGraduation") {
          methods.setValue("postCourseName", item?.courseName);
          methods.setValue("postInstitudeName", item?.institute);
          methods.setValue("postStartingYear", item?.startYear);
          methods.setValue("postEndYear", item?.endYear);
        }else{
            methods.setValue("highCourseName", item?.courseName);
          methods.setValue("highInstitudeName", item?.institute);
          methods.setValue("highStartingYear", item?.startYear);
          methods.setValue("highEndYear", item?.endYear);
        }
      });
      setSelectedLanguage(userProfileData?.data?.language);
      //   setSelectedLanguage((prev)=>[...prev,])
    }
  }, [userProfileData, error, methods]);

  const onSubmit = (data) => {
    const editableData = {
      phoneNumber: data.mobileNumber,
      fullName: data?.fullName,
      nickName: data?.nickName,
    };

    editUserProfile(editableData)
      .unwrap()
      .then((res) => {
        setEditUserDetails(false);
        userProfileRefetch();
        toast.success(res?.message, { position: "bottom-right" });
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  const editPersonalInfo = (data) => {
    const editableData = {
      language: selectedLanguage,
      maritialStatus: data?.maritalStatus,
      joiningDate: joiningDate,
      DOB: dateOfBirth,
      nationality: data?.nationality,
      personalAddress: data?.personalAddress,
    };

    editUserProfile(editableData)
      .unwrap()
      .then((res) => {
        setEditEducation(false);
        userProfileRefetch();
        toast.success(res?.message, { position: "bottom-right" });
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
const handleEditEducation=(data)=>{
   const edu=[
    {
      educationType: "underGraduation",
      courseName: data?.underCourseName,
      institute: data.underInstitudeName,
      startYear: data.underStartingYear,
      endYear: data.underEndYear,
    },{
        educationType: "postGraduation",
        courseName: data?.postCourseName,
        institute: data.postInstitudeName,
        startYear: data.postStartingYear,
        endYear: data.postEndYear, 
    },{
        educationType: "10+2",
        courseName: data?.highCourseName,
        institute: data.highInstitudeName,
        startYear: data.highStartingYear,
        endYear: data.highEndYear, 
    }]
    editUserProfile({'education':edu})
    .unwrap()
    .then((res) => {
        setEditEducation(false);
        userProfileRefetch();
      toast.success(res?.message, { position: "bottom-right" });
    })
    .catch((err) => {
      toast.error(err?.data?.message, { position: "bottom-right" });
    });
}


  const clientData = "";
  return (
    <>
      <div className="flex w-[80%] mr-6 mt-16">
        <div className="w-[50%] p-8 ">
          <UserProfilePicture />

          {editUserDetails ? (
            <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg shadow-lg p-3 mt-10">
              <div className="flex justify-between items-center border-b-2 mb-2 w-full">
                <h1 className=" font-bold text-white ">
                  User Detail
                </h1>
                <h1
                  onClick={() => {
                    setEditUserDetails(false);
                  }}
                >
                  <MdOutlineViewAgenda />
                </h1>
              </div>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="grid grid-cols-1 md:grid-cols-2"
                >
                  <div className="mr-4">
                    <ReusableInput
                      label="Designation"
                      name="designation"
                      type="text"
                      placeholder="Enter your designation"
                      disabled={true}
                      rules={{ required: "Designation is required" }}
                      className="placeholder:text-black dark:text-white dark:placeholder:text-white text-black"
                      labelClass="text-black dark:text-white"
                    />
                  </div>

                  <div>
                    <ReusableInput
                      label="Department"
                      name="department"
                      type="text"
                      placeholder="Enter your department"
                      disabled={true}
                      // rules={{ required: "Department is required" }}
                      className="placeholder:text-black dark:placeholder:text-white"
                      labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                    />
                  </div>

                  <div className="md:col-span-2">
                    <ReusableInput
                      label="Mobile Number"
                      name="mobileNumber"
                      type="text"
                      placeholder="Enter your Mobile Number"
                      rules={{ required: "Mobile Number is required" }}
                      className="placeholder:text-black dark:placeholder:text-white"
                      labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                    />
                  </div>

                  <div className="md:col-span-2">
                    <ReusableInput
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Enter your Email"
                      disabled={true}
                      rules={{ required: "Email is required" }}
                      className="placeholder:text-black dark:placeholder:text-white"
                      labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                    />
                  </div>

                  <div className="md:col-span-2">
                    <ReusableInput
                      label="Full Name"
                      name="fullName"
                      type="text"
                      placeholder="Enter Your Full Name"
                      rules={{ required: "Full Name is required" }}
                      className="placeholder:text-black dark:placeholder:text-white"
                      labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                    />
                  </div>

                  <div className="md:col-span-2">
                    <ReusableInput
                      label="Nick Name"
                      name="nickName"
                      type="text"
                      placeholder="Enter Your Nick Name"
                      className="placeholder:text-black dark:placeholder:text-white"
                      labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full md:col-span-2 bg-blue-500 text-white font-bold py-2 rounded-md"
                  >
                    Submit
                  </button>
                </form>
              </FormProvider>
            </div>
          ) : (
            <div className="bg-lightThemeGradient dark:bg-darkThemeGradient">
            <UserProfileWrapper
            isLoading={isLoading}
              showEditButton={setEditUserDetails}
              label={"Personal Information"}
            >
              <div className="   flex w-full gap-10 justify-between">
                <div className="w-full flex ">
                  <div className=" w-[90%]  flex   justify-between">
                    <div className="flex gap-4 flex-col justify-between ">
                      <div className=" ">
                        <h1 className="font-bold">Designation</h1>
                        <p>{userProfileData?.data?.designationName}</p>
                      </div>
                      <div className="">
                        <h1 className="font-bold ">Mobile Number </h1>
                        <p>{userProfileData?.data?.phoneNumber}</p>
                      </div>
                      <div className=" ">
                        <h1 className=" font-bold ">Email </h1>
                        <p>{userProfileData?.data?.email}</p>
                      </div>
                      <div className=" ">
                        <h1 className="  font-bold">Full Name</h1>
                        <p>{userProfileData?.data?.fullName}</p>
                      </div>
                      <div className="">
                        <h1 className="  font-bold">Nick Name </h1>
                        <p>{userProfileData?.data?.nickName}</p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4  ">
                      <div className="  ">
                        <h1 className="font-bold">Department </h1>
                        <div className="">
                          {userProfileData?.data?.departmentName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </UserProfileWrapper>
          </div>
          )}

         {showEditPassword &&  <div className="fixed inset-0 bg-gray-900    bg-opacity-50 flex justify-center items-center">
      <div className=" dark:bg-blue-500 bg-gray-700 w-full max-w-[1160px]  overflow-y-auto text-darkThemeFontColor rounded-lg ">
            <ChangePassword setShowEditPassword={setShowEditPassword}  isLoading={isLoading} />
          </div> </div>}
        </div>
        <div className="w-1/2 p-8 flex flex-col gap-4 ">
            <div className="w-full text-lightThemeFontColor dark:text-darkThemeFontColor flex justify-end ">
                
        <div onClick={()=>{setShowEditPassword(true);
          setEditEducation(false);
          setEditUserDetails(false)
          setEditPersonalProfile(false)

         }} className="cursor-pointer">Edit Password</div>
            </div>
          <div >
            {editPersonalProfile ? (
              <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg shadow-lg p-3">
                <div className="flex justify-between items-center border-b-2 mb-2 w-full">
                  <h1 className=" font-bold text-white ">
                    Personal Information
                  </h1>
                  <h1
                    onClick={() => {
                      setEditPersonalProfile(false);
                    }}
                  >
                    <MdOutlineViewAgenda />
                  </h1>
                </div>
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(editPersonalInfo)}
                    className="grid grid-cols-1 md:grid-cols-2 gap-4"
                  >
                    <div className="col-span-1 ">
                      <p className="text-lg font-medium">Language</p>
                      <div className="h-12 ">
                        <NormalDropDown
                          selectedValue={selectedLanguage}
                          setSelectedValue={setSelectedLanguage}
                          options={["Hindi", "English", "Sanskrit", "French"]}
                          label={"Select value"}
                          isMulti={true}
                        />
                      </div>
                    </div>

                    <div>
                      <ReusableInput
                        label="Marital Status"
                        name="maritalStatus"
                        type="text"
                        placeholder="Enter your Marital Status"
                        rules={{ required: "Marital status is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>

                    <div className="col-span-2">
                      <p className=" text-lg mb-2">Joining Date</p>
                      <div className="h-12 border flex items-center rounded-lg border-white">
                        <DateTimePicker
                          onChange={setJoiningDate}
                          value={joiningDate}
                          format="y-MM-dd"
                          className="w-full relative rounded-lg text-white p-2 dark:text-white"
                        />
                      </div>
                    </div>

                    {/* <div>
                      <ReusableInput
                        label="Company Name"
                        name="companyName"
                        type="text"
                        placeholder="Enter your company name"
                        rules={{ required: "Company name is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div> */}

                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Nationality"
                        name="nationality"
                        type="text"
                        placeholder="Enter your nationality"
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>

                    <div className="col-span-2">
                      <p>Date of Birth</p>
                      <div className="h-14 border w-full flex mt-2 items-center rounded-lg border-white">
                        <DateTimePicker
                          onChange={setDateOfBirth}
                          value={dateOfBirth}
                          format="y-MM-dd"
                          className="w-full relative rounded-lg text-white p-2 dark:text-white"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Address"
                        name="personalAddress"
                        type="text"
                        placeholder="Enter your Address"
                        rules={{ required: "Address is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full md:col-span-2 bg-blue-500 text-white font-bold py-2 rounded-md"
                    >
                      Submit
                    </button>
                  </form>
                </FormProvider>
              </div>
            ) : (
                <div className="bg-lightThemeGradient dark:bg-darkThemeGradient">
              <UserProfileWrapper
              isLoading={isLoading}
                showEditButton={setEditPersonalProfile}
                label={"Personal Information"}
              >
                <div className="   flex w-full gap-10 justify-between">
                  <div className="w-full flex ">
                    <div className=" w-[90%]  flex   justify-between">
                      <div className="flex gap-4 flex-col justify-between ">
                        <div className=" ">
                          <h1 className="font-bold">Language</h1>
                          <p>
                            {userProfileData?.data?.language?.map(
                              (item, ind) => (
                                <span>
                                  {item}{" "}
                                  {userProfileData?.data?.language.length -
                                    1 ===
                                  ind
                                    ? ""
                                    : ", "}
                                </span>
                              )
                            )}
                          </p>
                        </div>
                        <div className="">
                          <h1 className="font-bold ">Joining Date </h1>
                          <p>{clientData?.totalProperty}</p>
                        </div>
                        <div className=" ">
                          <h1 className=" font-bold ">Nationality </h1>
                          <p>{userProfileData?.data?.nationality}</p>
                        </div>
                        <div className=" ">
                          <h1 className="  font-bold">Date of Birth</h1>
                          <p>{userProfileData?.data?.DOB}</p>
                        </div>
                        <div className="">
                          <h1 className="  font-bold">Address </h1>
                          <p>{userProfileData?.data?.personalAddress}</p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-4  ">
                        <div className="  ">
                          <h1 className="font-bold">Marital Status </h1>
                          <div className="">
                            {userProfileData?.data?.maritialStatus}
                          </div>
                        </div>
                        <div className=" ">
                          <h1 className="font-bold">Company Name </h1>
                          <p>{clientData?.location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </UserProfileWrapper>
              </div>
            )}

          </div>

          <div>
            {editEducation ? (
              <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg shadow-lg p-3">
                <div className="flex justify-between items-center border-b-2 mb-2 w-full">
                  <h1 className=" font-bold text-white ">
                    Education Background
                  </h1>
                  <h1
                    onClick={() => {
                      setEditEducation(false);
                    }}
                  >
                    <MdOutlineViewAgenda />
                  </h1>
                </div>
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(handleEditEducation)}
                    className="gap-4"
                  >
                    <div className="">
                        <h1 className="font-bold">Under Graduation</h1>
                    <div className="flex mx-4 justify-between flex-wrap">
                    
                      <div className=" w-full">
                        <ReusableInput
                          label="Course Name"
                          name={`underCourseName`}
                          type="text"
                          placeholder="Enter Your Course Name"
                        //   rules={{ required: "Course Name is required" }}
                        />
                      </div>
                      <div className="w-full">
                        <ReusableInput
                          label="Institue Name"
                          name={`underInstitudeName`}
                          type="text"
                          placeholder="Enter Your Institute Name"
                        //   rules={{ required: "Institude Name is required" }}
                        />
                      </div>
                      <div className="w-[48%]">
                        <ReusableInput
                          label="Starting Year"
                          name={`underStartingYear`}
                          type="number"
                          placeholder="Enter Your Starting Year"
                        //   rules={{ required: "Starting Year is required" }}
                        />
                      </div>
                      <div className=" w-[48%]">
                        <ReusableInput
                          label="End Year"
                          name={`underEndYear`}
                          type="number"
                          placeholder="Enter Your Ending Year"
                        //   rules={{ required: "Ending Year is required" }}
                        />
                      </div>
                    </div>
                    </div>
                    <div className="">
                        <h1 className="font-bold">Post Graduation</h1>
                    <div className="flex mx-4 justify-between flex-wrap">
                    
                      <div className=" w-full">
                        <ReusableInput
                          label="Course Name"
                          name={`postCourseName`}
                          type="text"
                          placeholder="Enter Your Course Name"
                        //   rules={{ required: "Course Name is required" }}
                        />
                      </div>
                      <div className="w-full">
                        <ReusableInput
                          label="Institue Name"
                          name={`postInstitudeName`}
                          type="text"
                          placeholder="Enter Your Institute Name"
                        //   rules={{ required: "Institude Name is required" }}
                        />
                      </div>
                      <div className="w-[48%]">
                        <ReusableInput
                          label="Starting Year"
                          name={`postStartingYear`}
                          type="number"
                          placeholder="Enter Your Starting Year"
                        //   rules={{ required: "Starting Year is required" }}
                        />
                      </div>
                      <div className=" w-[48%]">
                        <ReusableInput
                          label="End Year"
                          name={`postEndYear`}
                          type="number"
                          placeholder="Enter Your Ending Year"
                        //   rules={{ required: "Ending Year is required" }}
                        />
                      </div>
                    </div>
                    </div>
                    <div className="">
                        <h1 className="font-bold">10+2</h1>
                    <div className="flex mx-4 justify-between flex-wrap">
                    
                      <div className=" w-full">
                        <ReusableInput
                          label="Course Name"
                          name={`highCourseName`}
                          type="text"
                          placeholder="Enter Your Course Name"
                        //   rules={{ required: "Course Name is required" }}
                        />
                      </div>
                      <div className="w-full">
                        <ReusableInput
                          label="Institue Name"
                          name={`highInstitudeName`}
                          type="text"
                          placeholder="Enter Your Institute Name"
                        //   rules={{ required: "Institude Name is required" }}
                        />
                      </div>
                      <div className="w-[48%]">
                        <ReusableInput
                          label="Starting Year"
                          name={`highStartingYear`}
                          type="number"
                          placeholder="Enter Your Starting Year"
                        //   rules={{ required: "Starting Year is required" }}
                        />
                      </div>
                      <div className=" w-[48%]">
                        <ReusableInput
                          label="End Year"
                          name={`highEndYear`}
                          type="number"
                          placeholder="Enter Your Ending Year"
                        //   rules={{ required: "Ending Year is required" }}
                        />
                      </div>
                    </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full md:col-span-2 bg-blue-500 text-white font-bold py-2 rounded-md"
                    >
                      Submit
                    </button>
                  </form>
                </FormProvider>
              </div>
            ) : (
                <div className="bg-lightThemeGradient dark:bg-darkThemeGradient">
              <UserProfileWrapper
              isLoading={isLoading}
                showEditButton={setEditEducation}
                label={"Education Information"}
              >
                <div className="   flex w-full gap-10 justify-between">
                  <div className="w-full flex ">
                    <div className=" w-[90%]  flex   justify-between">
                      <div className="flex w-full gap-4 flex-col justify-between ">
                        <div className="w-full ">
                          <h1 className="font-bold">Under Graduation</h1>
                          <div className="w-full" >
                            {userProfileData?.data?.education?.map(
                              (item, ind) => (
                                <div className="w-full flex  justify-between">
                                  {item?.educationType === "underGraduation" &&<>
                                   <span> {item?.courseName}</span> 
                                   <div className="flex ">
                                    <h1>{item?.startYear}-</h1>
                                    <h1>{item?.endYear}</h1>
                                   </div>
                                   </>
                                   
                                   }
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="">
                          <h1 className="font-bold ">Post Graduation </h1>
                          <p>
                            {" "}
                            {userProfileData?.data?.education?.map(
                              (item, ind) => (
                                <div className="w-full flex  justify-between">
                                  {item?.educationType === "postGraduation" &&<>
                                   <span> {item?.courseName}</span> 
                                   <div className="flex ">
                                    <h1>{item?.startYear}-</h1>
                                    <h1>{item?.endYear}</h1>
                                   </div>
                                   </>
                                   
                                   }
                                </div>
                              )
                            )}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className=" font-bold ">10+2 </h1>
                          <p>
                            {" "}
                            {userProfileData?.data?.education?.map(
                              (item, ind) => (
                                <div className="w-full flex  justify-between">
                                  {item?.educationType === "10+2" &&<>
                                   <span> {item?.courseName}</span> 
                                   <div className="flex ">
                                    <h1>{item?.startYear}-</h1>
                                    <h1>{item?.endYear}</h1>
                                   </div>
                                   </>
                                   
                                   }
                                </div>
                              )
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </UserProfileWrapper>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
