import React, { useState } from "react";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Cookies from "js-cookie";
import { FaCalendarAlt } from "react-icons/fa";
import Calendar from "../calendar";
import CustomCheckBox from "../CheckBox/customCheckBox";
import NormalDropDown from "../DropDown/NormalDropDown";

const Address = ({ onSubmit }) => {
  const methods = useForm();

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold text-black">Your Address</h1>
        <p className="text-[#96A0AD] my-2">
          The size and scope of your hotel portfolio play a crucial role in how
          R-World software can benefit your agency.
        </p>
      </div>
      <div className="flex justify-between flex-wrap">
        <div className=" w-[48%] text-black">
          <ReusableInput
            label="Flat/House No."
            name={`flatNo`}
            type="text"
            placeholder="Enter Your Flat Number"
            rules={{ required: "Flat no. is required" }}
            isBGWhite={true}
          />
        </div>
        <div className="w-[48%]  text-black">
        <ReusableInput
            label="Street Name"
            name={`streetName`}
            type="text"
            placeholder="Enter Street Name"
            rules={{ required: "Street Name is required" }}
            isBGWhite={true}
          />
        </div>
        <div className="w-[100%]">
        <ReusableInput
            label="Locality"
            name={`locality`}
            type="text"
            placeholder="Enter Your Locality"
            rules={{ required: "Locality is required" }}
            isBGWhite={true}
          />
        </div>
        <div className=" w-[48%]">
          <ReusableInput
            label="City"
            name={`city`}
            type="text"
            placeholder="Enter Your City"
            rules={{ required: "City is required" }}
            isBGWhite={true}
          />
        </div>
        <div className="w-[48%]">
        <ReusableInput
            label="Country"
            name={`country`}
            type="text"
            placeholder="Enter Your Country"
            rules={{ required: "Country is required" }}
            isBGWhite={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
