import React, { useState, useEffect, useRef } from "react";

import ProgressBar from "../../component/ProgressBar/progressBar";

import Button from "../../component/button";
import { useNavigate } from "react-router-dom";
// import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
// import { useHistory } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import FullCircle from "../../assets/icons/Circles/FullCircle.svg";
const LoadingPage = () => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  let logo = useRef(null);
  let semiBubble = useRef(null);
  let upsideDown = useRef(null);
  let headingAnimation = useRef(null);
  //   const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);

          return 100;
        }

        return prev + 1;
      });
    }, 50); // Adjust the speed of the animation by changing the interval time

    return () => clearInterval(interval);
  }, []);
  useGSAP(() => {
    const tl = gsap.timeline();

    // Set initial positions before animating
    tl.set(logo, { y: -32, x: -14 })
      .set(semiBubble, { y: 32, x: 20 })
      .set(upsideDown, { y: "50%", x: "-6px" });


      // Animation for screen 1
      tl.from(logo, { y: 100, duration: 1 })
        .from(semiBubble, { y: 64, x: 64, duration: 1 }, "<")
        .from(upsideDown, { y: 100, x: 150, duration: 1 }, "<")
        .to(logo, { y: 24, x: 112, duration: 1 })
        .to(semiBubble, { y: 160, x: -40, duration: 1 }, "<")
        .to(upsideDown, { y: -40, x: 50, duration: 1 }, "<")
        .to({}, { duration: 0.5 })
        .to(logo, { x: 0, y: 0, duration: 1 })
        .to(semiBubble, { y: 0, x: 60, duration: 1 }, "<")
        .to(upsideDown, { y: 0, x: 0, duration: 1 }, "<")
        .to(headingAnimation, { y: "+=20", opacity: 0, duration: 0.5 }, "-=0.5")
        .set(headingAnimation, { opacity: 0, y: "" })
        .set(headingAnimation, { opacity: 1 })
        .call(() => {
         
          gsap.to(headingAnimation, { y: "-=20", opacity: 1, duration: 10 });
        });
    
    return () => {
      tl.kill();
    };
  }, []);

  const handleGoToDashboard = () => {
    navigate('/loginDashboard');
  };

  return (
    <div
      className="w-[100vw] h-[100vh] text-lightThemeFontColor dark:text-darkThemeFontColor flex flex-col justify-center items-center"
      style={{ textAlign: "center", padding: "50px" }}
    >
           <div>
        <div
          ref={(el) => {
            logo = el;
          }}
          id="box"
          className="absolute -left-14 -top-32"
        >
          <img src={FullCircle} className="w-[100%] h-[100%]" />
        </div>
        <div
          ref={(el) => {
            semiBubble = el;
          }}
          className="absolute -right-20 top-32"
        >
          <img src={FullCircle} className="w-[100%] h-[100%]" />
        </div>
        <div
          ref={(el) => {
            upsideDown = el;
          }}
          className="absolute h-12 w-12 top-1/2 -left-6"
        >
          <img src={FullCircle} className="w-[100%] h-[100%]" />
        </div>
        <div className="flex mx-10 py-10 md:gap-6 flex-col h-[100%] w-[100%] md:w-1/3">
          <div className="flex flex-col text-white"></div>
        </div>
      </div>
      {progress == 100 ? (
        <h1 className="text-xl font-bold">Congratulations! We are ready for next step</h1>
      ) : (
        <h1>
          Ready to take{" "}
          <span style={{ color: "#7ED321" }}>Retvens Services</span> to the next
          level? Let's get started with setting up your{" "}
          <span style={{ color: "#7ED321" }}>client data and alerts</span>.
        </h1>
      )}

      <p>We are designing inputs to feed your dashboard on future steps</p>

      <div className="w-[50%] mt-10">
        <ProgressBar progress={progress} />
      </div>

      {progress === 100 && (
        <div className="mt-10">
          <Button
            onClick={handleGoToDashboard}
            className="mt-10 p-2 bg-blue-500 text-white rounded"
          >
            Go to Dashboard
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoadingPage;
