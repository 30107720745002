import React, { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const NormalDropDown = ({
  options,
  label,
  selectedValue,
  setSelectedValue,
  showSelectedValue=false, //use when is multi is true and you want to show selected value which in most cases will be a ID
  isMulti = false,
  sendOnlyId = false,
  showBorder=true,
  customTextColor,
  isTextField=false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
const [showTextField,setShowTextField]=useState();
const [searchBarText,setSearchBarText]=useState('')
const inputRef=useRef(null)
const [filteredOptions,setFilteredOptions]=useState(options)
const handleSearchBarText =(e)=>{
  setIsOpen(true)
  setSearchBarText(e.target.value)
  inputRef.current.focus();
  const filteredData = options.filter((item)=>item.toLowerCase().includes(e.target.value.toLowerCase()))
setFilteredOptions(filteredData);
  
}
  const handleSelect = (value) => {
    if (isMulti) {
      if (selectedValue?.includes(value)) {
        setSelectedValue(selectedValue.filter((item) => item !== value));
        setSearchBarText(selectedValue.filter((item) => item !== value))
      } else {
        setSelectedValue([...selectedValue, value]);
      }
    } else {
      setSelectedValue(value);
      setSearchBarText( value)
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if(isTextField){
      setShowTextField(true)
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDisplayValue = () => {
    if (isMulti) {
      if (selectedValue?.length === 0) {
      
        return label
      };
      if (selectedValue?.length > 3) {
        return (
          selectedValue
            .slice(0, 2)
            ?.map((id) => {
              const option = options?.find((opt) => opt.id === id);
              return option ? option.name : id;
            })
            .join(", ") +
          " +" +
          (selectedValue?.length - 2) +
          " Others"
        );
      }
      return selectedValue
        ?.map((id) => {
          const option = options?.find((opt) => opt.id === id);
          return option ? option.name : id;
        })
        .join(", ");
    } else {
      if (sendOnlyId) {
        const option = options?.find((opt) => opt.id === selectedValue);
        return option ? option.name : showSelectedValue?selectedValue:label;
      }
      return  selectedValue || label;
    }
  };

  return (
    <div className="relative w-full h-full text-black" ref={dropdownRef}>
      <div
        className={`flex justify-between items-center w-full h-full ${isTextField ?'p-0':'p-2'} ${customTextColor? customTextColor:'text-white/80'}  text-xs ${showBorder && 'border'} border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 ${
          selectedValue === "" || (isMulti && selectedValue?.length === 0)
            ? ""
            : ""
        }`}
        onClick={toggleDropdown}
      >
        {showTextField ? <input ref={inputRef} type='text' value={searchBarText} focus={true} onChange={handleSearchBarText} className='focus:outline-none px-4 h-full w-full bg-transparent'/>:getDisplayValue() || label}
        {}
        {isOpen ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
      </div>
      {isOpen && (
  <div className="absolute z-[999] mt-1 w-full whitespace-nowrap bg-white border border-gray-300 max-h-[200px] overflow-y-auto rounded-md shadow-lg">
    {isTextField
      ? filteredOptions?.map((option, index) => (
          <div
            key={index}
            className={`p-2 text-xs z-[999] text-gray-700 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white ${
              isMulti &&
              selectedValue?.includes(sendOnlyId ? option.id : option)
                ? "bg-blue-500 text-white"
                : ""
            }`}
            onClick={() => handleSelect(sendOnlyId ? option.id : option)}
          >
            {sendOnlyId ? option?.name : option}
          </div>
        ))
      : options?options?.map((option, index) => (
          <div
            key={index}
            className={`p-2 text-xs z-[999] text-gray-700 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white ${
              isMulti &&
              selectedValue?.includes(sendOnlyId ? option.id : option)
                ? "bg-blue-500 text-white"
                : ""
            }`}
            onClick={() => handleSelect(sendOnlyId ? option.id : option)}
          >
            {sendOnlyId ? option?.name : option}
          </div>
        )):<div class="flex items-center justify-center w-full p-4 bg-gray-50 border border-gray-300 rounded-md">
        <p class="text-gray-500 text-sm">No options available</p>
      </div>}
  </div>
)}

    </div>
  );
};

export default NormalDropDown;
