import React from "react";


const OutlinedButton = ({
    hasIcon,
    isLoading,
    buttonText,
    Icon,
    clickFunc
}) => {
  return (
    <button className="">
      <div className="flex flex-row justify-center items-center px-[14px] space-x-2 py-[4px] border border-white rounded-md">
        <p className="text-white text-[12px]">{buttonText}</p>
        <Icon className="h-[10px] text-white"/>
      </div>
    </button>
  );
};

export default OutlinedButton;
