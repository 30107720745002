import { useState } from "react";
import NormalDropDown from "./DropDown/NormalDropDown";
import { useGetPropertyAssignQuery, useGetUserListQuery,useGetPropertyUserListQuery,useGetClientsListQuery } from "../redux/slices/Setting";
import { useGetUtilsQuery, } from "../redux/slices/canabel";

const AssigneeForm = ({  setRedirectArray, redirectArray,hotelId }) => {
  const [selectedCheckup, setSelectedCheckup] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
    const {data:userListData}=useGetPropertyUserListQuery();
    const {data:utilsData}=useGetUtilsQuery({hId:hotelId})
  // Function to add new object to the array
  const addNewRedirect = () => {
    if (selectedCheckup && selectedValue) {
      const newRedirect = {
        user: selectedCheckup,
        role: selectedValue,
      };

      setRedirectArray([...redirectArray, newRedirect]);
      setSelectedCheckup(null); // Reset the selected checkup after adding
      setSelectedValue(null);   // Reset the selected time period after adding
    }
  };

  // Function to delete an item from the array based on index
  const deleteRedirect = (indexToDelete) => {
    setRedirectArray(redirectArray.filter((_, index) => index !== indexToDelete));
  };
const userMap = new Map(userListData?.data.map((item)=>[item?.id,item?.name]))
const roleMap = new Map(utilsData?.data?.roleList.map((item)=>[item?.id,item?.name]))

console.log(userMap,"userMap")
  return (
    <div className="my-2">
      {/* First column: Dynamically rendered Dropdowns */}
      <div className="  w-full space-y-4">
        {redirectArray.map((item, index) => (
          <div key={index} className="flex gap-4 items-center">
            <div className="w-32">
              <p>User</p>
              <div className="h-14">
                <NormalDropDown
                  selectedValue={userMap.get(item.user)}
                  setSelectedValue={(value) => {
                    const updatedArray = [...redirectArray];
                    updatedArray[index].user = value;
                    setRedirectArray(updatedArray);
                  }}
                  showSelectedValue={true}
                  options={userListData?.data}
                  sendOnlyId={true}
                  label={"Select value"}
                />
              </div>
            </div>

            <div className="w-32">
              <p>Role</p>
              <div className="h-14">
                <NormalDropDown
                  selectedValue={roleMap.get(item.role)}
                  setSelectedValue={(value) => {
                    const updatedArray = [...redirectArray];
                    updatedArray[index].role = value;
                    setRedirectArray(updatedArray);
                  }}
                  showSelectedValue={true}
                  options={utilsData?.data?.roleList}
                  sendOnlyId={true}
                  label={"Select value"}
                />
              </div>
            </div>

            <div
              onClick={() => deleteRedirect(index)}
              className="ml-4 bg-red-500 text-white px-2 py-1 rounded cursor-pointer"
            >
              Delete
            </div>
          </div>
        ))}

        {/* Initial Dropdowns for Adding New */}
        <div className="flex gap-4  justify-start  items-center">
          <div className="w-32">
            <p>User</p>
            <div className="h-14">
              <NormalDropDown
                selectedValue={selectedCheckup}
                setSelectedValue={setSelectedCheckup}
                options={userListData?.data}
                label={"Select value"}
                sendOnlyId={true}
              />
            </div>
          </div>

          <div className="w-32">
            <p>Role</p>
            <div className="h-14">
              <NormalDropDown
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                options={utilsData?.data?.roleList	}
                label={"Select value"}
                sendOnlyId={true}
              />
            </div>
          </div>
          <div
          onClick={addNewRedirect}
          className=" bg-blue-500 h-fit  text-white  px-2 py-1 rounded cursor-pointer"
        >
          Save
        </div>
      </div>
        </div>

        {/* Add New Button */}
    

      {/* Second column: List of Added Redirects */}
      {/* <div className="col-span-1 mt-4">
        {redirectArray.length > 0 && <h3>Added Redirects:</h3>}
        <ul>
          {redirectArray.map((item, index) => (
            <li key={index} className="flex justify-between mt-2 items-center">
              <div>
                <span className="font-bold">Checkup:</span> {item.checkupRedirect}{" "}
                <span className="font-bold">Period:</span> {item.timePeriod}
              </div>
              <div
                onClick={() => deleteRedirect(index)}
                className="ml-4 bg-red-500 text-white px-2 py-1 rounded cursor-pointer"
              >
                Delete
              </div>
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default AssigneeForm;

