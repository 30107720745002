import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import ReusableInput from "../InputField/ReusableInput";
import NormalDropDown from "../DropDown/NormalDropDown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./customDateTimePicker.css"; // Custom CSS for dark mode styles
import {
  useAddReminderMutation,
  useEditReminderMutation,
  useGetAllReminderQuery,
  useGetReminderQuery,
  useGetSingleReminderQuery,
} from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import Skeleton from "../Skeleton";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiPencil } from "react-icons/ti";
import UniversalModel from "./UniversalModel";
// import Toggle from '../Component/Toggle'
import Toggle from "../CheckBox/Toggle";
const AllReminder = ({ setViewAllReminder }) => {
  const [recurrence, setRecurrence] = useState();
  const [selectedPriority, setSelectedPriority] = useState();
  const [recurrenceTimeRange, setRecurrenceTimeRange] = useState();
  const [showEditReminder, setShowEditReminder] = useState(false);
  const [selectedReccurance, setSelectedRecurrence] = useState(false);
  const [reminderId, setReminderId] = useState();
  const [addReminder] = useAddReminderMutation();
  const { data: singleReminderData } = useGetSingleReminderQuery({
    reminderId: reminderId,
  });
  const [editReminder] = useEditReminderMutation();
  const [value, onChange] = useState(new Date());
  useEffect(() => {
    if (singleReminderData?.data) {
      setRecurrence(singleReminderData?.data?.reccurance);
      setSelectedPriority(singleReminderData?.data?.priority);
      methods.setValue("title", singleReminderData?.data?.title);
      // setRecurrenceTimeRange(singleReminderData?.data?.time)
      onChange(new Date(singleReminderData?.data?.time || new Date()));
      // setShowEditReminder(true)
      // setReminderId(singleReminderData?.data._id)
      setSelectedRecurrence(
        singleReminderData?.data?.reccurance == false ? "false" : "true"
      );
    }
  }, [singleReminderData?.data]);
  // Initialize form methods
  const methods = useForm({
    // resolver: zodResolver(schema),
    defaultValues: {
      textarea: "", // Default value for the textarea
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  // Form submission handler
  const handleSave = (data) => {
    addReminder({
      title: data?.fullName,
      time: value,
      reccurance: recurrence == "True" ? true : false,
      priority: selectedPriority,
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        // setShowReminder(false);
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  const handleEdit = (data) => {
    console.log(data, "dataaa");
    editReminder({
      reminderId: reminderId,
      title: data?.title,
      time: value,
      reccurance: selectedReccurance ,
      priority: selectedPriority,
    })
      .unwrap()
      .then(() => {
        refetch();
        setShowEditReminder(false)
      });
  };

  const {
    data: remindersData,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllReminderQuery();

  const handleDelete = (reminderId) => {
    editReminder({ reminderId: reminderId, isDeleted: true, isActive: false })
      .unwrap()
      .then(() => {
        refetch();
      });
  };
  return (
    <div className="fixed z-50 inset-y-0 left-[200px] right-0 bg-opacity-50  mt-20 flex justify-center items-center">
    <div className="bg-lightThemeGradient dark:bg-darkThemeGradient w-full max-w-[80%] text-darkThemeFontColor rounded-lg shadow-md">
      <div className="relative w-full flex justify-center py-4">
        {/* Save Button */}
        <div className="w-full max-h-[600px] overflow-y-scroll p-4">
          <h1> All Reminder</h1>
  {!isLoading ? (
    remindersData?.data?.length>0 ? (
      remindersData?.data?.map((reminder, index) => (
        <div
          key={index}
          className="bg-blue-500 w-full rounded-lg p-4 mb-3 flex justify-between items-center"
        >
          <div>
            <p className="text-sm">{reminder?.title}</p>
            <h3 className="text-sm mt-2 font-medium">
              <span className="font-bold">Time:</span> {reminder.time}
            </h3>
            <h3 className="text-sm font-medium">
              <span className="font-bold">Priority:</span> {reminder?.priority}
            </h3>
          </div>
          <div className="flex gap-4">
            <div
              onClick={() => handleDelete(reminder?.reminderId)}
              className="flex cursor-pointer gap-2 items-center text-red-600"
            >
              <RiDeleteBin6Line /> Delete
            </div>
            <h1
              onClick={() => {
                setShowEditReminder(true);
                setReminderId(reminder?.reminderId);
              }}
              className="flex cursor-pointer gap-2 items-center text-white"
            >
              <TiPencil /> Edit
            </h1>
          </div>
        </div>
      ))
    ) : (
      <div className="w-full text-center">No data found</div>
    )
  ) : (
    Array(10)
      .fill("")
      .map((_, i) => (
        <div key={i} className="h-12 w-full mb-3">
          <Skeleton />
        </div>
      ))
  )}
</div>

      </div>
      {showEditReminder && (
        <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-lightThemeGradient dark:bg-popupGradient w-full max-w-[1260px] text-darkThemeFontColor rounded-lg shadow-md">
            <div className="relative w-full flex justify-center py-4">
              <div
                onClick={() => setShowEditReminder(false)}
                className="absolute top-4 left-4 cursor-pointer text-white text-2xl"
              >
                <IoClose />
              </div>
              <button
                type="submit"
                className="absolute top-4 right-4 cursor-pointer text-white bg-blue-700 rounded-lg p-2"
                onClick={methods.handleSubmit(handleEdit)}
              >
                Save
              </button>
              <FormProvider {...methods}>
                <form
                  className="grid grid-cols-2 gap-4 p-6 w-full h-4/5"
                  onSubmit={methods.handleSubmit(handleEdit)}
                >
                  <div className="col-span-1 h-12">
                    <ReusableInput
                      label="Reminder title"
                      name="title"
                      type="text"
                      placeholder="Enter your Full Name"
                      rules={{ required: "Full Name is required" }}
                      showIcon={true}
                    />
                  </div>
                
                  <div className="col-span-1 h-20  flex items-center justify-between">
                  <p>Task Recurrence</p>
                  
<div className='w-fit h-14'>
                  <Toggle onClick={()=>setSelectedRecurrence((pre=>!pre))} isToggled={selectedReccurance}/>
                  </div>
                
                </div>
                  <div className="col-span-1 ">
                    <p className="mb-2">Priority</p>
                    <div className="h-12">
                      <NormalDropDown
                        selectedValue={selectedPriority}
                        setSelectedValue={setSelectedPriority}
                        options={["Low", "High"]}
                        label={"Select value"}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <p>Date & Time</p>
                    <div className="h-12 border flex mt-1 items-center rounded-lg border-white">
                      <DateTimePicker
                        onChange={onChange}
                        value={value}
                        format="y-MM-dd h:mm a"
                        className="w-full relative rounded-lg text-white p-2 dark:text-white"
                      />
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  
  );
};

export default AllReminder;
