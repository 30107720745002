import React, { Fragment, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useGetUpcomingTaskQuery } from "../../redux/slices/dashboard";
import "./upComingTask.css";
import Skeleton from "../Skeleton";
import { gapi } from "gapi-script";
import { useAddTokenSalesMutation } from "../../redux/slices/sales";
import { Tooltip } from "react-tooltip";
// Moment.js localizer
const localizer = momentLocalizer(moment);

const UpComingTask = () => {
  const [token, setToken] = useState();
  const [addTokenSales] = useAddTokenSalesMutation();
  const { data: upComingData, isLoading } = useGetUpcomingTaskQuery();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [eventss, setEvents] = useState([]);
  // Map tasks to events format required by react-big-calendar
  const events = useMemo(() => {
    if (!upComingData || !upComingData.data) return [];

    return upComingData.data.map((task) => ({
      title: task.taskNames.join(", "), // Joining task names if multiple
      start: new Date(task.date), // Start date
      end: new Date(task.date), // End date (can be the same if it's an all-day event)
      allDay: true,
      tooltip: `Tasks: ${task.taskNames.join(", ")}`,
    }));
  }, [upComingData]);
  // Custom Event component with tooltip
  const EventComponent = ({ event }) => {
   return <div className="bg-blue-100 text-blue-500 mt-1 px-1 rounded-md">
      <div
        className="min-w-[20%] max-w-[100%] text-xs truncate"
        data-tooltip-content={event.title}
        data-tooltip-id={event.tooltip}
      >
        {event.title}
      </div>
      <Tooltip id={event.tooltip} />
    </div>
  };

  const CLIENT_ID =
    "908257533893-f0f53m1or8vdu4h0tsg9qkvbrhpc0nho.apps.googleusercontent.com"; // Replace with your client ID
  const API_KEY = "AIzaSyC3lox-iGh2W2-VokLmZVz60LnLHgLSpMg"; // Replace with your API key
  const SCOPES = "https://www.googleapis.com/auth/calendar.events.readonly";

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: SCOPES,
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          setIsSignedIn(authInstance.isSignedIn.get());
          authInstance.isSignedIn.listen(setIsSignedIn);
        });
    }

    gapi.load("client:auth2", start);
  }, []);

  const handleSignIn = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((response) => {
        setToken(response.getAuthResponse().access_token);
        localStorage.setItem("google_calendar_token", token);
        console.log("Token saved to localStorage:", token);
      });
  };

  const handleSignOut = () => {
    gapi.auth2.getAuthInstance().signOut();
  };

  const fetchEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: "primary",
        timeMin: new Date().toISOString(), // Fetch upcoming events from now
        maxResults: 1,
        singleEvents: true,
        orderBy: "startTime",
      })
      .then((response) => {
        const events = response.result.items;
        setEvents(events);
      });
  };

  console.log(token);
  return (
    <Fragment>
      {/* <div>
      {!isSignedIn ? (
        <button onClick={handleSignIn}>Sign in with Google</button>
      ) : (
        <>
          <button onClick={handleSignOut}>Sign out</button>
          <button onClick={fetchEvents}>Fetch Calendar Events</button>
          <ul>
            {eventss.map((event) => (
              <li key={event.id}>
                {event.summary} - {new Date(event.start.dateTime).toLocaleString()}
              </li>
            ))}
          </ul>
        </>
      )}
    </div> */}
      <div className="p-4 rounded-lg bg-lightThemeGradient dark:bg-darkThemeGradient w-full my-2 text-white">
        <h2 className="text-md font-bold mb-4">Upcoming Tasks</h2>
        {!isLoading ? (
          <div className="h-full w-full bg-transparent text-white p-2 rounded-lg">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 650 }}
              views={["month", "week", "day"]} // Enabling week and day modes
              defaultView="month"
              step={60}
              showMultiDayTimes
              components={{
                // event: EventComponent, // Custom event component to show tooltip
              }}
              className="custom-calendar text-white bg-transparent"
            />
          </div>
        ) : (
          <div className="h-full">
            <Skeleton />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default UpComingTask;
