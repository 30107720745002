import React, { useEffect, useRef, useState } from "react";
import "./Calendar.css";
// import calIcon from "../../assets/img/cal.svg";
import { format, startOfMonth, getDay, subMonths } from "date-fns";
import { useIsIpad } from "../../utils/isIpad";
// import { toast } from "react-toastify";
// import leftArrow from "../../assets/icons/leftarrow.svg";
const Calendar = ({
  setSelectedDate,
  selectedDate,
  backgroundNone = false,
  width,
  right,
  enableDaysFromNow = 365,
  showIconBackground = false,
  height,
  disablePastDays = false,
  maxSelectableYear,
  enableOneYearFromNow = false,
  onlyPastDaysSelectable = false,
  minStart,
  icon,
  showAll = false,
  label,
  labelWhite,
  isText=false
}) => {
  const ref = useRef();
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(selectedDate).getMonth()
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date(selectedDate).getFullYear()
  );
  const [viewMode, setViewMode] = useState("date");
  const [showCal, setShowCal] = useState(false);

  useEffect(() => {
    const today = new Date();
    if (enableOneYearFromNow) {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(today.getFullYear() + 1);
      if (selectedDate > oneYearFromNow) {
        setSelectedDate(oneYearFromNow);
      }
    }
  }, [enableOneYearFromNow, selectedDate, setSelectedDate]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return getDay(startOfMonth(new Date(year, month)));
  };

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const firstDayOfMonth = getFirstDayOfMonth(selectedYear, selectedMonth);

    const calendar = [];
    const today = minStart
      ? new Date(minStart)
      : new Date().setDate(new Date().getDate() - 1);
    const enableDate = new Date();
    enableDate.setDate(enableDate.getDate() + enableDaysFromNow);

    // Add placeholders for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendar.push(
        <div key={`empty-${i}`} className="calendar-day empty"></div>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(selectedYear, selectedMonth, i);
      const isPastDate = currentDate < today;
      const isFutureDate = currentDate <= enableDate && currentDate >= today;
      const isSelected = i === new Date(selectedDate).getDate();
      const isDisabledYear = currentDate.getFullYear() > maxSelectableYear;
      const isSelectable = onlyPastDaysSelectable ? isPastDate : isFutureDate;

      let className = "calendar-day text-[12px]";
      if (isSelected) {
        className += " selected text-black";
      } else if (isPastDate && disablePastDays) {
        className += " past-day text-[#A8B1C4]";
      } else if (isDisabledYear) {
        className += " disabled text-[#A8B1C4]";
      } else if (isSelectable) {
        className += " selectable text-black";
      } else {
        className += " future-day text-[#A8B1C4]";
      }

      calendar.push(
        <div
          className={className}
          key={i}
          onClick={() => {
            if (!isSelectable && !showAll) {
              // toast.error(
              //   `Only ${
              //     onlyPastDaysSelectable ? "past" : "future"
              //   } dates are selectable.`,
              //   {
              //     position: "bottom-right",
              //   }
              // );
            } else {
              setSelectedDate(new Date(selectedYear, selectedMonth, i));
              setShowCal(false);
            }
          }}
        >
          {i}
        </div>
      );
    }

    return calendar;
  };

  const renderMonths = () => {
    const months = Array.from({ length: 12 }, (_, i) => i);
    return months.map((month) => (
      <div
        key={month}
        className={`month cursor-pointer ${
          selectedMonth === month ? "selected" : ""
        }`}
        onClick={() => handleMonthClick(month)}
      >
        {new Date(2000, month, 1).toLocaleString("default", { month: "short" })}
      </div>
    ));
  };

  const renderYears = () => {
    const years = Array.from({ length: 12 }, (_, i) => selectedYear - 5 + i);
    return years.map((year) => (
      <div
        key={year}
        className={`year cursor-pointer ${
          selectedYear === year ? "selected" : ""
        }`}
        onClick={() => handleYearClick(year)}
      >
        {year}
      </div>
    ));
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    setViewMode("date");
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    setViewMode("date");
  };

  const handlePrevMonth = () => {
    if (viewMode === "month" || viewMode === "date") {
      const prevMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
      const prevYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
    } else if (viewMode == "year") {
      const prevYear = selectedYear - 1;
      setSelectedYear(prevYear);
    }
  };

  const handleNextMonth = () => {
    if (viewMode === "month" || viewMode === "date") {
    const nextMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(nextMonth);
    setSelectedYear(nextYear);
    } else if (viewMode === "year") {
      const nextYear = selectedYear + 1;
      setSelectedYear(nextYear);
    }
    
  };

  const handlePrevious = () => {
    const prevDate = new Date(selectedDate);
    prevDate.setDate(prevDate.getDate() - 1);

    if (!disablePastDays || (disablePastDays && prevDate >= new Date())) {
      setSelectedDate(prevDate);
    }
  };

  const handleNext = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);

    if (
      !onlyPastDaysSelectable ||
      (onlyPastDaysSelectable && nextDate < new Date())
    ) {
      setSelectedDate(nextDate);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setShowCal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  const isIpad = useIsIpad();
  return (
    <div
      className={` ${width ? `w-[${width}px]` : " w-[100%]"} flex `}
      ref={ref}
    >
      <div
        className={`flex cursor-pointer justify-between gap-2 items-center ${backgroundNone ? "" : "bg-[#E9EBF0] dark:bg-subMainDark"}  dark:text-mainDark text-mainLight ${height ? `h-8` : "h-10"}  ${width ? `w-[${width}px]` : "w-fit "} border border-white/15  px-1 text-[0.8em] whitespace-nowrap rounded-xl`}
      >
        {/* {showIconBackground && (
          <div
            className={`h-[41px] w-[48px] rounded-lg flex items-center justify-center bg-[#FBFCFD] cursor-pointer`}
            onClick={handlePrevious}
          ></div>
        )} */}

        <div
          className="flex  items-center justify-between w-full  gap-2"
          onClick={() => setShowCal(!showCal)}
        >
          <div className="p-1 bg-slate-500/35 rounded-xl">

          {icon}
          </div>
          {/* {format(new Date(selectedDate), "dd MMM','yy")} */}
          <div className={`text-xs font-medium  ${labelWhite?'text-white':'text-black'} dark:text-textLight `}>
            {format(new Date(selectedDate), "dd MMM','yy") || label}
          </div>
        </div>

        {/* {showIconBackground && (
          <div
            className="h-[41px] w-[48px] rounded-lg flex cursor-pointer items-center justify-center bg-[#FBFCFD]"
            onClick={handleNext}
          >
           
            <img src={'leftArrow'} className="md:ml-2 h-[14px]" loading="lazy" />
          </div>
        )} */}
      </div>
      {showCal && (
        <div
          className={`calendar-container absolute ${isIpad ? " w-[25vw] " : " w-[330px] "}  z-10 top-6 left-0`}
          style={{
            boxShadow: "0px 1px 2px 0px #00000069",
            background: "#f9f9f9",
          }}
        >
          <div className="header  text-black">
            <div className="arrow" onClick={handlePrevMonth}>
              {"<"}
            </div>
            <div className="flex">
              <div
                className="month-year cursor-pointer mr-2"
                onClick={() => setViewMode("month")}
              >
                {viewMode === "date"
                  ? new Date(selectedYear, selectedMonth, 1).toLocaleString(
                      "default",
                      { month: "short" }
                    )
                  : "Month"}
              </div>
              <div
                className="month-year cursor-pointer"
                onClick={() => setViewMode("year")}
              >
                {viewMode === "date" ? selectedYear : "Year"}
              </div>
            </div>
            <div className="arrow" onClick={()=>handleNextMonth(true)}>
              {">"}
            </div>
          </div>
          <div className="calendar">
            {viewMode === "date" && (
              <div className="weekdays text-[#59647B] text-[12px]">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
            )}
            <div className="days">{viewMode === "date" && renderDays()}</div>
            <div className="monthyearview text-black">
              {viewMode === "month" && renderMonths()}
              {viewMode === "year" && renderYears()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
