import React from "react";
import Button from "../button";
import { MdOutlineAddReaction } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const NotificationCondition = ({noti, condition = "modification" }) => {
     const navigate =useNavigate()
  return (
    <>
      {condition === "task" && (
        <div className="mt-4 bg-white/20 p-4 rounded-lg flex flex-col ">
          <div>
            <h5 className="font-medium">This task</h5>
            <div className="text-sm text-gray-300">Edited 10 min ago</div>
          </div>

          {/* Action Buttons */}
          <div onClick={()=>{navigate('/task-management',{ state: { propTaskId: noti?.taskDetail?.taskId,propModel:true } })}} className="mt-2  flex gap-3 w-1/6">
            {/* <Button type="light">Decline</Button> */}
            <Button type="">View</Button>
          </div>
        </div>
      )}
      {condition === "client" && (
        <div className="mt-4 bg-white/20 p-4 rounded-lg flex flex-col ">
          <div>
            <h5 className="font-medium">@shivam has completed tasks</h5>
          </div>

          <div className="flex  items-center gap-2 rounded-lg mt-2 p-2">
            <MdOutlineAddReaction />
            <h1 className="w-full">
              <input
                type="text"
                className="w-full bg-transparent focus:outline-none active:border-none h-full"
                placeholder="Write someing..."
              />{" "}
            </h1>
          </div>
        </div>
      )}
      {condition === "account" && (
        <div className="mt-4 bg-white/20 p-4 rounded-lg flex flex-col ">
          <div className="flex  items-center gap-2 rounded-lg mt-2 p-2">
            <div className="h-full rounded-lg">
              <MdOutlineAddReaction color="black"  size={20}/>
            </div>
            <h1 className="w-full">Document.txt </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationCondition;
