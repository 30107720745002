import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';

const ExcelToJson = ({ file, setData }) => {
  useEffect(() => {
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Object to hold data from all sheets
        const sheetsData = {};

        // Loop through each sheet and parse its data
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          sheetsData[sheetName] = XLSX.utils.sheet_to_json(worksheet, { defval: null });
        });

        // Save all sheets data directly to the provided setData function
        setData(sheetsData);
      } catch (error) {
        console.error('Error parsing Excel file:', error);
        setData(null); // Clear data in case of an error
      }
    };

    reader.onerror = () => {
      console.error('Error reading the file');
      setData(null); // Clear data if file read fails
    };

    reader.readAsArrayBuffer(file);
  }, [file, setData]);

  return null; // This component does not render anything
};

ExcelToJson.propTypes = {
  file: PropTypes.instanceOf(File),
  setData: PropTypes.func.isRequired,
};

export default ExcelToJson;
