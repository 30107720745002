import React, { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const NormalDropdownDupli = ({
  options,
  label,
  selectedValue,
  setSelectedValue,
  isMulti = false,
  sendOnlyId = true,
}) => {
   
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (value) => {
   
    if (isMulti) {
      if (selectedValue.includes(value)) {
        setSelectedValue(selectedValue.filter((item) => item !== value.departmentId));
      } else {
        setSelectedValue(value.departmentId);
      }
    } else {
      setSelectedValue(value);
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDisplayValue = () => {
    if (isMulti) {
      if (selectedValue?.length === 0) return label;
      return selectedValue.map(id => {
        const option = options.find(opt => opt.departmentId === id);
        return option ? option.departmentName : id;
      }).join(", ");
    } else {
      if (sendOnlyId) {
        const option = options.find(opt => opt.departmentId === selectedValue);
        return option ? option.departmentName : label;
      }
      return selectedValue || label;
    }
  };

  return (
    <div className="relative w-full h-full" ref={dropdownRef}>
      <div
        className={`flex justify-between items-center w-full h-full p-2 text-gray-700 bg-white text-xs border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 ${
          selectedValue === "" || (isMulti && selectedValue?.length === 0)
            ? "text-gray-500"
            : ""
        }`}
        onClick={toggleDropdown}
      >
        {getDisplayValue()}
        {isOpen ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
      </div>
      {isOpen && (
        <div className="absolute z-[999] mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
          {options.map((option, index) => (
            <div
              key={index}
              className={`p-2 text-xs z-[999] text-gray-700 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white ${
                isMulti && selectedValue && selectedValue.includes(sendOnlyId ? option.departmentId : option)
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              onClick={() => handleSelect(sendOnlyId ? option.departmentId : option)}
            >
              {sendOnlyId ? option?.departmentName : option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NormalDropdownDupli;
