import React, { useState } from 'react'
import Calendar from '../calendar';
import { FaCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';

const BillingPreferance = ({customDate, setCustomDate}) => {
    const [selectedDate, setSelectedDate] = useState("beginning");
    const handleChange = (event) => {
      setSelectedDate(event.target.value);
      if (selectedDate === 'beginning') {
        // Create a new date object for the first day of the next month
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        
        // Format the date and set it as the custom date
        setCustomDate(format(nextMonth, 'yyyy-MM-dd'));
      }
    };
   
  return (
    <div className="w-[100%] h-[100%]">
    <div>
      <h1 className="text-3xl font-semibold">Billing Preferences</h1>
      <p className="text-[#96A0AD] my-2">
      Now that we have your business information, let's finalise your billing preferences for R-World
      </p>
    </div>
    <div>
      <p className="my-2 f">
      When would you prefer your first invoice to be generated?
      </p>
      <label className="flex items-center gap-2 m-2">
        <input
          type="radio"
          id="beginning"
          name="beginning"
          value="beginning"
          onChange={handleChange}
          checked={selectedDate === "beginning"}
        />
        Beginning of you Next Billing Cycle
      </label>

      <label className="flex items-center gap-2 m-2">
        <input
          type="radio"
          id="custom"
          name="custom"
          value="custom"
          onChange={handleChange}
          checked={selectedDate === "custom"}
        />
        Custom Start Date
      </label>

   {selectedDate === "custom" && <div className='p-2 w-fit flex relative items-center rounded-md bg-slate-200'>
       
        <div className=' rounded-md  w-fit '>

        <Calendar
              selectedDate={customDate}
              setSelectedDate={setCustomDate}
            //   enableDaysFromNow={60}
              backgroundNone={true}
              showIconBackground={true} 
              onlyPastDaysSelectable={true}
              icon={<FaCalendarAlt size={24} /> }
              />
              </div>
     </div>}
    </div>
  </div>
  )
}

export default BillingPreferance
