import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ReusableInput from "../component/InputField/ReusableInput";

import {

  useGetEmployeePiQuery,
  useEditEmployeeDetailMutation,
} from "../redux/slices/onBoarding";
import { toast } from "react-toastify";
import UserProfilePicture from "./profilePage/UserProfilePicture";
import DateTimePicker from "react-datetime-picker";
import UserProfileWrapper from "../component/WrapperComponents/UserProfileWrapper";
import { MdOutlineViewAgenda } from "react-icons/md";
import TeamActivity from "../component/TeamActivity";
import { useParams } from "react-router-dom";
import EmployeeOverviewProperty from "../component/EmployeeOverviewProperty";
import EmployeeActivity from "../component/EmployeeActivity";
const EmployeeDetails = () => {
  const methods = useForm();
  const { empId } = useParams();
  const {
    data: userProfileData,
    error,
    isLoading,
    refetch:empRefetch
  } = useGetEmployeePiQuery({userId:empId});
  const [editUserProfile] = useEditEmployeeDetailMutation();
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [editPersonalProfile, setEditPersonalProfile] = useState();
  
  useEffect(() => {
    if (userProfileData) {
      methods.reset({
        designation: userProfileData?.data?.designationName || "",
        department: userProfileData?.data?.departmentName || "",
        mobileNumber: userProfileData?.data?.phoneNumber || "",
        email: userProfileData?.data?.email || "",
        fullName: userProfileData?.data?.fullName || "",
        nickName: userProfileData?.data?.nickName || "",
        maritalStatus: userProfileData?.data?.maritialStatus || "",
        nationality: userProfileData?.data?.nationality || "",
        personalAddress: userProfileData?.data?.personalAddress || "",
      });
      userProfileData?.data?.education?.map((item) => {
        if (item.educationType === "underGraduation") {
          methods.setValue("underCourseName", item?.courseName);
          methods.setValue("underInstitudeName", item?.institute);
          methods.setValue("underStartingYear", item?.startYear);
          methods.setValue("underEndYear", item?.endYear);
        } else if (item?.educationType === "postGraduation") {
          methods.setValue("postCourseName", item?.courseName);
          methods.setValue("postInstitudeName", item?.institute);
          methods.setValue("postStartingYear", item?.startYear);
          methods.setValue("postEndYear", item?.endYear);
        } else {
          methods.setValue("highCourseName", item?.courseName);
          methods.setValue("highInstitudeName", item?.institute);
          methods.setValue("highStartingYear", item?.startYear);
          methods.setValue("highEndYear", item?.endYear);
        }
      });
      setSelectedLanguage(userProfileData?.data?.language);
      //   setSelectedLanguage((prev)=>[...prev,])
    }
  }, [userProfileData, error, methods]);

  const onSubmit = (data) => {
    const editableData = {
      phoneNumber: data.mobileNumber,
      fullName: data?.fullName,
      nickName: data?.nickName,
    };

    editUserProfile(editableData)
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  const editPersonalInfo = (data) => {
    const editableData = {
      fullName: data?.fullName,
      nickName: data?.nickName,
    //   designation: data?.designation,
      joiningDate: joiningDate,
      phoneNumber: data?.mobileNumber,
    //   department: data?.department,
      DOB: dateOfBirth,
      personalAddress: data?.personalAddress,
      userId:empId,
    };

    editUserProfile(editableData)
      .unwrap()
      .then((res) => {
        setEditPersonalProfile(false)
        toast.success(res?.message, { position: "bottom-right" });
        empRefetch()
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  const clientData = "";
  return (
    <>
      <div className="flex mr-6 mt-6">
        <div className="w-1/2 p-8 mt-10 pl-20">
          <UserProfilePicture />
<div className="h-[850px] rounded-lg  overflow-y-scroll ">

          <EmployeeActivity />
</div>
        </div>

        <div className="w-1/2 p-8 flex flex-col gap-4 mt-10 ml-6">
          <div>
            {editPersonalProfile ? (
              <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg shadow-lg p-3">
                <div className="flex justify-between items-center border-b-2 mb-2 w-full">
                  <h1 className=" font-bold text-white ">
                    Personal Information
                  </h1>
                  <h1
                    onClick={() => {
                      setEditPersonalProfile(false);
                    }}
                  >
                    <MdOutlineViewAgenda />
                  </h1>
                </div>
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(editPersonalInfo)}
                    className="grid grid-cols-1 md:grid-cols-2 gap-4"
                  >
                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Full Name"
                        name="fullName"
                        type="text"
                        placeholder="Enter Your Full Name"
                        rules={{ required: "Full Name is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>
                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Nick Name"
                        name="nickName"
                        type="text"
                        placeholder="Enter Your Nick Name"
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>
                    <div className="mr-4">
                      <ReusableInput
                        label="Designation"
                        name="designation"
                        type="text"
                        placeholder="Enter your designation"
                        disabled={true}
                        rules={{ required: "Designation is required" }}
                        className="placeholder:text-black dark:text-white dark:placeholder:text-white text-black"
                        labelClass="text-black dark:text-white"
                      />
                    </div>
                    <div className="col-span-1">
                      <p className=" text-lg mb-2">Joining Date</p>
                      <div className="h-12 border flex items-center rounded-lg border-white">
                        <DateTimePicker
                          onChange={setJoiningDate}
                          value={joiningDate}
                          format="y-MM-dd"
                          className="w-full relative rounded-lg text-white p-2 dark:text-white"
                       
                        />
                      </div>
                    </div>
                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Mobile Number"
                        name="mobileNumber"
                        type="text"
                        placeholder="Enter your Mobile Number"
                        rules={{ required: "Mobile Number is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>

                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Department"
                        name="department"
                        type="text"
                        placeholder="Enter your department"
                        // disabled={true}
                        rules={{ required: "Department is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>
                    <div className="col-span-2">
                      <p>Date of Birth</p>
                      <div className="h-14 border w-full flex mt-2 items-center rounded-lg border-white">
                        <DateTimePicker
                          onChange={setDateOfBirth}
                          value={dateOfBirth}
                          format="y-MM-dd"
                          className="w-full relative rounded-lg text-white p-2 dark:text-white"
                          maxDate={new Date()}
                        />
                      </div>
                    </div>

                    <div className="md:col-span-2">
                      <ReusableInput
                        label="Address"
                        name="personalAddress"
                        type="text"
                        placeholder="Enter your Address"
                        rules={{ required: "Address is required" }}
                        className="placeholder:text-black dark:placeholder:text-white"
                        labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full md:col-span-2 bg-blue-500 text-white font-bold py-2 rounded-md"
                    >
                      Submit
                    </button>
                  </form>
                </FormProvider>
              </div>
            ) : (
              <div className="bg-lightThemeGradient dark:bg-darkThemeGradient">
                <UserProfileWrapper
                  isLoading={isLoading}
                  showEditButton={setEditPersonalProfile}
                  label={"Overview "}
                >
                  <div className="   flex w-full gap-10 justify-between">
                    <div className="w-full flex ">
                      <div className=" w-[70%]  flex   justify-between">
                        <div className="flex gap-4 flex-col justify-between ">
                          <div className=" ">
                            <h1 className="font-bold">Full Name</h1>
                            {userProfileData?.data?.fullName}
                          </div>
                          <div className="">
                            <h1 className="  font-bold">Nick Name </h1>
                            <p>{userProfileData?.data?.nickName}</p>
                          </div>

                          <div className="">
                            <h1 className="font-bold ">Mobile Number </h1>
                            <p>{userProfileData?.data?.phoneNumber}</p>
                          </div>
                          <div className=" ">
                            <h1 className="font-bold">Designation</h1>
                            <p>{userProfileData?.data?.designationName}</p>
                          </div>
                          <div className="">
                            <h1 className="  font-bold">Address </h1>
                            <p>{userProfileData?.data?.personalAddress}</p>
                          </div>
                        </div>
                        <div className="flex  w-1/2 flex-col gap-4  ">
                          <div className="">
                            <h1 className="font-bold ">Joining Date </h1>
                            <p>{clientData?.joiningDate}</p>
                          </div>

                          <div className=" ">
                            <h1 className=" font-bold ">Email </h1>
                            <p>{userProfileData?.data?.email}</p>
                          </div>
                          <div className="  ">
                            <h1 className="font-bold">Department </h1>
                            <div className="">
                              {userProfileData?.data?.departmentName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </UserProfileWrapper>
              </div>
            )}
          </div>
          <div className="h-fit">
            <EmployeeOverviewProperty empId={empId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDetails;
