import React, { useState,useEffect } from "react";
import UniversalTable, {
  DataNotFound,
} from "../../component/Table/UniversalTable";
import {
  useAssignServicesMutation,
  useGetFinalLeadQuery,
  useGetServicesQuery,
} from "../../redux/slices/CSO/dashboard";
import {
  useAddClientTransactionMutation
} from "../../redux/slices/Finance/index";

import NormalDropDown from "../../component/DropDown/NormalDropDown";
import ReusableDropDown from "../../component/DropDown/DropDown";
import Select from "react-select";
import { toast } from "react-toastify";
import Skeleton from "../../component/Skeleton";
import AddCustomerForm from "./addCustomerForm";
import UniversalModal from '../../component/Modal/UniversalModel'
function separateCamelCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert a space before each uppercase letter
    .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
}

const removeKeys = (array) => {
  return array.map((item) => {
    return Object.fromEntries(
      Object.entries(item).filter(
        ([key]) => key !== "leadId" 
      )
    );
  });
};

const CSO_Dashboard = () => {
  const [pageNumber ,setPageNumber]=useState(1);

  const {
    data: getFinalData,
    isLoading,
    isFetching,
    isError,
    refetch: refetchFinalLead,
  } = useGetFinalLeadQuery({pageNumber:pageNumber});
  const [selectedOption, setSelectedOption] = useState();
  const [selectedClientId,setSelectedClientId]=useState();
  const [currentIndex, setCurrentIndex] = useState();
  const { data: getServices, refetch } = useGetServicesQuery();
  const filterArray = getFinalData?.data && removeKeys(getFinalData?.data);
  const [actualSelectedOptions,setActualSelectedOption]=useState([]);
  const [OpenTransactionModel,setOpenTransactionModel]=useState(false);
  const [imageFile ,setImageFile ]=useState();
  const [transactionId,setTransactionId]=useState('')
  useEffect(() => {
    // Initialize selected options based on API data
    if (getFinalData?.data) {
      const initialSelectedOptions = filterArray?.map((item) => {
        return item?.servicesAssigned?.length > 0 ? item.servicesAssigned : [];
      });

      filterArray.map((_,index)=>{

        const newSelected = filterArray.servicesAssigned?.map((item) => {
          return {
            serviceId: item?.value,
          };
        });
        const obj = {
          leadId: getFinalData?.data[index]?.leadId,
          services: newSelected,
        };
        setSelectedOption(obj)
      })

      setActualSelectedOption(initialSelectedOptions);
    }
  }, [getFinalData?.data]);

  console.log(actualSelectedOptions,"actual selected option")
  const servicesOption =
    getServices?.data &&
    getServices?.data?.map((item) => {
      return {
        label: item?.serviceName,
        value: item?.serviceId,
      };
    });
  const [assignServices] = useAssignServicesMutation();
  const [addClientTransaction]=useAddClientTransactionMutation();
const [selectedAssignedServices,setSelectedAssignedServices]=useState();
const [selectedImage, setSelectedImage] = useState(null);
const totalPages =getFinalData?.totalPages ;
const handleNext = () => {
  if (pageNumber < totalPages) {
    setPageNumber(pageNumber + 1);
  }
};
const handlePrevious = () => {
  if (pageNumber > 1) {
    setPageNumber(pageNumber - 1);
  }
};

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };

  const handleSelectChange = (selected, index) => {
    // `selected` is an array of selected options or null if no options are selected
    const newSelected = selected?.map((item) => {
      return {
        serviceId: item?.value,
      };
    });
    const obj = {
      leadId: getFinalData?.data[index]?.leadId,
      services: newSelected,
    };
    setSelectedOption(obj);
    const updatedSelectedOptions = [...actualSelectedOptions];
    updatedSelectedOptions[index] = selected; // Update the selected options for the specific item
    setActualSelectedOption(updatedSelectedOptions);
    // setSelectedOption((prev)=>[...prev,selected])
    // console.log('Selected values:', selected,getFinalData?.data[index]?.leadId);
  };

  const handleVave = () => {
    
    assignServices(selectedOption)
      .unwrap()
      .then((res) => {
        refetch();
        toast.success("Services assigned successfully!", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong!", {
          position: "bottom-right",
        });
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Make the background transparent
      border: "1px solid #ccc",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #aaa", // Change border color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(255, 255, 255, 0.9)", // Optional: set a semi-transparent background for the dropdown menu
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgba(0, 123, 255, 0.2)", // Optional: set a background for selected values
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fffff", // Optional: change label color
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#007bff", // Optional: change remove button color
      ":hover": {
        backgroundColor: "rgba(255, 0, 0, 0.1)", // Optional: add hover effect on remove button
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white", // Set the placeholder color to white
      fontSize: "12px",
    }),
  };
  function getGreeting() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 0 && hours < 12) {
      return "Good morning";
    } else if (hours >= 12 && hours < 17) {
      return "Good afternoon";
    } else if (hours >= 17 && hours < 21) {
      return "Good evening";
    } else {
      return "Good night";
    }
  }
  
const handleTransaction =()=>{
  addClientTransaction({transactionId:transactionId,img:imageFile,clientId:selectedClientId}).unwrap().then((res)=>{
    toast.success(res?.message, { position: "bottom-right" });
    refetch();
    setOpenTransactionModel(false)
    // getTaskRefetch();
    refetchFinalLead();
  }).catch((err)=>{
    toast.error(err?.data?.message, { position: "bottom-right" });

  })
}
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);

  return (
    <div className="pt-20 mx-[100px] w-[100%] ">
      {isAddCustomerModalOpen && (
        <AddCustomerForm
          showModal={isAddCustomerModalOpen}
          setShowModal={setIsAddCustomerModalOpen}
          refetch={refetchFinalLead}
        />
      )}
      <div className="text-xl my-8 dark:text-white text-black">
        <h1 className="text-[30px] font-bold">{getGreeting()}, Partner!</h1>
        <p className="text-[18px] font-semibold">Welcome to CFO Dashboard</p>
      </div>
      <div className="relative pb-14 mt-4 bg-gradient-to-r dark:from-[#3396E2BD] from-[#060B28BD] to-[#A0AEC0] p-4 dark:to-[#6679D3B5] rounded-md">
        <div className=" flex flex-row justify-between mb-[16px]">
          <div className="flex flex-col">
            <p className="text-[20px] font-semibold">Customer Details</p>
            <p className="text-[14px] font-semibold">
              These are the list of all the qualified leads converted into
              customers
            </p>
          </div>
          <button
            className="bg-blue-600 px-[10px] py-[15px] rounded-lg text-[15px] font-semibold"
            onClick={() => {setIsAddCustomerModalOpen(true)}}
          >
            {" "}
            Add Customer
          </button>
        </div>
        {!isLoading ? (
          <div>
            <div className="flex justify-between border-b border-[#56577A] p-2">
              {getFinalData?.data &&
                getFinalData?.data[0] &&
                Object.keys(getFinalData?.data[0])
                  ?.map((item) => {
                    if (item !== "leadId" && item !== "clientId" && item!=='isTransaction') {
                      return (
                        <div className="w-[16%] text-sm">
                          {separateCamelCase(item)}
                        </div>
                      );
                    }
                    return null; // Return null or remove this line to exclude the unwanted keys
                  })
                  .filter(Boolean)}
              <div className="w-[16%] text-sm">Action</div>
            </div>
            <div className="flex justify-start min-h-auto max-h-[500px] overflow-auto  flex-col">
              {getFinalData?.data ? (
                filterArray?.map((item, index) => {
                  
                  return (
                    <div className="flex justify-between font-semibold border-b border-[#56577A] items-center p-2">
                      <div className="w-[16%]">{item?.clientName}</div>
                      <div className="w-[16%]">{item?.noOfProperty}</div>
                      <div className="w-[16%]">{item?.budget}</div>
                      <div className="w-[16%]">
                      
                         
                         
                          <Select
                            onChange={(newValue) =>
                              handleSelectChange(newValue, index)
                            }
                            isMulti
                            maxMenuHeight={200}
                            className="w-[90%]"
                            menuPortalTarget={document.querySelector("body")}
                            options={servicesOption}
                            styles={customStyles}
                            value={actualSelectedOptions[index] || []} // Use state for selected options

                          />
                      
                      </div>
                      <div className="w-[16%]">{item?.servicesPitched}</div>
                      <div
                        className="w-[16%] cursor-pointer hover:underline duration-150 ease-in-out transition-all"
                        // onClick={()=>{ setSelectedClientId(item?.clientId); item?.isTransaction? setOpenTransactionModel(true) : handleVave()}}
                      >
                       {/* { item?.isTransaction? 'Enter detail' : 'Assign'} */}

                       <div className="relative w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <Select
                        onChange={(x) => {
                          console.log(x);
                          if (x?.value === "Enter_detail") {
                            setSelectedClientId(item?.clientId)
                            setOpenTransactionModel(true)
                            // navigate(`/client/${item?.clientId}`);
                          } else if(x?.value === 'Assign') {
                            setSelectedClientId(item?.clientId)
                            handleVave()
                            // handleFunction(item?.clientId,'Deactivate')
                          }
                        }}
                        components={{
                          Indicator: () => (
                            <div className="custom-indicator">
                              {/* <BsThreeDotsVertical className="check-icon" /> */}
                            </div>
                          ),
                        }}
                        // components={{
                        //   Option: CustomOption,
                        // }}
                        maxMenuHeight={200}
                        className="w-[90%]"
                        menuPortalTarget={document.querySelector("body")}
                        options={[
                          { value: "Enter_detail", label: "Enter transaction detail" },
                        
                        {value:"Assign",label:'Assign'},
               
                        ].filter(Boolean)}
                        styles={customStyles}
                      />
                    </div>
                      </div>
                    </div>

                  );
                })
              ) : (
                <DataNotFound />
              )}
            </div>


            {(totalPages>1) &&     
      <div className="absolute bottom-2 w-full flex items-center justify-center mt-8">
        <div className="flex justify-center items-center space-x-4 ">
          <button
            onClick={handlePrevious}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === 1
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === 1}
          >
            Previous
          </button>

          <span className="text-lg text-white dark:text-black font-medium">
            Page {pageNumber} of {totalPages}
          </span>

          <button
            onClick={handleNext}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === totalPages
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === totalPages}
          >
            Next
          </button>
        </div>
      </div>
                  
                  
                  }
          </div>
        ) : (
          <div className="w-full h-[400px]">
            <Skeleton />{" "}
          </div>
        )}
      </div>
     {OpenTransactionModel && (
  <UniversalModal
  fixedWidth={true}
    setShowModel={setOpenTransactionModel}
    showSaveButton={true}
    handleSave={handleTransaction}
  >
   <div className="flex flex-col mt-4 items-start space-y-6 text-darkThemeFontColor p-4 min-w-[600px] ">
      <div className='absolute font-semibold mx-2 top-4 left-10'>
        <h1>Enter Last Transaction Detail</h1>
      </div>
      {/* Heading for Transaction ID */}
      <div className="w-full">
        <h3 className="text-lg font-semibold  mb-2">Transaction ID</h3>
        <input
          type="text"
          name="transactionId"
          value={transactionId}
          onChange={(e)=>setTransactionId(e.target.value)}
          placeholder="Enter Transaction ID"
          className="w-full border text-black border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        /> 
      </div>

      {/* Heading for Transaction Proof */}
      <div className="w-full">
        <h3 className="text-lg font-semibold mb-2">Transaction Proof</h3>
        <div className="w-32 h-32 rounded-lg border-2 border-dashed border-gray-300 overflow-hidden flex items-center justify-center relative">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Transaction Proof Preview"
              className="object-cover w-full h-full"
            />
          ) : (
            <label className="w-full h-full flex items-center justify-center cursor-pointer ">
              <span className="flex items-center text-sm justify-center">Upload</span>
              <input
                type="file"
                onChange={handleImageChange}
                accept="image/*"
                className="hidden"
              />
            </label>
          )}
        </div>
        {selectedImage && (
          <button
            onClick={() => setSelectedImage(null)}
            className="mt-2 text-xs underline"
          >
            Remove Image
          </button>
        )}
      </div>
    </div>
  </UniversalModal>
)}

    </div>
  );
};

export default CSO_Dashboard;
