import React from 'react'
import Button from '../button'

const Tablewrap = ({label,children}) => {
  return (
    <div className='w-[100%] h-[100%]  bg-lightThemeGradient dark:bg-darkThemeGradient  p-6  rounded-lg'>
      <div className='w-full flex m-2 items-center justify-between'>

      
        <h1 className='font-semibold'>{label}</h1>
        {/* <div className='flex gap-4 '>

       <Button type = "primary">Sort</Button>
       <Button type = "primary">Filter</Button>
        </div> */}

    
      </div>
      <div className='px-6'>

        {children}
      </div>
    </div>
  )
}

export default Tablewrap
