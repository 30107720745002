import React, { useState } from "react";
import {
  useGetEmployeeActivityQuery,
  useGetPropertyActivityQuery,
  useGetTeamAcitivyQuery,
} from "../redux/slices/dashboard";
import Skeleton from "./Skeleton";
import NormalDropDown from "./DropDown/NormalDropDown";
import {formatDateString} from '../utils/FormatDateString'
const PropertyActivity = ({hId}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize,setPageSize]=useState(10);
  const { data: teamActivityData, isLoading,isFetching } = useGetPropertyActivityQuery({
    pageNumber: pageNumber,
    hId:hId,
    limit:pageSize
  });
  const [selectedValue,setSelectedValue]=useState(10)

  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber < teamActivityData?.data?.totalCount) {
      setPageNumber(pageNumber + 1);
    }
  };
  const totalPages = teamActivityData?.data?.totalPages;
  return (
    <div className="bg-lightThemeGradient mt-2 relative min-h-[500px] dark:bg-darkThemeGradient p-4 rounded-lg">
      <h1>Property Activity</h1>
      <div className="h-[600px] overflow-y-auto ">
        {!(isLoading || isFetching)
          ? teamActivityData?.data?.activityHistoryDetail?.map((item, index) => (
              <div
                key={index}
                className="bg-lightThemeGradient dark:bg-gradient-to-r dark:from-blue-500 dark:to-blue-600  p-4 rounded-md shadow-md text-white flex items-start space-x-4 my-2"
              >
                <div className="flex flex-col items-center">
                  {/* Circle for the timeline */}
                  <div className="w-3 h-3 bg-green-400 rounded-full mt-1.5"></div>
                  {/* Dotted line for timeline */}
                  <div className="h-12 w-0.5 bg-green-400 mt-1"></div>{" "}
                  {/* Adjust height here */}
                </div>
                <div className="flex-1">
                  {/* Main notification text */}
                  <div>{item?.activity}</div>
                  {/* Date and time */}
                  <div className="text-sm text-gray-300 mt-2">{formatDateString(item?.time)}</div>
                </div>
              </div>
            ))
          : Array(6)
              .fill("")
              .map((_, i) => (
                <div key={i} className="h-12 w-full mb-3">
                  <Skeleton />
                </div>
              ))}
      </div>
      <div className="w-full flex items-center justify-end mt-8">
  <div className="flex items-center space-x-6 bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-lg">
    {/* Previous Button */}
    <button
      onClick={handlePrevious}
      className={`px-2 py-1 flex items-center justify-center rounded-full transition-all duration-200 ${
        pageNumber === 1
          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
          : "bg-blue-600 hover:bg-blue-700 text-white"
      }`}
      disabled={pageNumber === 1}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
      </svg>
    </button>

    {/* Page Number Display */}
    <span className="text-lg font-semibold text-gray-800 text-sm dark:text-white">
      Page {pageNumber} of {totalPages}
    </span>

    {/* Next Button */}
    <button
      onClick={handleNext}
      className={`px-2 py-1 flex items-center justify-center rounded-full transition-all duration-200 ${
        pageNumber === totalPages
          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
          : "bg-blue-600 hover:bg-blue-700 text-white"
      }`}
      disabled={pageNumber === totalPages}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
      </svg>
    </button>

    {/* Page Input with Arrow Buttons */}
    <div className="flex items-center space-x-2">
      <p className="text-sm font-medium text-gray-700 dark:text-gray-300">set page size:</p>
      <button
        onClick={() => setPageSize((prev) =>prev>0 && prev-1)}
        className="px-2 py-1 rounded-l-md bg-blue-600 hover:bg-blue-700 text-white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <input
        type="text"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        className="w-12 px-2 py-1 text-center bg-transparent border border-gray-300 rounded-sm text-gray-700 dark:text-white"
      />
      <button
        onClick={() => setPageSize((prev) => prev+1)}
        className="px-2 py-1 rounded-r-md bg-blue-600 hover:bg-blue-700 text-white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  </div>
</div>

      {/* </div> */}
    </div>
  );
};

export default PropertyActivity;
