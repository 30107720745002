import React, { useEffect, useState } from 'react'
import { CiGrid42 } from 'react-icons/ci'
import { FaListUl } from 'react-icons/fa6'
import UniversalTable from "../../component/Table/UniversalTable";
import AddTask from '../../component/Modal/AddTask';
import { useGetTaskTypeDetailQuery, useGetTaskTypeQuery } from '../../redux/slices/canabel';
import { useLocation } from 'react-router-dom';
// import UniversalTable from '../../'

const SettingPage = () => {
    const [showAddTask,setShowAddTask]=useState(false);
    const [showEditTask,setShowEditTask]=useState(false);
    const [selectedTaskTypeId,setSelectedTaskTypeId]=useState();
    const {data, refetch}=useGetTaskTypeQuery();
    const location = useLocation()
    // const {data:taskTypeData}=useGetTaskTypeDetailQuery()
    // const {showAddModal} = location?.state
const [selectedScreen,setSelectedScreen]=useState('taskType')
    useEffect(()=>{
      if(location?.state?.showAddModal){
        setShowAddTask(true)
      }
    },[location?.state?.showAddModal])
    const handleShowEditTaskPage =(id)=>{
      setSelectedTaskTypeId(id);
      setShowEditTask(true)
    }
  return (
    <div className="flex w-[90%] mx-auto  h-screen overflow-y-auto flex-col p-4">
        {showAddTask && <AddTask refetch={refetch} setShowAddTask={setShowAddTask} />}
        {showEditTask && <AddTask  isEdit={true} selectedTaskTypeId={selectedTaskTypeId} refetch={refetch} setShowAddTask={setShowEditTask} />}
        <div className="w-full h-16 flex mt-20 items-center  justify-between border-y-2 dark:border-white border-black">
        <div className="left flex gap-2 py-4  items-center">
                <div className="flex p-2 text-sm items-center border-r-2 dark:border-white border-black gap-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
                  Task Management
                </div>
                <div
                  onClick={() => setSelectedScreen("taskType")}
                  className={`flex ${selectedScreen === "taskType" ? "dark:bg-white dark:text-black bg-black/40 text-white"  : "text-lightThemeFontColor dark:text-darkThemeFontColor"} rounded-lg p-2 text-sm cursor-pointer items-center  gap-2 `}
                >
                  Task Type
                </div>
                <div
                  onClick={() => setSelectedScreen("taskDesignation")}
                  className={`flex ${selectedScreen === "taskDesignation" ? "dark:bg-white dark:text-black bg-black/40 text-white" : "text-lightThemeFontColor dark:text-darkThemeFontColor"} rounded-lg p-2 text-sm cursor-pointer items-center  gap-2 `}
                >
                  
                  
                  Task Designation
                </div>
                <div
                  onClick={() => setSelectedScreen("CR")}
                  className={`flex ${selectedScreen === "CR" ? "dark:bg-white dark:text-black bg-black/40 text-white" : "text-lightThemeFontColor dark:text-darkThemeFontColor"} rounded-lg p-2 text-sm cursor-pointer items-center  gap-2 `}
                >
                  
                  
                  CR
                </div>
                <div
                  onClick={() => setSelectedScreen("account")}
                  className={`flex ${selectedScreen === "account" ? "dark:bg-white dark:text-black bg-black/40 text-white" : "text-lightThemeFontColor dark:text-darkThemeFontColor"} rounded-lg p-2 text-sm cursor-pointer items-center  gap-2 `}
                >
                  
                  
                  Account
                </div>
        </div>
      </div>
      <div className='bg-lightThemeGradient dark:bg-darkThemeGradient  rounded-lg p-4 mt-4'>
        <div className='flex  justify-between'> 
            <h1>Task Type</h1>

            <div  className="flex gap-4 ">
            <div className='cursor-pointer px-2 py-1 text-sm bg-white text-black rounded-md' onClick={()=>{setShowAddTask(true)}}>Add Task</div>
{/* 
                <div>Filter</div>
                <div>Sort </div> */}
            </div>

            
        </div>
        <div className='w-[98%]  p-4'>

      <UniversalTable dataArray={data?.data} handleFunction={handleShowEditTaskPage}  showActions='compset'/>
        </div>
      </div>
    </div>
  )
}

export default SettingPage

