import React, { useEffect, useState } from "react";
import Button from "../../component/button";
import ReusableDropDown from "../../component/DropDown/DropDown";
import CustomCheckBox from "../../component/CheckBox/customCheckBox";
import ReusableInput from "../../component/InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import RevensMessage from "../../component/RevensMessage/RevensMessage";
import {
  useAddScenarioByUserMutation,
  useGetScenarioFrequencyQuery,
  useUpdateFrequencyMutation,
} from "../../redux/slices/onBoarding";
import { navigate } from "@storybook/addon-links";
import { useNavigate } from "react-router-dom";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import { toast } from "react-toastify";
const Scenario = ({
  senarioDetail,
  isSetting,
  subCategoryId,
  categoryId,
  subCategoryArray,
  setSubCategoryId,
  refetchCategoryData,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const { data: frequencyData, isLoading: frequencyLoading } =
    useGetScenarioFrequencyQuery(
      { scenarioId: senarioDetail?.data?.scenarioId },
      { skip: !senarioDetail?.data?.scenarioId }
    );

  const [screen, setScreen] = useState(1);
  const [frequencyType, setFrequnecyType] = useState();
  const [frequencyNumber, setFrequencyNumber] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState(
    frequencyData?.data
  );
  const [revensMessage, setRevensMessage] = useState();
  const [addScenarioByUser] = useAddScenarioByUserMutation();
  const [updateFrequency] = useUpdateFrequencyMutation();
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    switch (screen) {
      case 1:
        setRevensMessage(senarioDetail?.data?.scenarioData?.revensMessage);
        break;
      case 2:
        setRevensMessage(senarioDetail?.data?.scenarioFrequency?.revensMessage);
        break;
      case 3:
        setRevensMessage(senarioDetail?.data?.whatsAppMessage);
        break;
      default:
        setRevensMessage("Default message");
    }
  }, [screen, senarioDetail]);
  const handleClick = () => {
    if (screen < 3) {
      if (screen == 1) {
        setIsLoading(true);
        addScenarioByUser({ scenarioId: senarioDetail?.data?.scenarioId })
          .unwrap()
          .then(() => {
            setIsLoading(false);
            toast.success("Scenario Added", { position: "bottom-right" });
          })
          .catch(() => {
            // toast.error("some error", { position: "bottom-right" });
            setIsLoading(false);
          });
        // refetchCategoryData();
      } else if (screen == 2) {
        setIsLoading(true);

        updateFrequency({
          scenarioId: senarioDetail?.data?.scenarioId,
          frequency: selectedFrequency,
          frequencyType: frequencyType,
          frequencyNumber: frequencyNumber,
        })
          .unwrap()
          .then(() => {
            setIsLoading(false);
            toast.success("Frequency Added", { position: "bottom-right" });
            setIsLoading(false);
          })
          .catch(() => {
            // toast.error("some error", { position: "bottom-right" });
            setIsLoading(false);
          });
        // refetchCategoryData()
      }
      setScreen((prev) => prev + 1);
    } else if (screen === 3) {
      const currentIndex = subCategoryArray.indexOf(subCategoryId);
      refetchCategoryData();
      // Set the next value in subCategoryId
      if (currentIndex < subCategoryArray.length - 1) {
        setSubCategoryId(subCategoryArray[currentIndex + 1]);
      } else {
        if (subCategoryArray?.length > 1) {
          setSubCategoryId(subCategoryArray[0]);
        } else {
          navigate("/add-team-members");
        }
      }
    }
  };

  const handleSkip = () => {
    const currentIndex = subCategoryArray.indexOf(subCategoryId);
    refetchCategoryData();
    // Set the next value in subCategoryId
    if (currentIndex < subCategoryArray?.length - 1) {
      setSubCategoryId(subCategoryArray[currentIndex + 1]);
    } else {
      if (subCategoryArray?.length > 1) {
        setSubCategoryId(subCategoryArray[0]);
      } else {
        // navigate('/add-team-members')
      }
    }
  };
  const onChangeCheckbox = (label, e) => {
    setFrequnecyType(label);
  };

  useEffect(() => {
    setSelectedFrequency(frequencyData?.data);
  }, [frequencyData]);

  useEffect(() => {
    setScreen(1);
  }, [categoryId, subCategoryId]);
  
  function generateNotificationStatement(frequencyType, frequencyNumber) {
    if (frequencyNumber < 1 || frequencyNumber > 5) {
      return "Invalid frequency number. Please enter a number between 1 and 5.";
    }

    const timeSlots = ["06:00", "12:00", "18:00", "09:00", "15:00"];
    const weekDays = ["Monday", "Wednesday", "Friday"];
    const monthDays = [1, 8, 15, 22, 29];

    let statement = "";

    switch (frequencyType) {
      case "Daily":
        statement = `You'll receive notification ${frequencyNumber} times a day at ${timeSlots.slice(0, frequencyNumber).join(", ")} time.`;
        break;
      case "Weekly":
        statement = `You'll receive notification ${frequencyNumber} times a week on ${weekDays.slice(0, frequencyNumber).join(", ")}.`;
        break;
      case "Fortnightly":
        statement = `You'll receive notification ${frequencyNumber} times every two weeks on days ${monthDays.slice(0, frequencyNumber).join(", ")}.`;
        break;
      case "Monthly":
        statement = `You'll receive notification ${frequencyNumber} times a month on days ${monthDays.slice(0, frequencyNumber).join(", ")}.`;
        break;
      default:
        statement = "Invalid frequency type.";
    }

    return statement;
  }

  
  return (
    <div className={`w-full p-4 flex flex-col h-full gap-2 ${isSetting &&"bg-darkThemeGradient"  }`}>
      <div className="skipForNow flex justify-end w-full">
        <div className="w-fit">
         {!isSetting && <Button
            onClick={() => {
              navigate("/add-team-members");
            }}
          >
            Skip To Dashboard
          </Button>}
        </div>
      </div>
      <div className="flex flex-col justify-between h-[80%]">
        <div>
      {screen == 1 && (
        <div className="  dark:text-darkThemeFontColor text-darkThemeFontColor">
          <div className="h-[250px] w-full  rounded">
            <img
              src={senarioDetail?.data?.scenarioData?.image}
              alt="image could not be loaded"
              className="object-contain h-full w-full"
            />{" "}
          </div>
          <div className="my-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
            <h1 className="text-lg">
              {senarioDetail?.data?.scenarioData?.heading}
            </h1>
            <p className="text-sm">
              {senarioDetail?.data?.scenarioData?.description}
            </p>
          </div>
        </div>
      )}
      {screen == 2 && (

        <div className="  text-lightThemeFontColor">
          {senarioDetail?.data?.scenarioFrequency?.frequency.length ==0 ? <div className='w-full h-full flex items-center justify-center' > No data found</div>:<>
          <h1 className="text-3xl font-semibold">Frequency of alerts</h1>
          <p className="my-2 text-xm">
            What should be frequency of this alert be
          </p>
          {senarioDetail?.data?.scenarioFrequency?.frequency?.map(
            (freq, index) => (
              <div className="flex gap-2" key={index}>
                <input
                  type="radio"
                  id={freq?._id}
                  name="frequency"
                  value={freq?.type}
                  onChange={(e) => onChangeCheckbox(freq?.type, e)}
                  checked={frequencyType === freq.type}
                />
                <label htmlFor={freq?._id}>{freq?.type}</label>
              </div>
            )
          )}

          <input
            type="text"
            name="frequency"
            placeholder="Enter Frequency"
            value={frequencyNumber}
            onChange={(e) => setFrequencyNumber(e.target.value)}
            className=" mt-3 font-sans text-sm font-normal w-full border border-solid border-[#d6d9de] box-border rounded-[9px] pr-5 h-12 pl-2 text-[#2a2e34]"
          />
          {
            frequencyNumber && frequencyType &&
            <>
              <div className="flex flex-row">
                <p className="font-semibold pr-4">Note:</p>
                <p className="">{generateNotificationStatement(frequencyType,frequencyNumber)}</p>
              </div>
            </>
          }</>}
        </div>
      )}
      {screen === 3 && (
        <div className="h-[90%] w-fit">
          <div className="h-[70%] w-[400px] ">
            
          {senarioDetail?.data?.whatsAppMessage ?<img
              src={senarioDetail?.data?.whatsAppMessage}
              className="h-[100%] w-full object-contain"
              alt="image could not be loaded"
            />: <div className='w-full h-full flex items-center justify-center' > No data found</div>
            }
          </div>
        </div>
      )}
      </div>
      <div>
      {(screen == 1 || screen == 2) && (
        <div className="  py-4  px-4 w-[100%] md:w-[90%] relative h-auto md:h-auto rounded-lg flex  bottom-0 justify-start items-center bg-RGreen text-white gap-2">
          <div className="w-[800px] flex flex-wrap ">
            <RevensMessage message={revensMessage} />
          </div>
        </div>
      )}
      </div>
      </div>

      <div className="absolute bottom-2 flex  justify-end gap-2">
        <Button  onClick={handleClick}>
          {screen == 1
            ? "Add to Dashboard"
            : screen == 2
              ? "Check on Whatsapp"
              : "Continue"}
        </Button>
        <Button onClick={handleSkip} type="light">
          Skip for now
        </Button>
      </div>
    </div>
  );
};

export default Scenario;
