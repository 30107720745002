import React from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import ReusableInput from '../InputField/ReusableInput';

const HotelManaged = ({finalData ,setFinalData}) => {
    const methods = useForm();
 
  return (
    <div className="w-[100%]  text-lightThemeFontColor dark:text-darkThemeFontColor  h-[100%]">
    <div>
      <h1 className="text-3xl font-semibold text-black">Number of Hotel Managed</h1>
      <p className="text-[#96A0AD] my-2">
       The size and scope of your hotel portfolio play a crucial role in how R-World software can benefit your agency
      </p>
    </div>
    <div>
   
          <ReusableInput
            label="Number of Hotel You manage"
            name="managedHotel"
            type="number"
            placeholder="Enter the approx number of hotel you manage"
            className='  placeholder:text-darkThemeFontColor dark:placeholder:text-lightThemeFontColor   text-lightThemeFontColor dark:text-darkThemeFontColor '
            lableClass=' text-lightThemeFontColor dark:text-darkThemeFontColor text-lightThemeFontColor dark:text-darkThemeFontColor  '
            rules={{ required: "Hotel Manage is required" }}
            min={1}
          />  

    </div>
  </div>
  )
}

export default HotelManaged
