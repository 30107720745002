import React, { useEffect, useState } from "react";
import "../Loader/UniversalLoader.css";
import { IoArrowBackSharp, IoClose } from "react-icons/io5";
import ProgressBar from "../ProgressBar/progressBar";
import { CgProfile } from "react-icons/cg";
import CommentComponent from "../CommentComponent";
import TaskTab from "../TaskTable";
import TaskCompletionComponent from "../TaskCompletion";
import {
  useGetTaskDetailQuery,
  usePatchTaskStatusMutation,
  usePostImageMutation,
  useRemmoveImageMutation,
} from "../../redux/slices/canabel";
import { FaRegFileLines } from "react-icons/fa6";
import { toast } from "react-toastify";
import Skeleton from "../Skeleton";
import { useSwitchToRatexQuery } from "../../redux/slices/onBoarding";
import { BsUpload } from "react-icons/bs";

const TrueFalseModal = ({
  setShowModal,
  taskScreen,
  setTaskScreen,
  functionCall,
  taskId,
  mode,
  setTaskId,
}) => {
  const { data: switchToRate } = useSwitchToRatexQuery();
  const [imageURL, setImageURL] = useState([]);
  const [postImage] = usePostImageMutation();
  const [selectedTask, setSelectedTask] = useState();
  const [tempMode, setTempMode] = useState();
  const [removeImage] = useRemmoveImageMutation();
  const [keyInsights, setKeyInsights] = useState();
  const [challanges, setChallanges] = useState();
  const [suggestion, setSuggestion] = useState();
  const [patchTaskStatus] = usePatchTaskStatusMutation();

  const handleOK = () => {
    setShowModal(false);
    patchTaskStatus({
      keyInsights,
      challengedFaced: challanges,
      suggestions: suggestion,
    });
    if (functionCall) {
      functionCall(); // Call the function if it's defined
    }
  };

  const handleRemoveImage = (taskId, attachmentId) => {
    removeImage({ taskId, attachmentId })
      .unwrap()
      .then((res) => {
        setImageURL((prevImages) =>
          prevImages.filter((img) => img.attachmentId !== attachmentId)
        ); // Assuming setImageURL is your state updater
        toast.success(res?.message, { position: "bottom-right" });
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
  useEffect(() => {
    if (taskScreen == 1) {
      setTempMode("propertyMode");
    } else if (taskScreen == 2) {
      setTempMode("taskMode");
    } else if (taskScreen == 3) {
      setTempMode("subTaskMode");
    }
  }, [tempMode, taskScreen]);
  const { data: taskDetailData, refetch } = useGetTaskDetailQuery({
    id: taskId,
    type: tempMode,
  });
  useEffect(() => {
    setImageURL(taskDetailData?.data?.attachments);
  }, [taskDetailData?.data]);

  const handleNavigate = (reportLink) => {
    const token = switchToRate?.data?.data?.token;
    const hId = localStorage.getItem("hId") || 790755;
    if (token) {
      // window.open(
      //   `https://ratex.retvenslabs.com/ratex/token:${token}/hId:${parseInt(hId)}/report:${reportLink}`,
      //   "_blank"
      // );
      window.open(
        `http://localhost:3000/ratex/token:${token}/hId:${parseInt(hId)}/report:${reportLink}`,
        "_blank"
      );
    } else {
      toast.error("Token not received from switch to rate.", {
        position: "bottom-right",
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 h-[100%]   bg-opacity-50 flex justify-center items-center">
      <div className=" dark:bg-blue-500 bg-gray-700 w-full max-w-[1160px] h-[80%] overflow-y-auto text-darkThemeFontColor rounded-lg shadow-md">
        <div className="z-50 dark:bg-blue-500 bg-gray-700 sticky top-0 w-full flex justify-center py-4">
          <div
            onClick={() => setShowModal(false)}
            className="absolute top-4 left-4 cursor-pointer text-white text-2xl"
          >
            <IoClose />
          </div>

          <h1 className="text-xl font-semibold">
            {taskScreen === 1
              ? "Property Daily Task"
              : taskScreen === 2
                ? "Sub Task"
                : "Sub Task Detail"}
          </h1>

          {taskScreen === 3 && (
            <h1
              className="text-sm absolute right-4 cursor-pointer"
              onClick={() => setTaskScreen((prev) => prev + 1)}
            >
              Mark As Completed
            </h1>
          )}
        </div>

        {taskScreen === 4 ? (
          <TaskCompletionComponent
            subTaskData={taskDetailData?.data}
            handleClose={() => setShowModal(false)}
            handleOK={handleOK}
            keyInsights={keyInsights}
            setKeyInsights={setKeyInsights}
            challanges={challanges}
            setChallanges={setChallanges}
            suggestion={suggestion}
            setSuggestion={setSuggestion}
            taskId={taskId}
          />
        ) : (
          <div className="p-6 pt-0">
            <div className="mb-4 pb-2 z-50 dark:bg-blue-500 bg-gray-700 sticky top-[60px]">
              <h1 className="mb-4 text-lg font-semibold flex gap-2 items-center">
                {taskDetailData?.data?.title}
              </h1>

              <div className="flex w-full flex-wrap">
                <div className="w-1/2 flex gap-2">
                  <h1 className="font-semibold">Priority:</h1>
                  <h2>{taskDetailData?.data?.priority}</h2>
                </div>
                <div className="w-1/2 flex gap-2">
                  <h1 className="font-semibold">Created By:</h1>
                  <h2>Auto Generated</h2>
                </div>
                <div className="w-1/2 flex gap-2">
                  <h1 className="font-semibold">Due Time:</h1>
                  <h2>{taskDetailData?.data?.dueTime}</h2>
                </div>
                <div className="w-1/2 flex gap-2">
                  <h1 className="font-semibold">Type:</h1>
                  <div className="flex flex-wrap gap-2">
                    {taskDetailData?.data?.type?.map((item, index) => (
                      <div
                        key={index}
                        className="bg-[#fffde0] text-[#a37a00] rounded-lg px-2"
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full gap-4 my-4">
                <h1 className="font-semibold">Tags:</h1>
                <div className="flex flex-wrap gap-2">
                  {taskDetailData?.data?.tags?.map((item, index) => (
                    <div
                      key={index}
                      className="bg-[#fffde0] text-[#a37a00] rounded-lg px-2"
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-full my-4">
                <div className="flex gap-2">
                  <h1>Progress:</h1>
                  <h1>Task is {taskDetailData?.data?.progress}% completed</h1>
                </div>
                <ProgressBar progress={taskDetailData?.data?.progress} />
              </div>
            </div>
<div className="my-4">
       {/* TaskTab Component */}
       {taskScreen === 1 ? (
              <div className="h-60 w-full">
                {taskDetailData?.data?.tasks ? (
                  <TaskTab
                    isTask={true}
                    setSelectedTask={setTaskId}
                    setTaskScreen={setTaskScreen}
                    dataArray={taskDetailData?.data?.tasks}
                  />
                ) : (
                  <Skeleton />
                )}
              </div>
            ) : taskScreen === 2 ? (
              <div className="h-60 w-full">
                {taskDetailData?.data?.tasks ? (
                  <TaskTab
                    setTaskScreen={setTaskScreen}
                    setSelectedTask={setTaskId}
                    dataArray={taskDetailData?.data?.tasks}
                  />
                ) : (
                  <Skeleton />
                )}
              </div>
            ) : taskScreen === 3 ? (
              <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg p-4 w-full text-white">
                <h3 className="font-semibold mb-2">Actions to be performed</h3>
                <ul>
                  <li className="flex justify-between items-center">
                    <span>• Check Pace Report</span>
                    <div
                      onClick={() =>
                        handleNavigate("Report_TemplateReport_parep")
                      }
                      className="text-white cursor-pointer"
                    >
                      Check here
                    </div>
                  </li>
                  <li className="flex justify-between items-center mt-2">
                    <span>• Check Revenue Report</span>
                    <div
                      onClick={() =>
                        handleNavigate("Report_TemplateReport_revrep")
                      }
                      className="text-white cursor-pointer"
                    >
                      Check here
                    </div>
                  </li>
                </ul>
              </div>
            ) : null}
</div>
       

            <h1 className="font-semibold">Attachment</h1>
            <div className="flex py-2 flex-wrap gap-4">
              {imageURL &&
                imageURL.map((item) => (
                  <div
                    key={item.attachmentId}
                    className="relative w-14 h-14 rounded-full border"
                  >
                    {item?.docType?.includes("image") ? (
                      <>
                        {!tempMode == "propertyMode" && (
                          <div className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full cursor-pointer hover:bg-red-700 transition-transform duration-300 transform hover:scale-110 w-6 h-6 flex items-center justify-center">
                            <span
                              onClick={() =>
                                handleRemoveImage(taskId, item?.attachmentId)
                              }
                              className="text-xs font-bold"
                            >
                              x
                            </span>
                          </div>
                        )}
                        <img
                          className="w-full h-full object-cover rounded-full"
                          src={
                            item?.doc ||
                            "https://cdn.pixabay.com/photo/2021/08/30/22/33/warning-6587278_1280.png"
                          }
                          alt="Uploaded"
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop
                            e.target.src =
                              "https://cdn.pixabay.com/photo/2021/08/30/22/33/warning-6587278_1280.png"; // Fallback URL
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {!tempMode == "propertyMode" && (
                          <div className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full cursor-pointer hover:bg-red-700 transition-transform duration-300 transform hover:scale-110 w-6 h-6 flex items-center justify-center">
                            <span
                              onClick={() =>
                                handleRemoveImage(taskId, item?.attachmentId)
                              }
                              className="text-xs font-bold"
                            >
                              x
                            </span>
                          </div>
                        )}
                        <div className="w-full h-full flex items-center justify-center">
                          <FaRegFileLines size={40} className="text-gray-500" />
                        </div>
                      </>
                    )}
                  </div>
                ))}

              <div className=" w-32 h-12 rounded-lg border-dashed border overflow-hidden">
                <label className="w-full h-full  flex items-center justify-center cursor-pointer">
                  <div className="flex items-center text-sm justify-between px-4 w-full h-full text-white">
                    <BsUpload size={24} />
                    Upload
                  </div>
                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const formData = new FormData();
                      formData.append("taskAttachment", file);
                      formData.append("docType", file?.type);
                      postImage({
                        taskAttachment: file,
                        docType: file.type,
                        taskId: taskId,
                      })
                        .unwrap()
                        .then((res) => {
                          setImageURL((prev) => [
                            ...prev,
                            {
                              docType: res?.data?.docType,
                              doc: res?.data?.doc,
                              attachmentId: res?.data?.attachmentId,
                            },
                          ]);
                        })
                        .catch((err) => {
                          toast.error(err?.data?.message, {
                            position: "bottom-right",
                          });
                        });
                    }}
                    accept="*"
                    className="hidden"
                  />
                </label>
              </div>
            </div>

            <CommentComponent
              commentsData={taskDetailData?.data?.taskComments}
              descriptionData={taskDetailData?.data?.description}
              activityHistory={taskDetailData?.data?.activityHistory}
              taskId={taskId}
              refetch={refetch}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrueFalseModal;
