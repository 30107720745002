import getToken from "../../../utils/getToken";
import { emptySplitApi } from "../../injectEndpoint";

export const CSO = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFinalLead: builder.query({
      query: (data) => ({
        url: `lead/getFinalLead`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params:data
      }),
      invalidatesTags: ["getFinalLead"],
    }),
    getServices: builder.query({
      query: () => ({
        url: `/userRoutes/getServices`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getServices"],
    }),
    assignServices: builder.mutation({
      query: (data) => ({
        url: `/lead/assignServices`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      providesTags: ["assignServices"],
    }),
  }),
  overrideExisting: false, // Add
});
export const { useGetFinalLeadQuery,useGetServicesQuery,useAssignServicesMutation } = CSO; // Corrected export
