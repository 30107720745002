import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Button from "../../component/button";
import ProfilePicture from "../../assets/icons/Revens/ProfilePicture.png";
import UserProfileWrapper from "../../component/WrapperComponents/UserProfileWrapper";
import {
  useGetClientDetailQuery,
  useGetUserDetailQuery,
} from "../../redux/slices/Setting";
import AddClient from "../AllClients/AddClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../component/Layout/Header";
import SideBar from "../../component/Layout/SideBar";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import Properties from "../../component/DashboardScreen/Properties";
import PropertyRevenueBreakdown from "../../component/ApexGraphComponent/PropertyRevenueBreakdown";
import OTATable from "../../component/DashboardScreen/OTATable";
import { formatDateString } from "../../utils/FormatDateString";
import { IoArrowBackSharp } from "react-icons/io5";

const ClientDetail = () => {
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const {
    data: clientDetail,
    refetch: getClientRefetch,
    isLoading,
    isFetching,
  } = useGetClientDetailQuery({ clientId: hotelId });
  const [clientData, setClientData] = useState();
  const [showAddClient, setShowAddClient] = useState(false);

  const [clientId, setClientId]  = useState(hotelId || "")

  useEffect(() => {
    setClientData(clientDetail?.data);
  }, [clientDetail]);

  return (
    <>
      <UniversalLoader loading={isLoading || isFetching} />

      <div className="p-6 w-full  h-auto ">
        {!showAddClient ? (
          <div className="h-auto  text-lightThemeFontColor dark:text-darkThemeFontColor mt-20 pl-20 w-full">
            <div className="flex flex-row w-[100%] justify-between items-center ">
              <div onClick={()=>{
                navigate(-1)
              }}  className='p-2 cursor-pointer '> <IoArrowBackSharp />
              </div>
              <div className="w-full text-[24px] font-semibold"> Client Overview</div>
              <div
                className="cursor-pointer text-white  bg-blue-700 whitespace-nowrap px-[10px] py-[5px] rounded-md"
                onClick={() => {
                  setShowAddClient(true);
                }}
              >
                Edit client{" "}
              </div>
            </div>
            <div className="flex mt-8 w-full  gap-2 justify-between h-full">
              <div className="flex  bg-lightThemeGradient dark:bg-darkThemeGradient text-darkThemeFontColor p-4  w-[70%] rounded-lg gap-4  ">
                <UserProfileWrapper label={"Client Overview"}>
                  <div className=" flex w-full gap-10 justify-between">
                    <div className="w-full flex ">
                      <div className=" w-[90%]  flex   justify-between">
                        <div className="flex gap-4 flex-col justify-between ">
                          <div className=" ">
                            <h1 className="font-bold">Client Name</h1>
                            <p>{clientData?.clientName}</p>
                          </div>
                          <div className="">
                            <h1 className="font-bold ">Total Properties </h1>
                            <p>{clientData?.totalProperty}</p>
                          </div>
                          <div className=" ">
                            <h1 className=" font-bold ">Mobile Number </h1>
                            <p>{clientData?.mobileNumber}</p>
                          </div>
                          <div className=" ">
                            <h1 className="  font-bold">
                              Point Of Contact 1 (POC){" "}
                            </h1>
                            <p>{clientData?.pointOfContact?.pointOfContact}</p>
                          </div>
                          <div className="">
                            <h1 className="  font-bold">POC 1 Email </h1>
                            <p>{clientData?.pointOfContact?.email}</p>
                          </div>
                          <div className="">
                            <h1 className="  font-bold">Current Address</h1>
                            <p>{clientData?.pointOfContact?.address}</p>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4  ">
                          <div className="  ">
                            <h1 className="font-bold">Onboarding Date </h1>
                            <div className="">{formatDateString(clientData?.onBordingDate)}</div>
                          </div>
                          <div className=" ">
                            <h1 className="font-bold">Location </h1>
                            <p>{clientData?.location}</p>
                          </div>
                          <div className=" ">
                            <h1 className="font-bold">Email </h1>
                            <p>{clientData?.email}</p>
                          </div>
                          <div className=" ">
                            <h1 className="font-bold">POC 1 Designation </h1>
                            <p>{clientData?.pointOfContact?.designation}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </UserProfileWrapper>
              </div>
              <div className="w-[30%] rounded-lg bg-lightThemeGradient dark:bg-darkThemeGradient text-darkThemeFontColor ">
                <UserProfileWrapper label={"Client Detail"}>
                  <div className="flex flex-col mt-2 ">
                    <div className="w-1/2 mt-2">
                      <h1 className=" font-semibold">GST Detail</h1>
                      <p className="">{clientData?.propertyDetails[0] && clientData?.propertyDetails[0]?.gstDetail	}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className=" font-bold">GSTIN Number</h1>
                      <p className="">{clientData?.propertyDetails[0] && clientData?.propertyDetails[0]?.gstNumber		}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="font-bold">PAN CARD</h1>
                      <p className="">{clientData?.propertyDetails[0] && clientData?.propertyDetails[0]?.panCard	}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="font-bold">Current Address </h1>
                      <p className="font-semibold">
                        {clientData?.pointOfContact?.address}
                      </p>
                    </div>
                  </div>
                </UserProfileWrapper>
              </div>
            </div>
            <div className="flex mt-8 w-full   gap-2 justify-between h-full">
              <div className="mt-2 w-[70%]">
                <Properties  propertyforClient={true}  hotelId={hotelId} />
              </div>
              <div className="mt-2 w-[30%]">
                <PropertyRevenueBreakdown 
                clientId= {clientId}
                />
              </div>
            </div>
            {/* <div className="mt-2 w-[70%]">
          <PropertyRevenueBreakdown/>
          </div> */}
            {/* <div className="mt-2 w-[70%]">
              <OTATable />
            </div> */}
          </div>
        ) : (
          <div className="h-auto mt-20 pl-20 w-full">
            <AddClient
              clientReload={getClientRefetch}
              data={clientData}
              setShowAddClient={setShowAddClient}
              editClients={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ClientDetail;