import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import AutoSearch from "./autoComplete";

const MapComponent = ({
  version,
  libraries,
  log,
  setLog,
  lat,
  setLat,
  address,
  setAddress,
  state,
  setState,
  country,
  setCountry,
  city,
  setCity,
  postalCode,
  setPostalCode,
  isBgWhite = false,
}) => {
  const mapStyles = {
    height: "220px",
    width: "100%",
  };
  const [map, setMap] = useState(null);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 22.7457114,
    lng: 75.89214199999999,
  });
  const apiKey = "AIzaSyBRAnfSXzM-fQXpa751GkbMQDEuavUSDP0";
  const [value, setValue] = useState(address ||"");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    version,
    libraries,
  });

  useEffect(() => {
    if (lat && log) {
      setDefaultCenter({ lat:lat, lng: log });
    }
  }, [lat, log]);

  const handlePlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setDefaultCenter({ lat, lng });
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={defaultCenter}
        zoom={13}
        onLoad={(map) => setMap(map)}
      ></GoogleMap>
      <AutoSearch
        address={address}
        placeholder={"Search for location"}
        setValue={setValue}
        type={"text"}
        setLat={setLat}
        setCity={setCity}
        setLog={setLog}
        id={'map'}
        label={"Search for location"}
        setState={setState}
        state={state}
        setCountry={setCountry}
        country={country}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        className={`  w-full h-12  
         peer  border-b-[#E6ECF5] border-b ${isBgWhite ? 'text-black' : 'text-white'}  focus-visible:outline-none`}
         isBgWhite = {isBgWhite}
        setAddress={setAddress}
      />
    </div>
  );
};

export default MapComponent;
