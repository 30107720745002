// import { emptySplitApi } from "../../injectEndpoint";
// import { get } from "react-hook-form";
import getToken from "../../utils/getToken";
import { emptySplitApi } from "../injectEndpoint";

export const setting = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addClient: builder.mutation({
      query: (data) => ({
        url: `client/addClientDetail`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["addClient"],
    }),
    addEmployee: builder.mutation({
      query: (data) => ({
        url: `settings/user/addUser`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["addEmployee"],
    }),
    getClient: builder.query({
      query: (data) => ({
        url: `settings/client/getClientsList`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["getClient"],
    }),
    getUsersList: builder.query({
      query: (data) => ({
        url: `settings/user/getUsersList`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["getUsersList"],
    }),
    getClientDetail: builder.query({
      query: (data) => ({
        url: `client/getClientDetail`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getClientDetail"],
    }),
    getUserDetail: builder.query({
      query: (data) => ({
        url: `settings/user/getUserDetail`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getUserDetail"],
    }),
    updateClient: builder.mutation({
      query: (data) => ({
        url: `client/editClientDetail`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data?.propertyDetails || {isActive :data?.isActive},
        params: { clientId: data?.propertyCode },
      }),
      invalidatesTags: ["updateClient"],
    }),
    getDepartment: builder.query({
      query: (data) => ({
        url: `userRoutes/getDepartment`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["getDepartment"],
    }),
    getUserDropDown: builder.query({
      query: (data) => ({
        url: `settings/user/getUserDropdown`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["getUserDropDown"],
    }),
    getPropertyDetail: builder.query({
      query: (data) => ({
        url: `properties/getPropertyDetail`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getPropertyDetail"],
    }),
    getPropertyAssign: builder.query({
      query: (data) => ({
        url: `properties/getPropertyAssign`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getPropertyAssign"],
    }),
    postPropertyAssign:builder.mutation({
        query: (data) => ({
          url: `settings/user/propertyAssign`,
          method: "POST",
          headers: {
            Authorization: getToken(),
          },
          body: data,
        }),
        invalidatesTags: ["postPropertyAssign"],
   
    }),
    getUserList: builder.query({
        query: (data) => ({
          url: `utils/getUserList`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
          params: data,
        }),
        invalidatesTags: ["getUserList"],
      }),
     getPropertyUserList:builder.query({
        query:(data)=>({
          url: `utils/getPropertyUserList`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
          params: data,
        }),
        invalidatesTags: ["getPropertyUserList"],
      }),
    getClientsList:builder.query({
      query:(data)=>({
        url: `utils/clientList`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getClientsList"],
    })
  }),
  overrideExisting: false, // Add
});
export const {
  useAddClientMutation,
  useAddEmployeeMutation,
  useGetClientQuery,
  useGetUsersListQuery,
  useGetClientDetailQuery,
  useGetUserDetailQuery,
  useUpdateClientMutation,
  useGetDepartmentQuery,
  useGetUserDropDownQuery,
  useGetPropertyDetailQuery,
  useGetPropertyAssignQuery,
  useGetUserListQuery,
  usePostPropertyAssignMutation,
  useGetPropertyUserListQuery,
  useGetClientsListQuery,
   // Corrected export
} = setting; // Corrected export
