import React, { useEffect } from "react";
import { useIsIpad } from "../utils/isIpad";
import { useNavigate } from "react-router-dom";
import { useLoginInfoQuery } from "../redux/slices/onBoarding";
import CRODashboard from "./Dashboard/CRODashboard";
import PerformanceDashboard from "./Dashboard/PerformanceDashboard";
import CSO_Dashboard from "./CSO/dashboard";
import Cookies from "js-cookie";

const MainDashboard = () => {
  const {
    data: currentScreenData,
    isLoading,
    isFetching,
  } = useLoginInfoQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isFetching && currentScreenData?.data) {
      const currentScreen = currentScreenData?.data?.currentScreen;

      if (currentScreen && currentScreen !== 20) {
        navigate("/onBoarding");
      }

      // Save user information to Cookies
      Cookies.set("designationId", currentScreenData?.data?.designationId, {
        expires: 1,
        path: "/",
        secure: true,
        sameSite: "Strict",
      });
      const joinedDepartments = currentScreenData?.data?.department.join(",");
      console.log(joinedDepartments, "aman joined");
      Cookies.set("departments", joinedDepartments, {
        expires: 1,
        path: "/",
        secure: true,
        sameSite: "Strict",
      });

      Cookies.set("company", currentScreenData?.data?.companies?.[0]?.companyId, {
        expires: 1,
        path: "/",
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set('profileImg', currentScreenData?.data?.profileImg	,{
        expires: 1,
        path: "/",
        secure: true,
        sameSite: "Strict",
      })
    }
  }, [currentScreenData?.data, isFetching, isLoading]);

  return (
    <div className="w-[100%]  -z-10 h-[100vh]   overflow-y-auto ">
      {/* <UniversalLoader loading={isLoading}/> */}
      {currentScreenData?.data?.designationId === 96876025 ? (
        <CRODashboard />
      ) : currentScreenData?.data?.designationId === 38539455 ? (
        <div className="flex justify-center h-[93vh]">
          <PerformanceDashboard />
        </div>
      ) : currentScreenData?.data?.designationId === 6955087 ? (
        <div className="flex justify-center h-[93vh] w-[100%]">
          <CSO_Dashboard />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MainDashboard;
