import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import ReusableInput from "../../component/InputField/ReusableInput";

import { FormProvider, useForm } from "react-hook-form";
import { usePostTaskTypeMutation } from "../../redux/slices/canabel";

import { toast } from "react-toastify";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import { useAddNewCustomerMutation } from "../../redux/slices/Finance";
import Cookies from "js-cookie";

const AddCustomerForm = ({ showModal, setShowModal, refetch }) => {
  const methods = useForm({
    customerName: "",
    amountClosed: "",
  });

  // Destructure methods
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [addNewCustomer] = useAddNewCustomerMutation();
  const [submitError, setSubmitError] = useState();

  const [customerName, setCustomerName] = useState();
  const [amountClosed, setAmountClosed] = useState();

  // Submit handler
  const onSubmit = (data) => {
    console.log("aman methods", data);

    let apiObj = {
      customerName: data?.customerName,
      companyId: Cookies.get("company")
        ? JSON.parse(Cookies.get("company"))
        : "",
      amountClosed: data?.amountClosed,
    };

    setSubmitError(false);
    console.log(apiObj, "apiObj");

    addNewCustomer(apiObj)
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        setShowModal(false);
        refetch();
      })
      .catch((err) => {
        toast.error(err.data.message, { position: "bottom-right" });
      });
  };

  return (
    <>
      <div className="fixed  z-50 inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div className="bg-lightThemeGradient dark:bg-darkThemeGradient w-full max-w-[1260px] text-darkThemeFontColor rounded-lg shadow-md">
          <div className=" w-full flex flex-col justify-center py-4">
            <div className="flex flex-row w-[100%] justify-between px-[20px]">
              <div className=" flex flex-row items-center">
                <div
                  onClick={() => setShowModal(false)}
                  className=" cursor-pointer text-white text-2xl"
                >
                  <IoClose />
                </div>
                <p className="text-[20px] font-semibold text-white dark:text-white">
                  Add New Customer
                </p>
              </div>
              {/* <button
                className="text-[15px] font-semibold bg-blue-600 rounded-md px-4 py-2"
                type="submit"
              >
                Save
              </button> */}
            </div>
            <FormProvider {...methods}>
              <form
                className="flex flex-col"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <div className="grid grid-cols-2 gap-4 p-6 w-full h-4/5">
                  <div className="col-span-1 h-14">
                    <ReusableInput
                      label="Customer Name"
                      name="customerName"
                      type="text"
                      placeholder="Enter Customer Name"
                      rules={{ required: "Customer Name is required" }}
                      showIcon={true}
                    />
                  </div>
                  <div className="col-span-1 h-14">
                    <ReusableInput
                      label="Amount Closed"
                      name="amountClosed"
                      type="amount"
                      placeholder="Enter Amount Closed"
                      rules={{ required: "Amount Closed is required" }}
                      showIcon={true}
                    />
                  </div>
                </div>
                <button
                  className="text-[15px] font-semibold bg-blue-600 rounded-md px-4 py-2 mt-[15px] mx-[20px]"
                  type="submit"
                >
                  Save
                </button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomerForm;
