import React from 'react';

const PopupModal = ({ closeModal, handleFunction ,heading,subHeading }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-lightThemeGradient dark:bg-darkThemeGradient p-6 rounded-lg shadow-lg text-darkThemeFontColor">
        <h2 className="text-lg font-semibold mb-4">{heading}</h2>
        <p className="mb-4">{subHeading}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={handleFunction}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
