import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRouters from "./routes/appRouters";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Header from "./component/Layout/Header";
import SideBar from "./component/Layout/SideBar";
import { useState } from "react";
import NewSidebar from "./component/newSideBar";

function AppContent() {
  const location = useLocation();
  const noHeaderAndSidebarPaths = [
    "/onboarding",
    "/OnBoarding",
    "/login",
    "/signup",
    "/forgot-password",
    "/add-team-members",
    "/onboarding/success",
    "/otp",
    "/loading",
    "/change-password/",
    "/loginDashboard",
    "/onBoarding",
  ];
  const [sideBarOpen, setSideBarOpen] = useState();
  const shouldShowHeaderAndSidebar = !noHeaderAndSidebarPaths.includes(
    location.pathname
  );

  const isOnboarding = location.pathname?.includes('client-pre-onboarding-form')

  return (
    <>
      <div className="relative">
       {!isOnboarding && <div className="fixed w-[100%] z-50">
          {shouldShowHeaderAndSidebar && (
            <Header sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
          )}
        </div>}
        {/* {(shouldShowHeaderAndSidebar && sideBarOpen) && <SideBar />} */}
       {!isOnboarding && <div className="fixed z-50">{shouldShowHeaderAndSidebar && <SideBar />}</div>}
        <div className=" text-textDark  flex justify-center dark:text-textDark ">
          <AppRouters />
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <div className=" themeToggle ">
      <Router>
        {/* <NewSidebar /> */}
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <AppContent />
        </GoogleOAuthProvider>
      </Router>
    </div>
  );
}

export default App;
