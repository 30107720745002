import React from 'react';
import ReusableInput from '../../component/InputField/ReusableInput';
import { useForm, FormProvider } from "react-hook-form";
import { useChangePasswordMutation } from '../../redux/slices/onBoarding';
import { toast } from 'react-toastify';
import Skeleton from '../../component/Skeleton';

const ChangePassword = ({isLoading,setShowEditPassword}) => {
  const methods = useForm(); 
  const [changePassword] = useChangePasswordMutation(); // Move this inside the component

  const onSubmit = (data) => {
    changePassword({
      oldPass: data?.currentPassword,
      newPass: data?.newPassword,
      confirmPass: data?.confirmPassword,
    }).unwrap().then((res)=>{
      toast.success(res?.message,{position: "bottom-right"})
    }).catch((err)=>{
      toast.error(err?.data?.message,{position: "bottom-right"})
    })
  };

  return (
    <div>
    {  isLoading ? <div className="h-[400px]"><Skeleton/> </div> :<div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg shadow-lg p-4">
       <div className='flex justify-between'> <h1 className=" font-bold  dark:text-white mb-6 ">
          Change Password
        </h1>
        <h1 className='cursor-pointer bg-red-500 h-fit p-1 rounded-lg' onClick={()=>{setShowEditPassword(false)}}>Close</h1>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="grid grid-cols-1 md:grid-cols-2"
          >
            <div className="md:col-span-2">
              <ReusableInput
                label="Current Password"
                name="currentPassword"
                type="password"
                placeholder="Enter your Current Password"
                rules={{ required: "Current Password is required" }}
                className="placeholder:text-black dark:placeholder:text-white"
                labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
              />
            </div>
            <div className="md:col-span-2">
              <ReusableInput
                label="New Password"
                name="newPassword"
                type="password"
                placeholder="Enter your New Password"
                rules={{ required: "New Password is required" }}
                className="placeholder:text-black dark:placeholder:text-white"
                labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
              />
            </div>
            <div className="md:col-span-2">
              <ReusableInput
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeholder="Enter your Confirm Password"
                rules={{ required: "Confirm Password is required" }}
                className="placeholder:text-black dark:placeholder:text-white"
                labelClass="text-lightThemeFontColor dark:text-darkThemeFontColor"
              />
            </div>

            <button
              type="submit"
              className="w-full md:col-span-2 bg-blue-500 text-white font-bold py-2 rounded-md"
            >
              Submit
            </button>
          </form>
        </FormProvider>
      </div>}
    </div>
  )
}

export default ChangePassword;
