export function formatDateString(dateString) {
    const date = new Date(dateString);
    
    // Array of short month names
    const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = shortMonthNames[date.getMonth()]; // Get short month name
    const year = date.getFullYear();
    
    // Return the formatted date
    return `${day}-${month}-${year}`;
}