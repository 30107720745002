import React, { useState } from 'react'
import UniversalTable from '../Table/UniversalTable'
import { useComposetDetailQuery, useGetPropertiesOnRiskQuery, useOtaTableQuery } from '../../redux/slices/dashboard'
import Skeleton from '../Skeleton'
import { toast } from 'react-toastify'
import { useSwitchToRatexQuery } from '../../redux/slices/onBoarding'
import switchToRatex from '../../utils/SwitchToRatex'

const CompsetTable = ({hId}) => {
    const [pageNumber,setPageNumber]=useState(1) 
    const {data,isLoading}=useComposetDetailQuery({hId:hId})
    

    const totalPages=5	;
const handlePrevious=() => {
    if(pageNumber>1){
      setPageNumber(pageNumber-1)
    }
  
}
const { data: switchToRate } = useSwitchToRatexQuery();
const handleNext=() => {
    if(pageNumber<totalPages){
      setPageNumber(pageNumber+1)
    }
  
}
const handleNavigate = (reportLink) => {
    const token = switchToRate?.data?.data?.token;
    const hId = localStorage.getItem("hId") || 790755;
    if (token) {
      // window.open(
      //   `https://ratex.retvenslabs.com/ratex/token:${token}/hId:${parseInt(hId)}/report:${reportLink}`,
      //   "_blank"
      // );
      window.open(
        `http://localhost:3001/ratex/token:${token}/hId:${hId}/settings`,
        "_blank"
      );
    } else {
      toast.error("Token not received from switch to rate.", {
        position: "bottom-right",
      });
    }
  };

  return (
    <div className='bg-lightThemeGradient h-full rounded-lg dark:bg-darkThemeGradient   p-4'>
        <div className=''>

      <h1 className='text-2xl'>Compset </h1>
      <h2 className='text-sm'>{data?.data.length} Compsets</h2>
        </div>

       { isLoading?<div className='w-full h-[200px]'> <Skeleton/> </div>:<div>
            <UniversalTable handleFunction={()=>switchToRatex(hId,'settings')} showActions={'compset'} dataArray={data?.data} />


        </div>}
    </div>
  )
}

export default CompsetTable

