import React, { useState } from "react";
import UniversalTable from "../Table/UniversalTable";
import {
    useAddPropertyDetailMutation,
  useClientPropertyManagementQuery,
  useDeletePropertyMutation,
  useGetPropertiesOnRiskQuery,
  usePropertyManagementQuery,
  useDeactivePropertyMutation,
} from "../../redux/slices/dashboard";
import PopupModal from '../Modal/PopupModal'
import {
  useGetClientsListQuery
} from "../../redux/slices/Setting";
import Skeleton from "../Skeleton";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../button";
import Cookies from "js-cookie";
import AssigneeForm from "../AssigneeForm";
import { toast } from "react-toastify";
import NormalDropDown from '../DropDown/NormalDropDown'
const Properties = ({
  propertyforClient = false,
  hotelId,
  showAddProperty,
  
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [selectedClient,setSelectedClient]=useState();
  const [showDeactivateModal,setShowDeactivateModal]=useState(false);
  const [showActivateModel,setShowActivateModal]=useState(false)
  const methods = useForm({});
  const [deactiveProperty]=useDeactivePropertyMutation();
  const {data:clientData}=useGetClientsListQuery();

  const [redirectArray, setRedirectArray] = useState([]);
  const [addPropertyDetail]=useAddPropertyDetailMutation();
  const [deleteProperty] = useDeletePropertyMutation();
  const { data, isLoading, isFetching, refetch } = usePropertyManagementQuery(
    {
      pageNumber: pageNumber,
    },
    { skip: propertyforClient }
  );
  const {
    data: clientManagementData,
    isLoading: clientManagementIsLoading,
    isFetching: clientManagementIsFetching,
    refetch: clientManagementRefetch,
  } = useClientPropertyManagementQuery(
    {
      pageNumber: pageNumber,
      clientId: hotelId,
    },
    { skip: propertyforClient ? false : true }
  );
  const [showAddPropertyForm, setShowAddPropertyForm] = useState(false);
  const totalPages =
    data?.data?.totalPages || clientManagementData?.data?.totalPages;
  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  console.log(redirectArray,"redirectArray")

  const onSubmit = (data) => {
    const obj = {
      // companyId: Cookies.get("company"),
      propertyName: data?.propertyName,
      otherDetail: data?.otherDetail,
      pancard:data?.pancard,
      gstNumber:data?.gstNumber,
      assign:redirectArray,
      clientId:propertyforClient?hotelId:selectedClient,
      pointOfContact:{
        pointOfContactName:data?.pocName,
        pointOfDesignation:data?.pocDesignation,
        pointOfEmail:data?.pocEmail,
        address:{
          fullAddress:data?.fullAddress
        }
      },
      
    };
    addPropertyDetail(obj).unwrap()
    .then((res) => {
      toast.success(res?.message, { position: "bottom-right" });
      setShowAddPropertyForm(false);
      refetch();
    })
    .catch((err) => {
      toast.error(err?.data?.message, { position: "bottom-right" });
    });
  };
  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const openModal = (propertyId,action) => {
    
    setSelectedPropertyId(propertyId);
    if(action == 'Delete'){

      setIsModalOpen(true);
    }else if (action ==='Deactivate'){
      setShowDeactivateModal(true)
    }else if( action ==='Activate'){
      setShowActivateModal(true)
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowDeactivateModal(false);
    setShowActivateModal(false)
    setSelectedPropertyId(null);

  };

  const handleDeactivateConfirm = ()=>{
    if (selectedPropertyId) {
      deactiveProperty({ hId: selectedPropertyId,isActive:false })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          refetch();
          closeModal();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
  }
  const handleActivateConfirm=()=>{
    if (selectedPropertyId) {
      deactiveProperty({ hId: selectedPropertyId ,isActive:true})
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          refetch();
          closeModal();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
  }
  const handleDeleteConfirm = () => {
    if (selectedPropertyId) {
      deleteProperty({ hId: selectedPropertyId })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          refetch();
          closeModal();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
  };

  return (
    <div className="bg-lightThemeGradient min-h-[400px] rounded-lg dark:bg-darkThemeGradient text-darkThemeFontColor  p-4">
      {isLoading ||
      isFetching ||
      clientManagementIsLoading ||
      clientManagementIsFetching ? (
        <div className="w-full h-[400px]">
          {" "}
          <Skeleton />{" "}
        </div>
      ) : showAddPropertyForm ? (
        <div>
                   <FormProvider {...methods}>
            <form
              className="flex flex-col h-4/5 justify-between items-between"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
          <div className="flex gap-4  mb-2 +items-center justify-between">
            <div className="flex gap-4 items-center">
              <div className="">
                <Button
                  onClick={() => {
                    setShowAddPropertyForm(false);
                  }}
                >
                  <FaArrowLeft />
                </Button>
              </div>
              <div className="">
                <h1 className="text-darkThemeFontColor text-2xl font-bold">
                  Add New properrty
                </h1>

                <p className="">
                  Let's add a new Property and assign to your team
                </p>
              </div>
            </div>
            <div>
            <Button
                  onClick={() => {
                    // setShowAddPropertyForm(false);
                  }}
                >
                  Save
                </Button>
                </div>

          </div>
   
              <ReusableInput
                label="Property Name"
                name="propertyName"
                type="text"
                placeholder="Enter your Property Name"
                rules={{ required: "Property Name is required" }}
              />
              <ReusableInput
                label="GST Number"
                name="gstNumber"
                type="text"
                placeholder="Enter your Gst Number"
                // rules={{ required: "Gst number is required" }}
              />
              <ReusableInput
                label="PAN Number"
                name="pancard"
                type="text"
                placeholder="Enter your pan Number"
                // rules={{ required: "Pan number is required" }}
              />
         <div className="flex gap-4 w-full  justify-start  items-center">
        {!propertyforClient &&   <div className="w-full">
            <p>Client</p>
            <div className="h-14 w-full">
              <NormalDropDown
                selectedValue={selectedClient}
                setSelectedValue={setSelectedClient}
                options={clientData?.data}
                label={"Select value"}
                sendOnlyId={true}
              />
            </div>
          </div>}
          </div>
              <AssigneeForm
              hotelId={hotelId}
                redirectArray={redirectArray}
                setRedirectArray={setRedirectArray}
              />
              <ReusableInput
                label="Point of Contact Name"
                name="pocName"
                type="text"
                placeholder="Enter your POC Name"
                // rules={{ required: "Pan number is required" }}
              />
              <ReusableInput
                label="Point of Contact Designation"
                name="pocDesignation"
                type="text"
                placeholder="Enter your POC Designation"
                // rules={{ required: "Pan number is required" }}
              />
              <ReusableInput
                label="Point of Contact Email"
                name="pocEmail"
                type="text"
                placeholder="Enter your POC Email"
                // rules={{ required: "Pan number is required" }}
              />
              
             
              <ReusableInput
                label="Point of Contact address"
                name="fullAddress"
                type="text"
                placeholder="Enter your POC Address"
                // rules={{ required: "Pan number is required" }}
              />
            </form>
          </FormProvider>
        </div>
      ) : (
        <div className='min-h-[200px]  '>
          <div className="flex h-full  justify-between">
            <div>
              <h1 className="text-2xl">Properties </h1>
              <h2 className="text-sm">
                {data?.data?.totalCount ||
                  clientManagementData?.data?.totalCount}{" "}
                properties
              </h2>
            </div>
            <h1
              onClick={() => setShowAddPropertyForm(true)}
              className="p-2 cursor-pointer bg-white rounded-lg h-fit  text-black text-sm"
            >
              Add Property
            </h1>
          </div>
          <div className='min-h-full overflow-x-hidden '>
            <UniversalTable
              showActions={"properties"}
              dataArray={
                data?.data?.userDetail || clientManagementData?.data?.userDetail
              }
              refetch={refetch || clientManagementRefetch}
              handleFunction={openModal} // Open modal on deactivate click
            />

            {totalPages > 1 && (
              <div className="flex justify-center items-center space-x-4 mt-6">
                <button
                  onClick={handlePrevious}
                  className={`px-4 py-2 text-white rounded ${
                    pageNumber === 1
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-700"
                  }`}
                  disabled={pageNumber === 1}
                >
                  Previous
                </button>

                <span className="text-lg text-white font-medium">
                  Page {pageNumber} of {totalPages}
                </span>

                <button
                  onClick={handleNext}
                  className={`px-4 py-2 text-white rounded ${
                    pageNumber === totalPages
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-700"
                  }`}
                  disabled={pageNumber === totalPages}
                >
                  Next
                </button>
              </div>
            )}
                     {isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg">
    <div className="bg-lightThemeGradient dark:bg-darkThemeGradient text-darkThemeFontColor  p-6 rounded-lg shadow-lg">
      <h2 className="text-lg font-semibold mb-4">Confirm Deactivation</h2>
      <p className="mb-4">Do you really want to deactivate this property?</p>
      <div className="flex justify-end space-x-4">
        <button
          onClick={closeModal}
          className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
        >
          Cancel
        </button>
        <button
          onClick={handleDeleteConfirm}
          className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
        >
          Yes, Deactivate
        </button>
      </div>
    </div>
    </div>
  </div>
)}


{showDeactivateModal && <PopupModal closeModal={closeModal} handleFunction={handleDeactivateConfirm} heading='Comfirm Deactivate' subHeading={'Do you really want to Deactivate Property'}/>}
{showActivateModel && <PopupModal closeModal={closeModal} handleFunction={handleActivateConfirm} heading='Comfirm Activate' subHeading={'Do you really want to Activate Property'}/>}

          </div>
        </div>
      )}
    </div>
  );
};

export default Properties;
