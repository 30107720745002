import React from 'react'


const WelcomeScreenAlert = ({
    
    text="Alert Text",
    buttonText=" Button Text"

}) => {
    return ( <>
    <div className='w-[100%] flex flex-row justify-between items-center px-[10px] py-[10px] bg-green-500 rounded-md'>
        <p className='text-[14px] font-[600]'>{text}</p>
        <button className='px-[5px] text-[14px] py-[5px] bg-green-800 rounded-md font-[600]'>
            {buttonText}
        </button>
    </div>
    </> );
}
 
export default WelcomeScreenAlert;