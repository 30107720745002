import React, { useEffect, useRef, useState } from "react";

const OtpInput = ({ length = 4,handleOTPVarification, onOtpSubmit = () => {}, setCombinedOTP }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);
 
  const handleChange = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) return;

    const newOTP = [...otp];

    newOTP[index] = value.substring(value?.length - 1);
    setOtp(newOTP);

    const combinedOTP = newOTP.join("");
    setCombinedOTP(combinedOTP);

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (index, e) => {
    if (
      e.code === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    } else if(e.code === "Enter" && !inputRefs.current[index + 1]) {
      handleOTPVarification();
  };
}
  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(0, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };
  const handlePaste = (index, e) => {
    const pasteData = e.clipboardData.getData("text");
    if (isNaN(pasteData)) return;

    const pasteArray = pasteData.split("").slice(0, length - index);
    const newOTP = [...otp];

    pasteArray.forEach((char, i) => {
      newOTP[index + i] = char;
      if (index + i < length - 1 && inputRefs.current[index + i + 1]) {
        inputRefs.current[index + i + 1].focus();
      }
    });

    setOtp(newOTP);
  };
  return (
    <div className="w-[100%]">
      {otp.map((value, index) => {
        return (
          <input
            key={index}
            ref={(input) => (inputRefs.current[index] = input)}
            type="text"
            className="w-[40px] h-[40px] border m-5 text-center font-[1.2em]"
            value={value}
            onChange={(e) => {
              handleChange(index, e);
            }}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onClick={() => handleClick(index)}
            onPaste={(e) => handlePaste(index, e)}
          />
        );
      })}
    </div>
  );
};

export default OtpInput;
