import React from "react";
import ReusableInput from "../InputField/ReusableInput";

const CompanyDetails = ({ setFinalData, finalData ,onSubmit}) => {

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold  text-lightThemeFontColor dark:text-darkThemeFontColor ">Tell Us About Your Proud</h1>
        <p className="text-[#96A0AD] my-2">
          By providing some basic information about your business. We can ensure
          you recieve the most relevent data and functionalities within R-World
        </p>
      </div>
      <div>
        
            <ReusableInput
              label="Company Name"
              name="companyName"
              type="text"
              placeholder="Enter your company name"
              rules={{ required: "company name is required" }}
              className='  placeholder:text-[#96A0AD] dark:placeholder:text-white   text-lightThemeFontColor dark:text-darkThemeFontColor '
              lableClass=' text-lightThemeFontColor dark:text-darkThemeFontColor text-lightThemeFontColor dark:text-darkThemeFontColor  '
            />
            <ReusableInput
              label="Enter company GST number (Optional)"
              name="companyGSTNumber"
              type="text"
              className='  placeholder:text-[#96A0AD] dark:placeholder:text-white    text-lightThemeFontColor dark:text-darkThemeFontColor '
              lableClass=' text-lightThemeFontColor dark:text-darkThemeFontColor text-lightThemeFontColor dark:text-darkThemeFontColor  '
              placeholder="Enter company GST number"
              rules={{
                pattern: {
                  value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                  message: "Invalid GST number format"
                }
              }}
            />
     
      </div>
    </div>
  );
};

export default CompanyDetails;
