import React, { useEffect, useState } from 'react'
import UniversalTable from '../Table/UniversalTable'
import { useGetPropertiesOnRiskQuery, useOtaTableQuery } from '../../redux/slices/dashboard'
import Skeleton from '../Skeleton'
import { useSwitchToRatexQuery } from '../../redux/slices/onBoarding'
import { toast } from 'react-toastify'
import SwitchToRatex from '../../utils/SwitchToRatex'
import switchToRatex from '../../utils/SwitchToRatex'

const OTATable = ({hId}) => {
    const [pageNumber,setPageNumber]=useState(1) 
    const {data,isLoading}=useOtaTableQuery({hId:hId})
    const { data: switchToRate } = useSwitchToRatexQuery();
    const [isSwitch,setIsSwitch] = useState(false)

    const totalPages=5	;
const handlePrevious=() => {
    if(pageNumber>1){
      setPageNumber(pageNumber-1)
    }
  
}

const handleNext=() => {
    if(pageNumber<totalPages){
      setPageNumber(pageNumber+1)
    }
  
}
const handleNavigate = (reportLink) => {
    const token = switchToRate?.data?.data?.token;
    const hId = localStorage.getItem("hId") || 790755;
    if (token) {
      // window.open(
      //   `https://ratex.retvenslabs.com/ratex/token:${token}/hId:${parseInt(hId)}/report:${reportLink}`,
      //   "_blank"
      // );
      window.open(
        `http://localhost:3001/ratex/token:${token}/hId:${hId}/settings`,
        "_blank"
      );
    } else {
      toast.error("Token not received from switch to rate.", {
        position: "bottom-right",
      });
    }
  };
  useEffect(()=>{
    if(isSwitch){

        setIsSwitch(false)
    } 
        // eslint-disable-next-line
  },[isSwitch])
  return (
    <>
      <div className='bg-lightThemeGradient  rounded-lg dark:bg-darkThemeGradient text-darkThemeFontColor  p-4'>
       <div className=''>

     <h1 className='text-2xl'>OTA </h1>
     <h2 className='text-sm'>{data?.data.length} otas</h2>
       </div>

      { isLoading?<div className='w-full h-[200px]'> <Skeleton/> </div>:<div className=''>
           <UniversalTable handleFunction={()=>switchToRatex(hId,'settings')} hId={hId} isSwitch={isSwitch} showActions={'compset'} dataArray={data?.data} />


       </div>}
   </div>
    
    </>
   
  )
}

export default OTATable

