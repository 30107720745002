// ContactList.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase-config'; // Path to your firebase-config.js
import axios from 'axios';
import Cookies from 'js-cookie';

const ContactList = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchGoogleContacts = async () => {
      try {
        // Ensure the user is authenticated
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated.');
        }

        // Get Google access token from Cookies
        const token = Cookies.get('idToken'); // Ensure this token has the correct scopes

        // Check if token is valid
        if (!token) {
          throw new Error('No valid token found.');
        }

        // Fetch Google contacts using axios
        const { data } = await axios.get('https://people.googleapis.com/v1/people/me/connections', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            personFields: 'names,emailAddresses',
          },
        });

        // Process fetched contacts data
        const fetchedContacts = data.connections.map((person) => ({
          name: person.names?.[0]?.displayName,
          email: person.emailAddresses?.[0]?.value,
        }));

        setContacts(fetchedContacts);
      } catch (error) {
        console.error('Error fetching Google contacts:', error);
        // Handle error
      }
    };

    fetchGoogleContacts();
  }, []);

  return (
    <div>
      {/* <h2>Google Contacts</h2> */}
      <ul>
        {contacts.map((contact, index) => (
          <li  key={index}>
            <strong>Name:</strong> {contact.name}, <strong>Email:</strong> {contact.email}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactList;
