import React, { useState } from "react";
import { useAddCommentMutation } from "../redux/slices/canabel";

const CommentComponent = ({
  descriptionData,
  commentsData,
  refetch,
  taskId,
  activityHistory,
}) => {
  const [selectedItem, setSelectedItem] = useState("activity");
  const [newComment, setNewComment] = useState(""); // State for new comment
  const [comments, setComments] = useState(commentsData); // State to hold all comments
  const [addComment] = useAddCommentMutation();
  // Function to handle comment submission
  const handleCommentSubmit = () => {
    if (newComment.trim() === "") return; // Prevent empty comments

    // Create a new comment object
    const comment = {
      comment: newComment,
      taskId: taskId,
    };
    addComment(comment)
      .unwrap()
      .then(() => {
        refetch();
      });
    // Update the comments state
    // setComments([...comments, comment]);

    // Clear the input field
    setNewComment("");
  };

  return (
    <div className="w-full">
      {/* Tab buttons */}
      <div className="w-full mt-2 flex gap-2 items-end border-b border-white">
        <h1
          onClick={() => setSelectedItem("description")}
          className={`cursor-pointer my-2 ${selectedItem === "description" && "text-xl font-bold text-white"}`}
        >
          Description
        </h1>
        <h1
          onClick={() => setSelectedItem("comments")}
          className={`cursor-pointer my-2 ${selectedItem === "comments" && "text-xl font-bold text-white"}`}
        >
          Comments
        </h1>
        <h1
          onClick={() => setSelectedItem("activity")}
          className={`cursor-pointer my-2 ${selectedItem === "activity" && "text-xl font-bold text-white"}`}
        >
          Activity
        </h1>
      </div>

      {/* Tab content */}
      <div className="">
        {selectedItem === "description" &&
          descriptionData?.map((item, key) => (
            <div key={key} className="w-full p-2 rounded-lg bg-gradient-to-r from-[#7F7F7F63] to-[#E5E5E542] my-2">
              {item?.description}
            </div>
          ))}

        <div className="comments-section">
          {selectedItem === "comments" && (
            <div>
              {commentsData?.map((item, key) => (
                <div key={key} className="flex space-x-2 my-2 items-start">
                  <img
                    src={
                      "https://e7.pngegg.com/pngimages/419/473/png-clipart-computer-icons-user-profile-login-user-heroes-sphere-thumbnail.png"
                    }
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full"
                  />
                 
                  <div className="w-[100%]"> 
                    <div className="text-white font-bold">Shivam</div>
                    <div className="bg-gradient-to-r from-[#3396E2BD] to-[#6679D3B5] p-3 rounded-lg w-[100%]">
                      {item?.comment}
                    </div>
                  </div>
                </div>
              ))}

              <div className="mt-4 flex space-x-2 items-start">
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Write a comment..."
                  className="w-full p-2 rounded-lg bg-gray-800 text-white"
                />
                <button
                  onClick={handleCommentSubmit}
                  className="p-2 bg-blue-600 text-white rounded-lg"
                >
                  Send
                </button>
              </div>
            </div>
          )}
        </div>

        {selectedItem === "activity" &&
          activityHistory?.map((item, key) => (
            <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-4 rounded-md shadow-md text-white flex items-start space-x-4 my-2">
              <div className="flex flex-col items-center">
                {/* Circle for the timeline */}
                <div className="w-3 h-3 bg-green-400 rounded-full mt-1.5"></div>
                {/* Dotted line for timeline */}
                <div className="h-12 w-0.5 bg-green-400 mt-1"></div>{" "}
                {/* Adjust height here */}
              </div>
              <div className="flex-1">
                {/* Main notification text */}
                <div>{item?.activity}</div>
                {/* Date and time */}
                <div className="text-sm text-gray-300 mt-2">{item?.time}</div>
              </div>
              {/* Link */}
              {/* <a href="#" className="text-green-300 hover:underline">
                Check Rates
              </a> */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CommentComponent;
