import React from 'react'
import { calculatePercentageDifference } from '../../../../utils/funcs';

const DashboardCard = ({
    Icon,
    cardName="",
    currentValue=0,
    lastValue=0
}) => {
    let percentageDiff= calculatePercentageDifference(lastValue,currentValue)
    return ( 
        <div className="flex flex-row justify-between items-center px-[10px] py-[15px] w-[100%] dark:bg-lightGradient bg-dark-gradient rounded-md mr-[10px]">
          <div className="flex flex-col justify-center items-start">
            <p className="font-[300] text-[10px]">{cardName}</p>
            <div className='flex flex-row items-end justify-start space-x-1'>
            <p className="font-[800] text-[16px]">{currentValue}</p>
            <p className={`font-[800] text-[13px] py-[1px] ${percentageDiff.isPositive ? 'text-red-600' : ' text-green-600'}`}>+{percentageDiff.percentage} %</p>
            </div>
          </div>
          <div className="h-[36px] w-[36px] bg-buttonBlue rounded-md flex justify-center items-center">
            <Icon className="text-white h-[20px]" />
          </div>
        </div>
        );
}
 
export default DashboardCard;