import React from 'react';
import { useState } from "react";

const DynamicTable = () => {
    // const {data:employeeData,isLoading,isFetching,refetch}=useEmployeePropertyQuery({pageNumber:pageNumber,userId:empId})
  
    // let countryData = countryData
    const data = [
        {
          Name: 'John Doe',
          Age: 28,
        },
        {
          Name: 'Jane Smith',
          Age: 34,
        },
      ];
    
      const actions = {
        Name: (name) => alert(`You clicked on ${name}`),
        // You can add more actions for other headers if needed
      };
  if (!data || data.length === 0) {
    return <div className="p-4">No data available</div>;
  }

  const headers = Object.keys(data[0]);

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full border  border-gray-300 rounded-lg">
        <div className="flex border-b bg-gray-200">
          {headers.map((header) => (
            <div key={header} className="flex-1 p-4 font-semibold text-left">
              {header}
            </div>
          ))}
        </div>
        <div>
          {data.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="flex border-b hover:bg-gray-100"
            >
              {headers.map((header) => {
                const cellValue = row[header];
                const action = actions[header];

                return (
                  <div
                    key={header}
                    className="flex-1 p-4"
                    onClick={action ? () => action(cellValue) : undefined}
                  >
                    {cellValue}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
