import React from 'react'
import EmployeeOverview from '../component/ApexGraphComponent/EmployeeOverview'

const EmployeeOveriviewPage = () => {
  return (
    <div className="p-4 w-[80%] mx-auto pt-[100px]">
      <EmployeeOverview showPagination={true}  />
    </div>
    
  )
}

export default EmployeeOveriviewPage

