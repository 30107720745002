import React from 'react'

const NormalButton = ({
    hasIcon,
    isLoading,
    buttonText,
    Icon,
    clickFunc
}) => {
  return (
    <button className="">
      <div className="flex flex-row justify-center items-center px-[14px] space-x-1 py-[4px]">
        <p className="text-white text-[12px]">{buttonText}</p>
        <Icon className="h-[12px] text-white"/>
      </div>
    </button>
  );
};
 
export default NormalButton;