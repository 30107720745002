import getAccessToken from "../../../utils/getAccessToken";
import getToken from "../../../utils/getToken";
import { emptySplitApi } from "../../injectEndpoint";

export const OnBoarding = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `onboarding/login`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["login"],
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: `onboarding/signUp`,
        method: "POST",
        headers: {
          authorization: getAccessToken(),
        },
        body: data,
      }),

      invalidatesTags: ["signup"],
    }),
    addPreOnboarding: builder.mutation({
      query: (data) => ({
        url: `preOnboarding/addPreOnboarding`,
        method: "POST",
        headers: {
          authorization: getAccessToken(),
        },
        body: data,
      }),

      invalidatesTags: ["addPreOnboarding"],
    }),
    onBoarding: builder.mutation({
      query: (data) => ({
        url: `onboarding/getOnboardingInfo`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["onBoarding"],
    }),
    basicDetails: builder.mutation({
      query: (data) => ({
        url: `onboarding/basicDetail`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["basicDetails"],
    }),
    OTPVerfication: builder.mutation({
      query: (data) => ({
        url: `onboarding/otpVerification`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["OTPVerfication"],
    }),
    ResendOTP: builder.mutation({
      query: (data) => ({
        url: `onboarding/resendOTP`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["ResendOTP"],
    }),
    loginInfo: builder.query({
      query: () => ({
        url: `onboarding/getLoginInfo`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["loginInfo"],
    }),
    getOtaDetails: builder.query({
      query: () => ({
        url: `/utils/getOTADetails`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getOtaDetails"],
    }),
    getHotelServicesPreOnboarding: builder.query({
      query: () => ({
        url: `/preOnboarding/getHotelServicesForPreOnboarding`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getHotelServicesPreOnboarding"],
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `onboarding/forgotPassword`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["forgotPassword"],
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `onboarding/resetPassword`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["resetPassword"],
    }),
    getAllCategory: builder.query({
      query: () => ({
        url: `onboarding/getAllCategory`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getAllCategory"],
    }),
    onBoardingInviteMember: builder.mutation({
      query: (data) => ({
        url: `notification/invitePeople`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["onBoardingInviteMember"],
    }),
    getScenarioDetails: builder.query({
      query: (data) => ({
        url: "onboarding/getScenarioDetails",
        method: "GET",
        params: data,
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getScenarioDetails"],
    }),
    addScenarioByUser: builder.mutation({
      query: (data) => ({
        url: `onboarding/addScenarioByUser`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["addScenarioByUser"],
    }),
    updateFrequency: builder.mutation({
      query: (data) => ({
        url: `onboarding/updateFrequency`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["updateFrequency"],
    }),
    getScenarioFrequency: builder.query({
      query: (data) => ({
        url: "onboarding/getScenarioFrequency",
        method: "GET",
        params: data,
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getScenarioFrequency"],
    }),
    switchToRatex: builder.query({
      query: () => ({
        url: `onboarding/switchToRatex`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["switchToRatex"],
    }),

    goBack: builder.mutation({
      query: (data) => ({
        url: `onboarding/updateCurrentScreen`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["updateCurrentScreen"],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `onboarding/changePassword`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["changePassword"],
    }),

    getPreOnboardingForm: builder.query({
      query: (data) => ({
        url: `preOnboarding/getPreOnboarding`,
        method: "GET",
        params:data,
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getPreOnboardingForm"],
    }),

    getUserProfileInformation: builder.query({
      query: () => ({
        url: `settings/user/getUserProfileInformation`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getUserProfileInformation"],
    }),
    getEmployeePi: builder.query({
      query: (data) => ({
        url: `employee/getEmployeePI`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getEmployeePi"],
    }),
    editUserProfile: builder.mutation({
      query: (data) => ({
        url: `settings/user/editUserProfile`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data ,
      }),
      invalidatesTags: ["editUserProfile"],
    }),
    editEmployeeProfile:builder.mutation({
        query: (data) => ({
          url: `employee/editEmployeeProfile?`,
          method: "PATCH",
          headers: {
            Authorization: getToken(),
          },
          body: data,
          params:{userId:data?.userId}
        }),
        invalidatesTags: ["editEmployeeProfile"],
  
    }),
    editProfilePicture:builder.mutation({
        query: (data) => ({
          url: `settings/user/editProfilePicture`,
          method: "PATCH",
          headers:{
            Authorization: getToken(),
          },
          body: data,
        }),
        invalidatesTags: ["editProfilePicture"],
      }),  
      removeProfilePicture:builder.mutation({
        query: () => ({
          url: `settings/user/removeProfilePicture`,
          method: "PATCH",
          headers:{
            Authorization: getToken(),
          },
          
        }),
        invalidatesTags: ["removeProfilePicture"],
      }),  
    editEmployeeDetail:builder.mutation({
        query: (data) => ({
          url: `employee/editEmployeeDetail`,
          method: "PATCH",
          headers: {
            Authorization: getToken(),
          },
          body: data,
          params:{userId:data?.userId}
        }),
        invalidatesTags: ["editEmployeeDetail"],
    }),

    overrideExisting: false,
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useOnBoardingMutation,
  useBasicDetailsMutation,
  useOTPVerficationMutation,
  useResendOTPMutation,
  useLoginInfoQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetAllCategoryQuery,
  useOnBoardingInviteMemberMutation,
  useGetScenarioDetailsQuery,
  useAddScenarioByUserMutation,
  useUpdateFrequencyMutation,
  useGetScenarioFrequencyQuery,
  useSwitchToRatexQuery,
  useGoBackMutation,
  useChangePasswordMutation,
  useGetUserProfileInformationQuery,
  useEditUserProfileMutation,
  useEditEmployeeProfileMutation,
  useAddPreOnboardingMutation,
  useGetEmployeePiQuery,
  useEditEmployeeDetailMutation,
  useEditProfilePictureMutation,
  useGetHotelServicesPreOnboardingQuery,
  useGetOtaDetailsQuery,
  useGetPreOnboardingFormQuery,
 useRemoveProfilePictureMutation,

} = OnBoarding;
