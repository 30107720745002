import React, { useState, useEffect } from 'react';

const TaskProgress = ({ startTime = "2024-10-12T11:02:00", endTime = "2024-10-12T11:05:00" }) => {
  const [progress, setProgress] = useState(0);

  // Function to calculate the percentage of progress based on the current time
  const calculateProgress = () => {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);
    const totalDuration = end - start;
    const elapsed = now - start;
    const currentProgress = Math.min((elapsed / totalDuration) * 100, 100); // Ensure max is 100%

    setProgress(currentProgress);
  };

  useEffect(() => {
    const intervalId = setInterval(calculateProgress, 1000); // Update every second

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, [startTime, endTime]);

  return (
    // <div className="absolute h-[70%]   overflow-hidden ">
      <div
        className="h-full  flex items-center justify-end transition-all ease-in-out duration-500 absolute "
        style={{ width: `${progress}%` }}
      >  
       {/* {progress.toFixed(2)}% */}
       <div
  className="min-h-4 min-w-4 rounded-full flex itmes-center border overflow-hidden"
>
  <img
    src={'https://rown-space-bucket.nyc3.digitaloceanspaces.com/R-World/userProfilePicture/lighttheme.png'}
    alt=""
    className="w-4 h-4 object-fill rounded-full"
  />
</div>

 
      </div>
    //   <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-gray-800">
     
    //   </span>
    // </div>
  );
};

export default TaskProgress;
