import { toast } from "react-toastify";
import getToken from "./getToken";

const switchToRatex = async (hId, pathName = 'settings') => {
  try {
    // Replace the URL with your actual endpoint to fetch the token
    const response = await fetch('https://staging.worldserver.retvenslabs.com/api/onboarding/switchToRatex', {
        method: 'GET', // Or 'POST', depending on your API requirement
        headers: {
          'authorization': `${getToken()}`, // Pass the token here
          'Content-Type': 'application/json'      // Include if needed for your API
        }
      });
  
    
    if (!response.ok) {
      throw new Error('Failed to fetch token');
    }

    const result = await response.json();

    const token = result?.data?.data?.token;
    
    if (token) {
      window.open(
        `http://localhost:3001/ratex/token:${token}/hId:${parseInt(hId)}/${pathName}`,
        "_blank"
      );
    } else {
      toast.error("Token not received for switching to Ratex.", {
        position: "bottom-right",
      });
    }
  } catch (error) {
    toast.error(`Failed to fetch token for Ratex. Error: ${error.message}`, {
      position: "bottom-right",
    });
  }
};

export default switchToRatex;
