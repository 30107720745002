import React, { useEffect, useState } from "react";
import {
  useGetAllMeetingQuery,
  useGetUpComingMeetingQuery,
} from "../../redux/slices/dashboard";
import UniversalModel from "../Modal/UniversalModel";
import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const UpComingMettings = () => {
  const navigate = useNavigate();
  const { data: meetingData } = useGetUpComingMeetingQuery();
  const { data: getAllMeetingData } = useGetAllMeetingQuery();
  const [showViewAllMeeting, setShowAllMeeting] = useState();
  let meetingMode="Executive";
  useEffect(()=>{
      if (getAllMeetingData?.data?.taskComments?.length > 0) {


          meetingMode = "x";
        } else {
            meetingMode = "RevenueManager";
        }
    },[])

  return (
    <>
{  meetingMode=='Executive' ?  <div className=" bg-lightThemeGradient dark:bg-darkThemeGradient  p-6 rounded-lg h-full shadow-lg w-full text-white">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <h2 className="text-xl font-semibold">Upcoming Meeting </h2>
          <span className="bg-white text-blue-600 font-bold rounded-full px-2 py-0.5">
            {meetingData?.data?.length}
          </span>
        </div>
        <h2 onClick={() => navigate("/meetings")} className="cursor-pointer">
          View all
        </h2>
      </div>

      <div className="flex flex-wrap gap-2">
        {meetingData?.data?.map((reminder, index) => (
          <div className="bg-purple-200 relative rounded-lg p-4 h-[150px] w-[48%] shadow-lg flex flex-col justify-between">
            <div className=" absolute right-4 flex justify-end mt-4">
              <img
                src="https://www.gstatic.com/images/branding/product/1x/meet_2020q4_48dp.png"
                alt="Google Meet"
                className="w-8 h-8"
              />
            </div>
            <div className="text-left">
            <p className="text-xs text-gray-600">{reminder?.title}</p>
              <p className="text-xs text-gray-600">{reminder?.startTime}</p>
              <p className="text-lg font-bold text-gray-800">
                {reminder?.endTime}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-sm font-medium h-fit text-gray-900 line-clamp-2">
                {reminder?.description}
              </p>
            </div>
          </div>
        ))}
        <div className="bg-purple-200 relative rounded-lg p-4 h-[150px] w-[48%] shadow-lg flex flex-col justify-between">
          <div className=" absolute right-4 flex justify-end mt-4"></div>
          <div className="text-left w-full text-sm flex flex-col justify-center items-center  h-full">
            <IoAddCircleOutline color="green" size={40} />
            <h1 className="text-green-800">Schedule a meeting</h1>
          </div>
        </div>
      </div>

      {showViewAllMeeting && (
        <UniversalModel setShowModel={setShowAllMeeting}>
          <div className="w-full p-4 flex flex-col gap-4 mt-4">
            {getAllMeetingData?.data?.map((reminder, index) => (
              <div className="bg-purple-200 relative rounded-lg p-4 h-auto w-full shadow-lg flex flex-col justify-between">
                <div className=" absolute right-4 flex justify-end mt-4">
                  <img
                    src="https://www.gstatic.com/images/branding/product/1x/meet_2020q4_48dp.png"
                    alt="Google Meet"
                    className="w-8 h-8"
                  />
                </div>
                <div className="text-left">
                  <p className="text-xs text-gray-600">{reminder?.startTime}</p>
                  <p className="text-lg font-bold text-gray-800">
                    {reminder?.endTime}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-sm font-medium h-fit text-gray-900 ">
                    {reminder?.meetingDescription}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </UniversalModel>
      )}
    </div> :<div className=" bg-lightThemeGradient dark:bg-darkThemeGradient  p-6 rounded-lg h-full shadow-lg w-full text-white">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">

          <h2 className="text-xl font-semibold">Upcoming Task </h2>
          <span className="bg-white text-blue-600 font-bold rounded-full px-2 py-0.5">
            {meetingData?.data?.length}
          </span>
        </div>
        <h2 onClick={() => navigate("/meetings")} className="cursor-pointer">
          View all
        </h2>
      </div>

      <div className="flex flex-wrap gap-2">
        {meetingData?.data?.map((reminder, index) => (
          <div className="bg-purple-200 relative rounded-lg p-4 h-[150px] w-[48%] shadow-lg flex flex-col justify-between">
            <div className=" absolute right-4 flex justify-end mt-4">
              <img
                src="https://www.gstatic.com/images/branding/product/1x/meet_2020q4_48dp.png"
                alt="Google Meet"
                className="w-8 h-8"
              />
            </div>
            <div className="text-left">
              <p className="text-xs text-gray-600">{reminder?.startTime}</p>
              <p className="text-lg font-bold text-gray-800">
                {reminder?.endTime}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-sm font-medium h-fit text-gray-900 line-clamp-2">
                {reminder?.meetingDescription}
              </p>
            </div>
          </div>
        ))}
        <div className="bg-purple-200 relative rounded-lg p-4 h-[150px] w-[48%] shadow-lg flex flex-col justify-between">
          <div className=" absolute right-4 flex justify-end mt-4"></div>
          <div className="text-left w-full text-sm flex flex-col justify-center items-center  h-full">
            <IoAddCircleOutline color="green" size={40} />
            <h1 className="text-green-800">Schedule a meeting</h1>
          </div>
        </div>
      </div>

      {showViewAllMeeting && (
        <UniversalModel setShowModel={setShowAllMeeting}>
          <div className="w-full p-4 flex flex-col gap-4 mt-4">
            {getAllMeetingData?.data?.map((reminder, index) => (
              <div className="bg-purple-200 relative rounded-lg p-4 h-auto w-full shadow-lg flex flex-col justify-between">
                <div className=" absolute right-4 flex justify-end mt-4">
                  <img
                    src="https://www.gstatic.com/images/branding/product/1x/meet_2020q4_48dp.png"
                    alt="Google Meet"
                    className="w-8 h-8"
                  />
                </div>
                <div className="text-left">
                  <p className="text-xs text-gray-600">{reminder?.startTime}</p>
                  <p className="text-lg font-bold text-gray-800">
                    {reminder?.endTime}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-sm font-medium h-fit text-gray-900 ">
                    {reminder?.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </UniversalModel>
      )}
    </div>}
    </>
  );
};

export default UpComingMettings;
