import getToken from "../../utils/getToken";
import { emptySplitApi } from "../injectEndpoint";



export const Sales = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
      addTokenSales: builder.mutation({
        query: () => ({
          url: `/sales/lead/addLeadAppCred`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
        }),
        providesTags: ["addTokenSales"],
      }),
    }),
    overrideExisting: false, // Add
  });

  export const {useAddTokenSalesMutation} = Sales