import React, { useState, useEffect } from "react";
const Toggle = ({ isToggled, onClick, index, activeIndex }) => {
    return (
      <div className="flex h-full w-full items-center">
        <div
          onClick={onClick}
          className={`${isToggled && activeIndex === index ? "bg-blue-500" : "bg-gray-300"
            } w-12 h-6 rounded-full cursor-pointer p-1 duration-300 ease-in-out relative`}
        >
          <div
            className={`${isToggled && activeIndex === index
              ? "translate-x-6"
              : "translate-x-0"
              } w-4 h-4 bg-white rounded-full shadow-md transform duration-300 cursor-pointer top-1 ease-in-out absolute inset-y-0 left-1`}
          ></div>
        </div>
      </div>
    );
  };

  export default Toggle
  