import React, { useState } from 'react'

import { useEmployeePropertyQuery } from '../redux/slices/dashboard'
import UniversalTable from './Table/UniversalTable'
import Skeleton from './Skeleton'

const EmployeeOverviewProperty = ({empId}) => {
    const [pageNumber,setPageNumber]=useState(1) 
    const {data,isLoading,isFetching,refetch}=useEmployeePropertyQuery({pageNumber:pageNumber,userId:empId})
    

    const totalPages=data?.data?.totalPages	;
const handlePrevious=() => {
    if(pageNumber>1){
      setPageNumber(pageNumber-1)
    }
  
}

const handleNext=() => {
    if(pageNumber<totalPages){
      setPageNumber(pageNumber+1)
    }
  
}
  return (
  
    <div className='bg-lightThemeGradient rounded-lg dark:bg-darkThemeGradient text-darkThemeFontColor  p-4'>
        <div className=''>

      <h1 className='text-2xl'>Properties </h1>
      <h2 className='text-sm'>{data?.data?.totalCount} Done this Month</h2>
        </div>

       { (isLoading||isFetching)?<div className='w-full h-[200px]'> <Skeleton/> </div>:<div>
            <UniversalTable showActions={'properties'} dataArray={data?.data?.userDetail} refetch={refetch} />

   {totalPages>1&&  <div className="flex justify-center items-center space-x-4 mt-6">
      <button
        onClick={handlePrevious}
        className={`px-4 py-2 text-white rounded ${
          pageNumber === 1
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-700'
        }`}
        disabled={pageNumber === 1}
      >
        Previous
      </button>

      <span className="text-lg text-white font-medium">
        Page {pageNumber} of {totalPages	}
      </span>

      <button
        onClick={handleNext}
        className={`px-4 py-2 text-white rounded ${
          pageNumber === totalPages
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-700'
        }`}
        disabled={pageNumber === totalPages}
      >
        Next
      </button>
    </div>}
        </div>}
    </div>
  
  )
}

export default EmployeeOverviewProperty

