import React from 'react'
import { useNavigate } from 'react-router-dom';

const SiderBarButtonComponent = ({
    isHovered=false,
    isSelected=false,
    Icon,
    setSelectedIndex,
    buttonText,
    actualIndex,
    isFirst=false,
    isLast = false,
    action

}) => {
    const navigate = useNavigate()
    const handleClick=(action)=>{
navigate(action)
setSelectedIndex(actualIndex)
    }
    return ( <>
    {
        isHovered
        ?
        <div onClick={()=>handleClick(action)}
          className={`${isFirst && 'rounded-t-3xl'} ${isLast && 'rounded-b-3xl'} flex items-center  flex-row p-2 space-x-5 text-textDark dark:text-textLight bg-navbarDark dark:bg-navbarLight`}
        >
          <div className="flex justify-center items-center  h-[35px] w-[35px] rounded-full">
            <Icon 
              className="w-[18px] h-[18px]  filter invert text-black dark:text-white "/>
          </div>
          <p>{buttonText}</p>
        </div>
        :
        <div onClick={()=>handleClick(action)}
          className={`${isFirst && 'rounded-t-3xl'} ${isLast && 'rounded-b-3xl'}  text-textDark dark:text-textLight bg-navbarDark dark:bg-navbarLight flex flex-row p-2 space-x-5 `}
        >
          <div className={`flex justify-center items-center ${isSelected && 'dark:bg-[rgba(255,255,255,0.24)] bg-black/10'} h-[35px] w-[35px] rounded-full`}>
            
            <Icon 
              className="filter invert text-black dark:text-white"/>
          </div>
        </div>
    }
    </> );
}
 
export default SiderBarButtonComponent;
