import React, { useState } from "react";
import RetvensBlack from "../../assets/icons/RWorldBlack.png";
import ReusableInput from "../InputField/ReusableInput";
import Button from "../button";
import { Form, FormProvider, useForm } from "react-hook-form";
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from "../../redux/slices/onBoarding";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const methods = useForm();
  let token = null;
  const location = useLocation();
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  if (location.pathname.includes("/change-password/")) {
    const pathParts = location.pathname.split("/change-password/");
    if (pathParts?.length > 1) {
      token = pathParts[1];
      Cookies.set("token", token, { expires: 7 });
    } else {
      console.error("Token not found in the URL");
    }
  } // <-- Close the if block here

  const handleSubmitForm = (data) => {
    // Handle form submission
    resetPassword({
      newPassword: data?.newPassword,
      confirmPassword: data?.confirmNewPassword,
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        navigate("/login");
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };

  const passwordValidationRules = {
    required: "Password is required",
    minLength: {
      value: 12,
      message: "Minimum character allowed is 12",
    },
    validate: {
      hasUpperCase: (value) =>
        /[A-Z]/.test(value) || "One uppercase character required",
      hasLowerCase: (value) =>
        /[a-z]/.test(value) || "One lowercase character required",
      hasNumber: (value) => /[0-9]/.test(value) || "One number required",
      hasSpecialChar: (value) =>
        /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
        "One special character required",
    },
  };

  return (
    <div className="h-[100%] flex flex-col w-[100%]">
      <div className="h-5 w-16 ">
        <img src={RetvensBlack} className="w-[100%] h-[100%]" alt="" />
      </div>

      <div className="flex flex-col gap-2 my-2">
        <h1 className="text-3xl outline-2 font-semibold">Reset Password</h1>
        <p>Enter your email below to receive a password reset link</p>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
          <div className="my-10">
            <div>
              <ReusableInput
                label="New Password"
                name="newPassword"
                type="password"
                placeholder="Enter new password"
                rules={passwordValidationRules}
                showIcon={true}
              />
              <ReusableInput
                label="Confirm New Password"
                name="confirmNewPassword"
                type="password"
                placeholder="Confirm new password"
                rules={{ required: "Password is required" }}
                showIcon={true}
              />
            </div>
          </div>
          <Button className="w-full bg-RGreen p-2 text-center rounded-lg text-white cursor-pointer">
            Change Password
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default ResetPassword;
