import React from "react";
import Button from "../button/index.jsx";
import { GoSkip } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";
import WelcomeScreenTableTabComponent from "./SubComponent/WelcomeScreenTableTabComponent.jsx";
import WelcomeScreenAlert from "./SubComponent/WelcomeScreenAlert.jsx";

const WelcomeScreenTable = ({ taskList = [] }) => {
  return (
    <>
      <div className="w-[90%] h-[100%] p-[5%] bg-lightGradient dark:bg-newGradient rounded-lg ">
        {/* header */}
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <h1 className="text-[20px] font-[700]">Welcome to R-World</h1>
            <p className="text-[15px] font-[400]">
              Follow these steps tog get started and keep your agency automated
            </p>
          </div>
          <Button
            type="solid"
            hasIcon={true}
            isLoading={false}
            buttonText={"Skip?"}
            Icon={GoSkip}
            onClick={() => {}}
          />
        </div>

        {/* table */}
        <div className="flex flex-col items-start border border-[#e6e6e6] my-[5%] rounded-md">
          {/* table header */}
          <div className=" w-[100%] flex flex-row space-x-3 items-center px-[10px] py-[10px] border-solid border-0 border-b border-[#e6e6e6]">
            <IoMdCheckmark className="text-white h-[18px] " />
            <h4 className="text-[14px] font-[600]">Get to know R-World</h4>
          </div>

          {/* table body */}
          <div className="w-[100%]">
            {taskList &&
              taskList.map((tasks, id) => {
                return (
                  <WelcomeScreenTableTabComponent
                    text={tasks.taskText}
                    completionStatus={tasks.completionStatus}
                    clickFunc={()=>{}}
                  />
                );
              })}
          </div>
        </div>

        {/* alert */}

        <WelcomeScreenAlert text="Follow these steps to get started and earn free cred" buttonText="Select Tasks " />
      </div>
    </>
  );
};

export default WelcomeScreenTable;
