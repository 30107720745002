import React, { Component } from "react";
import Button from "../../component/button";

//icons
import { TbUsersPlus } from "react-icons/tb";
import { FaTasks } from "react-icons/fa";
import DashboardCardList from "../../component/DashboardScreen/DashboardCardList/DashboardCardList";
import CustomTable from "../../component/Table/table";

const Test = () => {
  let cardList = [
    {
      Icon: FaTasks,
      cardName: "All Clients",
      currentValue: `35`,
      lastValue: `35`,
    },
    {
      Icon: FaTasks,
      cardName: "Active Clients",
      currentValue: `35`,
      lastValue: `35`,
    },
    {
      Icon: FaTasks,
      cardName: "Client At Risk",
      currentValue: `35`,
      lastValue: `35`,
    },
    {
      Icon: FaTasks,
      cardName: "Client At Risk",
      currentValue: `35`,
      lastValue: `35`,
    },
  ];

  let employeeListHeader = [
    "Employee",
    "Role",
    "Status",
    "Last Seen",
    "Action",
  ];
  let employeeListBody = [
    {
      profilePicture: "https://image.lexica.art/full_jpg/acf5676d-e1bb-47cf-8e64-b171543de0ec",
      employeeName: "Aman Sharma",
      employeeEmail: "aman@gmail.com",
      employeeId: "emp=245",
      role: "Revenue Exec",
      status: "Active",
      lastSeen: new Date(),
    },
    {
      profilePicture: "https://image.lexica.art/full_jpg/7515495b-982d-44d2-9931-5a8bbbf27532",
      employeeName: "Shivam Tiwariverveverveve",
      employeeEmail: "shivam@gmail.comverververve",
      employeeId: "emp=264",
      role: "Revenue Exec",
      status: "Active",
      lastSeen: new Date(),
    },
    {
      employeeName: "Saumitra Shukla",
      employeeEmail: "saumitra@shuka.com",
      employeeId: "emp=965",
      role: "Revenue Exec",
      status: "Inactive",
      lastSeen: new Date("2024-05-23"),
    },
  ];

  return (
    <>
      <div className="h-[100%] w-[100%] flex flex-col items-center justify-center">
        {/* Header */}
        <div className="w-[100%] flex flex-row">
          <div className=" w-[100%] flex flex-col items-start text-textWBGLight dark:text-textWBGDark mt-[20px]">
            <h1 className="text-[25px] font-[700]">Good Afternoon Partner,</h1>
            <p className="text-[13px] font-[400]">You got a lot to catch up!</p>
          </div>

          <div className=" w-[100%] flex flex-row justify-end items-center text-textWBGLight dark:text-textWBGDark mt-[20px] space-x-2 mr-2">
            <Button
              type="solid"
              hasIcon={true}
              isLoading={false}
              buttonText={"Add Client"}
              Icon={TbUsersPlus}
              onClick={() => {}}
            />
            <Button
              type="solid"
              hasIcon={true}
              isLoading={false}
              buttonText={"Add Tasks"}
              Icon={FaTasks}
              onClick={() => {}}
            />
          </div>
        </div>

        {/* Dashboard Card List */}
        <DashboardCardList list={cardList} />

        {/* table */}
        <CustomTable
          type="employee"
          header={employeeListHeader}
          body={employeeListBody}
          heading="Employee Overview"
          containSubheading={true}
          subHeading="30 active this month"
          hasFilter={false}
          hasSort={false}
          hasDownload={false}
          hasPagination={false}
          paginationFunc={() => {}}
        />
      </div>
    </>
  );
};

export default Test;
