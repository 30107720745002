import React, { useState, useEffect, useRef } from "react";
import {
  
    useEditProfilePictureMutation,
  useGetUserProfileInformationQuery,
  useRemoveProfilePictureMutation,
} from "../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa"; // Font Awesome icon for edit

const UserProfilePicture = () => {
  const {
    data: userProfileData,
    error,
    isLoading,
    refetch, // Function to refetch the user profile data
  } = useGetUserProfileInformationQuery();
  const [editProfilePicture] = useEditProfilePictureMutation()
  const [previewImage, setPreviewImage] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
const [removeProfilePicture]=useRemoveProfilePictureMutation();
  // Reference for the file input element
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isLoading) return;
    if (error) {
      toast.error("Failed to load user profile data.", {
        position: "bottom-right",
      });
      return;
    }
    setPreviewImage(userProfileData?.data?.profileImg || "");
  }, [userProfileData, isLoading, error]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        toast.error("Please upload a valid image (JPEG, PNG, GIF).", {
          position: "bottom-right",
        });
        return;
      }
      setSelectedImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    // Simulate a click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadClick = async () => {
    if (!selectedImageFile) {
      toast.error("No image selected for upload.", {
        position: "bottom-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("profileImg", selectedImageFile);

    editProfilePicture(formData)
      .unwrap()
      .then((response) => {
        toast.success("Profile picture updated successfully!", {
          position: "bottom-right",
        });
        setPreviewImage(response?.data?.profileImg); 
        refetch();// Assuming the API returns the updated profile image
      })
      .catch((err) => {
        toast.error("Failed to update profile picture.", {
          position: "bottom-right",
        });
        console.error(err); // Log the error for debugging purposes
      });

    // Refetch user profile data after successful upload
 
  };
  const handleRemoveClick = async () => {
  
  

    removeProfilePicture()
      .unwrap()
      .then(() => {
        toast.success("Profile picture removed successfully!", {
          position: "bottom-right",
        });
       
        refetch();
      })
      .catch((err) => {
        toast.error("Failed to update profile picture.", {
          position: "bottom-right",
        });
        console.error(err); // Log the error for debugging purposes
      });

    // Refetch user profile data after successful upload
  
  };
  return (
    <div className="w-full flex flex-col items-center mt-10">
      <div
        className="relative w-32 h-32 mb-4 overflow-hidden rounded-full bg-gray-100 shadow-md cursor-pointer group"
        onClick={handleImageClick} // Open file dialog when image is clicked
      >
        {previewImage ? (
          <img
            src={previewImage}
            alt="User Profile"
            className="object-cover w-full h-full"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full text-gray-500">
            No Image
          </div>
        )}

        {/* Hover overlay with edit icon */}
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity">
          <FaEdit className="text-white text-2xl" />
        </div>
      </div>

      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
      />

      {/* Upload Button */}
      {selectedImageFile && (
        <button
          onClick={handleUploadClick}
          className="my-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
        >
          Upload Image
        </button>
      )}
       {userProfileData?.data?.profileImg  && (
        <button
          onClick={handleRemoveClick}
          className="my-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
        >
          Remove
        </button>
      )}
    </div>
  );
};

export default UserProfilePicture;
